import React from 'react';
import useTranslator from "../../../hooks/useTranslator";
const AboutBanner = () => {
    const { _t } = useTranslator();
    return (
        <div className='aboutbanner'>
            <div className='container'>
                <h2>{_t("Who Are We?")}</h2>
                <div class="bar-line"></div>
                <p>{_t("The simple answer, were the ones who are going to modernize the mechanic industry.")}</p>
            </div>
        </div>
    );
}
 
export default AboutBanner;
