import React from "react";
import { formatDateWithoutTime } from "../../../utils/helpers";

const BlogDetailBanner = ({ data }) => {
  return (
    <>
      <section className="contact-banner BlogBanner BlogDetailBanner">
        <div className="container">
          <div className="blog-head">
            <div className="Categorybar">
              <h5>{data?.category || "Industries"}</h5>
            </div>
            <h2 className="blogtitle">{data?.title}</h2>
            <div class="bar-line"></div>
            <h5 className="blogdate">
              <img src="/images/calender.webp" alt="icon" />
              {formatDateWithoutTime(data?.createdAt)}
            </h5>
           
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailBanner;
