import React from "react";
import { QuickFormConstant } from "../../../constants/quick-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _default from "react-bootstrap/esm/Nav";
import useTranslator from "../../../hooks/useTranslator";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const PROVIDED_SERVICES_CONSTANT = QuickFormConstant.PROVIDED_SERVICES;

const StepFour = ({ setCurrentStep, totalCase }) => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formSlice.data);
  const handleNext = () => {
    setCurrentStep((prevStep) => (prevStep < 9 ? prevStep + 1 : prevStep));
  };
  useScrollTopForm(`fromapply`);


  const isHeavyVehicleSelected = () => {
    const selectedIndustries = formData.vehicles;
    return (
      selectedIndustries.includes(
        QuickFormConstant.INDUSTRY_SERVE.HEAVY_MACHINERY
      ) ||
      selectedIndustries.includes(
        QuickFormConstant.INDUSTRY_SERVE.COMMERCIAL_VEHICLES
      )
    );
  };

  const handleBack = () => {
    let isHeavy = isHeavyVehicleSelected() ? 1 : 2;
    console.log("🚀 ~ handleBack ~ isHeavy:", isHeavy);

    setCurrentStep((prevStep) => {
      console.log("🚀 ~ setCurrentStep ~ prevStep:", prevStep);
      return prevStep > 1 ? prevStep - isHeavy : prevStep;
    });
  };

  const schema = yup
    .object()
    .shape({
      providedServices: yup.array(yup.string().optional()).optional(),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      dispatch(saveData(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4>{_t("Do you provide any of the following services?")}</h4>
        <h6 className="mb-4 text-center">( Select all that apply )</h6>
        <div className="flexbar">
          <div class="relative mob-none"></div>
          <label className="selectbox">
            <input
              type="checkbox"
              value={PROVIDED_SERVICES_CONSTANT.WHEEL_TIRE}
              name="providedServices"
              {...register("providedServices")}
            />
            <div className="primebox">
              <img src="/images/tire-wheel.svg" alt="icon" />
              <h5>{_t("Wheel & Tire Services")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              value={PROVIDED_SERVICES_CONSTANT.TOW}
              name="providedServices"
              {...register("providedServices")}
            />
            <div className="primebox">
              <img src="/images/lowtruck.svg" alt="icon" />
              <h5>{_t("Tow Services")}</h5>
            </div>
          </label>
          <div class="relative  mob-none"></div>
        </div>
        <p className="error">
          {" "}
          {errors.providedServices && `${_t(errors.providedServices.message)}`}
        </p>
      </div>

      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Next")}
        </button>
      </div>
    </form>
  );
};

export default StepFour;
