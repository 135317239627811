import React from "react";
import JobPost from "../../../../Components/Forms/JobPostForm";

const EditJobs = () => {
  return (
    <div>
      <JobPost edit={true} />
    </div>
  );
};

export default EditJobs;
