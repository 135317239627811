import React from "react";

const Logo = () => {
  return (
    <div className="logobar">
      <img src="/images/logo.svg" alt="logo" width={300} height={47} loading="lazy" />
    </div>
  );
};

export default Logo;
