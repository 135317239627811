import React, { useState } from "react";
import BackButton from "../../../Components/BackToPrev";
import { AiFillPlusCircle, AiOutlineSearch } from "react-icons/ai";
import { InputGroup, Form, Table, Button } from "react-bootstrap";
import Paginate from "../../../Components/Paginate";
import Loader from "../../../Components/Loader";
import useUsers from "../../../data-fetch/useUsers";
import UpdatePasswordModel from "../../../Components/Modals/UpdatePassword";
import { AddUserModel } from "../../../Components/Modals";

const Users = () => {
  const [eventType, setEventType] = useState("Create");
  const [showPasswordModel, setShowUpdateModel] = useState(false);
  const [user, setUser] = useState(null);
  const {
    users,
    setSearchText,
    deactivateUser,
    activatedUser,
    searchText,
    totalDocs,
    loading,
    pageHandler,
    addUser,
    page,
  } = useUsers();
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Users</h4>
          </div>
          <div className="import-export">
            <Button
              onClick={() => {
                setEventType("Create");
                setUser(null);
                setShow(true);
              }}
              className="btn btnorange">
              <AiFillPlusCircle /> Add User
            </Button>
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Lead"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e)}
              />
            </InputGroup>
          </div>
        </div>
        {/* --------------table-------------- */}

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>

                    <th>phone</th>
                    <th>Email</th>
                    <th>status</th>
                    <th>Action </th>
                    <th>Edit </th>
                    <th>Update Password </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((usr, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{usr.name}</p>
                          </div>
                        </div>
                      </td>

                      <td className="red-text">{usr?.mobileNo}</td>
                      <td className="red-text">{usr?.email}</td>
                      <td className="red-text">
                        {usr?.isDeleted ? "Deactivated" : "Active"}
                      </td>

                      {!usr.isDeleted ? (
                        <td>
                          <button
                            onClick={() =>
                              deactivateUser({
                                userId: usr._id,
                              })
                            }>
                            Mark Deactivated
                          </button>
                        </td>
                      ) : (
                        <td>
                          <button
                            onClick={() =>
                              activatedUser({
                                userId: usr._id,
                              })
                            }>
                            Mark Active
                          </button>
                        </td>
                      )}

                      <td className="red-text">
                        <button
                          onClick={() => {
                            setUser(usr);
                            setEventType(true);
                            setShow(true);
                          }}
                          className="  cursor-pointer">
                          Edit
                        </button>
                      </td>
                      <td className="red-text">
                        <button
                          onClick={() => {
                            setUser(usr);
                            setShowUpdateModel(true);
                          }}
                          className="  cursor-pointer">
                          Update Password
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                total={totalDocs}
                onChange={pageHandler}
                page={page}
                eventType="Create"
              />
            </div>
            <AddUserModel
              show={show}
              setShow={setShow}
              onSubmit={addUser}
              eventType={eventType}
              user={user}
            />
            <UpdatePasswordModel
              show={showPasswordModel}
              setShow={setShowUpdateModel}
              id={user?._id}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Users;
