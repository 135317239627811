import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import ContectModel from "../../Modals/ContactModel";

const ContactUs = () => {
  const { _t } = useTranslator();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  return (
    <>
      <section className="contactus">
        <div className="container">
          <Row>
            <Col md="6">
              <div className="letstalk-inner">
                <h6>{_t("let’s talk")}</h6>
                <h3>{_t("Have Questions? Feel Free To Reach Out")}</h3>
                <div className="btn-radius">
                  <Link onClick={handleShow} className="btn btnorange">
                    {_t("Contact Us")}
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {/* <p className="cn-content">
                Join a team of creative and energetic individuals to re-imagine
                the future of surgery with groundbreaking robotics, advanced
                instrumentation, enterprise platform, artificial intelligence
                and much more !
              </p> */}
            </Col>
          </Row>
        </div>
      </section>
      <ContectModel show={show} setShow={setShow} />
    </>
  );
};

export default ContactUs;
