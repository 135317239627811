import React, { useEffect } from "react";
import QuickApplyStepForm from "../../Forms/QuickApplyStepForm/Index";
import useTranslator from "../../../hooks/useTranslator";

const QuickApply = () => {
  const { _t } = useTranslator();
  const hash = window.location.hash;

  useEffect(() => {
    if (hash === "#formapply") {
      const formApplyElement = document.getElementById("formapply");
      formApplyElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <>
      <div className="quick-block" >
        <div className="primary-title">
          <h4>{_t("quick apply")}</h4>
          <h2>{_t("Apply In Less Than 1 Minute Below")}</h2>
          <div className="bar-line" id="formapply"></div>
        </div>
        <QuickApplyStepForm />
      </div>
    </>
  );
};

export default QuickApply;
