import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob, clearJob } from "../../../Redux/Slices/jobsSlice";
import { businessOwnerApplyJob } from "../../../api-services/application";
import { useParams } from "react-router-dom";
import FormFieldCaptcha from "../FormFieldCaptcha";
import toast from "react-hot-toast";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const IsOem = ({ setCurrentStep, totalCase }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError("");
  };

  useScrollTopForm(`jobapply`);


  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const schema = yup
    .object()
    .shape({
      isOem: yup.boolean().required(),
    })
    .required();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formData, jobId: id },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const watchField = watch();

  const onSubmit = (data) => {
    if (!captchaValue) {
      setCaptchaError("Please complete the captcha.");
      return;
    }
    if (data) {
      dispatch(applyJob(data));
      businessOwnerApplyJob(watchField)
        .then((business_data) => {
          dispatch(clearJob());
          setCurrentStep(totalCase);
        })
        .catch((err) => {
          console.log("error ", err);
          toast.error(err?.message);
        });
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const { _t } = useTranslator();

  useEffect(() => {
    setValue("jobId", id);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4 className="mb-5">{_t("Is your Service Center OEM certified?”")}</h4>
        <div className="flexbar">
          <label className="selectbox w-100 h-auto">
            <input type="radio" value={true} {...register("isOem")} />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("Yes")}</p>
            </div>
          </label>
          <label className="selectbox w-100 h-auto">
            <input type="radio" value={false} {...register("isOem")} />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("No")}</p>
            </div>
          </label>
        </div>
        <p className="error">
          {" "}
          {errors.isOem && `${_t(errors.isOem.message)}`}
        </p>
        <div className="">
          <FormFieldCaptcha
            value={captchaValue}
            onChange={handleCaptchaChange}
            errorText={captchaError}
            showError={Boolean(captchaError)}
          />
        </div>
      </div>

      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Submit")}
        </button>
      </div>
    </form>
  );
};

export default IsOem;
