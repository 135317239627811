import useTranslator from "../../../hooks/useTranslator";

const HomeBanner = () => {
  const { _t } = useTranslator();

  return (
    <div className="banner">
      <div className="container">
        <h1>
          {_t("Who said mechanics can’t modernize.")}
          <span>{_t("Be Part of the Movement.")}</span>
        </h1>
        <div className="bar-line"></div>
        <p>
          {_t(
            "The same vehicle and equipment maintenance industry, with some upgraded service options Offering Remote Services provides you with an entirely new revenue stream at your own pace."
          )}
        </p>
      </div>
    </div>
  );
};

export default HomeBanner ;
