import React from "react";

import LookingForTabs from "../../LookingTabs/Index";
import useTranslator from "../../../hooks/useTranslator";

const LookingFor = () => {
  const {_t} =useTranslator()
  return (
    <div className="LookingFor">
      <div className="container">
        <div className="primary-title title-leftside">
          <h4>{_t("Who We’re Looking For")}</h4>
          <h2>{_t("We Need The Following Remote Technicians")}</h2>
          <div className="bar-line"></div>
        </div>
        <LookingForTabs/>
      </div>
    </div>
  );
};

export default LookingFor;
