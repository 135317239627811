import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { logout as logoutServices } from "../../../api-services/auth";
import { logout } from "../../../Redux/Slices/authSlice";
import { capitalizedString } from "../../../utils/helpers";
import {
  MdDashboardCustomize,
  MdLibraryBooks,
  MdLogout,
  MdOutlineListAlt,
  MdOutlineLocalAtm,
  MdOutlineSettings,
  MdOutlineSwitchAccount,
  MdPerson,
  MdPermContactCalendar,
  MdLeaderboard,
  MdChatBubble,
  MdArticle,
  MdCategory,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

const Superadminsidebar = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.authSlice);
  const navigate = useNavigate();
  const logoutHandler = () => {
    logoutServices()
      .then((logoutData) => {
        if (logoutData?.status === "success") {
          dispatch(logout());
          navigate("/");
        }
      })
      .catch((err) => {
        console.log("error while logout", err);
      });
  };
  return (
    <>
      <div className="Sidebarlayout">
        <div className="navbarbrand">
          <Link to="/">
            <img
              src="/images/logo.svg"
              width={300}
              height={47}
              loading="lazy"
              alt="logo"
            />
          </Link>
        </div>
        <div className="sidebarprofile-sec">
          <img
            src={
              userData?.profileImage
                ? userData?.profileImage
                : "/images/dummyuser.webp"
            }
            alt="userprofile"
          />
          <h5 className="username">{capitalizedString(userData?.name)}</h5>
        </div>
        <Navbar className="mainnavbar">
          <h4 className="navbar-title">General</h4>
          <Nav className="mb-5">
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/admin/overview">
              <MdDashboardCustomize />
              Overview
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/account">
              <MdLibraryBooks />
              Account
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/jobs">
              <MdOutlineLocalAtm />
              Jobs
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/applications">
              <MdOutlineListAlt />
              Applications
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/leads">
              <MdLeaderboard />
              Leads
            </NavLink>
            <a href="https://app.crisp.chat/" target="_blank">
              <MdChatBubble />
              Chats
            </a>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/admin/blogs">
              <MdArticle />
              Blogs
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/job-category">
              <MdCategory />
              Job Niche
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/short-application">
              <MdOutlineSwitchAccount />
              Short Applications
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/contacts-leads">
              <MdPermContactCalendar />
              Contacts
            </NavLink>

            {userData.role === "super_admin" && (
              <NavLink
                onClick={() => document.body.classList.toggle("sidebaractive")}
                to="/admin/users">
                <MdPerson />
                Users
              </NavLink>
            )}

            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/admin/language">
              <MdOutlineSettings />
              Content
            </NavLink>
            <NavLink
              onClick={() => document.body.classList.toggle("sidebaractive")}
              to="/admin/setting">
              <MdOutlineSettings />
              Setting
            </NavLink>
          </Nav>
        </Navbar>
        <Navbar className="mainnavbar logoutbar">
          <button className="btnlogout" onClick={() => logoutHandler()}>
            <MdLogout />
            Logout
          </button>
        </Navbar>
      </div>
    </>
  );
};

export default Superadminsidebar;
