import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import useTranslator from "../../hooks/useTranslator";
const LookingForTabs = () => {
  const { _t } = useTranslator();
  
  const Freelancertabs = [
    {
      carimage: "/images/sedan.svg",
      cartitle: "Automotive Mechanics",
    },
    {
      carimage: "/images/hatchback.svg",
      cartitle: "Classic Car Mechanics",
    },
    {
      carimage: "/images/sportbike.svg",
      cartitle: "Motorcycle Mechanics",
    },
    {
      carimage: "/images/minitruck.svg",
      cartitle: "RV Service Mechanics",
    },
    {
      carimage: "/images/boat.svg",
      cartitle: "Marine Vehicle Mechanics",
    },
    {
      carimage: "/images/small-engine.svg",
      cartitle: "Small Engine Mechanics",
    },
    {
      carimage: "/images/jcb.svg",
      cartitle: "Heavy Machinery Mechanics",
    },
    {
      carimage: "/images/bus.svg",
      cartitle: "Commercial Bus Mechanics",
    },
    {
      carimage: "/images/truck.svg",
      cartitle: "Commercial Truck Mechanics",
    },
    {
      carimage: "/images/lowtruck.svg",
      cartitle: "Tow Truck Operators",
    },
    {
      carimage: "/images/wheels.svg",
      cartitle: "Tire & Wheel Technicians",
    },
  ];
  return (
    <>
      <div className="LookingForTabs">
        <div className="container">
          {/* Freelancer content  */}
          <div className="gridbar">
                {Freelancertabs.map((freelancer, index) => (
                  <div className="cars-element" key={index}>
                    <div className="lookingservice">
                      <div className="carside">
                        <img src={freelancer.carimage} alt="icon" width={70} height={70} loading="lazy" />
                      </div>
                      <div className="service-content">
                        <h4>{_t(freelancer.cartitle)}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              
        </div>
      </div>
    </>
  );
};

export default LookingForTabs;
