import { useCookieConsentContext } from "@use-cookie-consent/react";
import { memo, useEffect, useState } from "react";

const CookieBanner = () => {
  const { acceptAllCookies, declineAllCookies, acceptCookies, consent } =
    useCookieConsentContext();
  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const cookiePreference = localStorage.getItem("cookiePreference");
      if (!cookiePreference) {
        setShowModel(true);
      }
      if (consent.thirdParty) {
        localStorage.setItem("cookiePreference", consent.thirdParty);


      }
    }, 7000);
  }, [consent]);

  const handleAcceptNecessaryOnly = () => {
    acceptCookies({ firstParty: true });
    setShowModel(false);
    // Save the preference in both local and session storage
    localStorage.setItem("cookiePreference", "necessary");
  };

  const handleDeclineAllCookies = () => {
    declineAllCookies();
    setShowModel(false);

    // Save the preference in both local and session storage
    localStorage.setItem("cookiePreference", "declined");
  };

  const handleAcceptAllCookies = () => {
    acceptAllCookies();
    setShowModel(false);

    // Save the preference in both local and session storage
    localStorage.setItem("cookiePreference", "all");
  };

  return (
    <>
      {showModel ? (
        <div className="cookie-consent-bar">
          <h6>We value your privacy</h6>
          <p>
            Please note that your consent will be valid across all our
            subdomains. You can change or withdraw your consent at any time by
            clicking the “Consent Preferences” button at the bottom of your
            screen. We respect your choices and are committed to providing you
            with a transparent and secure browsing experience. Cookie Policy
          </p>

          <div className="btngroupbar">
            <button
              className="btn btn-review btnorange"
              onClick={handleAcceptNecessaryOnly}>
              Accept Necessary Only
            </button>
            <div className="btninlinebar">
              <button
                className="btn btn-review btnorange"
                onClick={handleDeclineAllCookies}>
                Reject all
              </button>
              <button
                className="btn btnall btnorange"
                onClick={handleAcceptAllCookies}>
                Accept all
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(CookieBanner);
