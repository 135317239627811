import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import { capitalizedString } from "../../../utils/helpers";

const JobListing = ({ jobsList }) => {
  const { _t } = useTranslator();

  if (Array.isArray(jobsList) && jobsList?.length) {
    return (
      <>
        {jobsList?.map((jobs, index) => (
          <div className="joblistingbar" key={jobs.id}>
            <h4 className="jobtitle">{_t(jobs.jobTitle)}</h4>
            <ul>
              <li className="btnpitch">
                {_t(capitalizedString(jobs.jobType))}
              </li>
              <li className="btnpitch">{`$${
                jobs?.rate.rateValue != 0
                  ? jobs?.rate.rateValue
                  : jobs?.rate?.min + "-" + jobs?.rate?.max
              }/${_t(capitalizedString(jobs.rate?.rateTimeLine))}`}</li>
              <li className="btn-gps">
                <MdOutlineLocationOn />
                {jobs?.city && jobs?.city + ","} {jobs?.state}
              </li>
            </ul>
            <div className="btnjob">
              <Link to={`/alljobs/${jobs?._id}`} className="btn btnorange">
                {_t("Apply")}
              </Link>
            </div>
          </div>
        ))}
      </>
    );
  }
};

export default JobListing;
