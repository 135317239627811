import { useState, useEffect } from "react";
import BackButton from "../../../Components/BackToPrev";
import { AiOutlineSearch } from "react-icons/ai";
import { InputGroup, Form, Table } from "react-bootstrap";
import Paginate from "../../../Components/Paginate";
import { getAllShortApplications } from "../../../api-services/application";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import config from "../../../config";
import { IoDocumentAttach } from "react-icons/io5";
import { formatDateWithoutTime } from "../../../utils/helpers";
import Loader from "../../../Components/Loader";

const ShortApplications = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedSearch = debounce(() => {
    setLoading(true);
    getAllShortApplications({ page, limit, status, searchText })
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);

          setApplicationList(data.data.docs);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error.data);
      })
      .finally(() => setLoading(false));
  }, 500);

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, page, limit, searchText, status]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Short Applications</h4>
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeHolder="Search Customers"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
            </InputGroup>
          </div>
        </div>
        {/* --------------table-------------- */}

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Application Date</th>
                    <th>Job Niche</th>
                    <th>Resume</th>
                  </tr>
                </thead>
                <tbody>
                  {applicationList?.map((application, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">
                              {formatDateWithoutTime(application?.createdAt)}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">
                              {application?.jobCategory}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="red-text">
                        {application?.resume ? (
                          <Link
                            target="_blank"
                            to={`${config.server.imageBaseUrl}/${application?.resume}`}>
                            <IoDocumentAttach color="black" /> Resume
                          </Link>
                        ) : (
                          "Not-available"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate total={totalDocs} onChange={handleChangePage} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShortApplications;
