import React from 'react';
import ContactForm from '../../Forms/ContactForm';

const ContactFormSection = () => {
    return (
        <>
            <div className='contactformbar'>
                <div className='container'>
                <h4>Need Further information? </h4>
                <ContactForm/>
                </div>
            </div>
        </>
    );
}

export default ContactFormSection;
