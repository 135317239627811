import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getOutReachEmail = async (data) => {
  const axisRes = await sendRequest(Routes.emails.get, "GET", data);
  return axisRes;
};
export const sendOutReactMails = async (data) => {
  const axisRes = await sendRequest(Routes.emails.post, "POST", data);
  return axisRes;
};
