import React, { useState, useEffect } from "react";
import BackButton from "../../../Components/BackToPrev";
import { AiOutlineSearch } from "react-icons/ai";
import { InputGroup, Form, Table } from "react-bootstrap";
import { LuSettings2 } from "react-icons/lu";
import Paginate from "../../../Components/Paginate";
import debounce from "lodash/debounce";
import { LEADCATEGORY } from "../../../constants/leads";
import Loader from "../../../Components/Loader";
import { getContactList } from "../../../api-services/contact";
import { formatDateWithoutTime } from "../../../utils/helpers";
import { MdFilterList, MdOutlineMarkEmailUnread } from "react-icons/md";
import { Link } from "react-router-dom";

const ContactLeads = () => {
  const data = [{ name: "Floyd Miles" }, { name: "Floyd Miles" }];

  const [leadsList, setLeadList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectJob, setSelectedJob] = useState(null);
  const [leadId, setLeadId] = useState(null);

  const debounceList = debounce((value) => {
    setLoading(true);
    getContactList({ page, limit, searchText: value, category })
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          setLeadList(data.data.docs);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error);
      })
      .finally(() => setLoading(false));
  }, 600);

  useEffect(() => {
    debounceList(searchText);
  }, [page, limit, searchText, category]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const pageHandler = (page) => {
    setPage(page);
  };

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Contacts</h4>
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Lead"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
            </InputGroup>
          </div>
          <div className="filter">
            <LuSettings2 size={30} />
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}>
              <option value="">All</option>
              {LEADCATEGORY.map((category) => (
                <option value={category} key={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* --------------table-------------- */}

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Mobile Number</th>
                    <th>Business Name</th>
                    <th>Date</th>
                    <th>Message </th>
                    <th>Communications </th>
                  </tr>
                </thead>
                <tbody>
                  {leadsList?.map((lead, index) => (
                    <tr key={index}>
                      <td>{lead?.firstName}</td>
                      <td>{lead?.lastName}</td>

                      <td className="red-text">{lead?.email}</td>
                      <td className="red-text">
                        {lead?.mobile_number
                          ? lead?.mobile_number
                          : "N/A"}
                      </td>

                      <td className="red-text">{lead?.organization}</td>
                      <td className="red-text">
                        {formatDateWithoutTime(lead.createdAt)}
                      </td>
                      <td className="red-text">{lead?.message}</td>
                      <Link
                        to={`/applicant/mails/${lead?.email}?name=${lead?.firstName}`}>
                        <MdOutlineMarkEmailUnread className="ml-2" />{" "}
                        <td className="red-text">
                          <button>communications</button>
                        </td>
                      </Link>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                total={totalDocs}
                onChange={pageHandler}
                page={page}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ContactLeads;
