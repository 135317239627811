import React, { useEffect, useMemo, useState } from "react";
import { requestHandler } from "../utils/comman";
import { cities, states } from "../api-services/jobs";

const useStateAndCity = () => {
  const [stateList, setStateList] = useState(null);
  const [cityList, setCityList] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchState = () => {
    requestHandler(
      async () => await states(),
      setLoading,
      (res) => {
        const { data } = res;
        setStateList(data);
      },
      (error) => {
        console.log("error while fetch states list", error);
      }
    );
  };
  const fetchCities = (state) => {
    console.log("🚀 ~ fetchCities ~ state:", state)
    requestHandler(
      async () => await cities({ state }),
      setLoading,
      (res) => {
        const { data } = res;
        setCityList(data);
      },
      (error) => {
        console.log("error while fetch states list", error);
      }
    );
  };

  useEffect(() => {
    fetchState();
  }, []);

  return {
    fetchState,
    fetchCities,
    stateList,
    cityList,
    loading,
  };
};

export default useStateAndCity;
