import React from "react";
import { haveValue } from "../utils/helpers";
import { AiOutlineArrowRight } from "react-icons/ai";

const DashboardCard = ({
  cat = null,
  handleTableData = null,
  totalApplicationsCount = null,
  label = null,
}) => {
  if (haveValue(cat)) {
    return (
      <div className="info-card" key={cat._id}>
        <div className="card-details">
          <div className="card-content">
            <small className="heading-text">{cat?._id}</small>
            {handleTableData && (
              <p onClick={() => handleTableData(cat.applications)}>
                See Application{" "}
                <span>
                  <AiOutlineArrowRight />
                </span>
              </p>
            )}
          </div>
          <span className="crypto">
            <h4>{cat?.applicationCount}</h4>
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="info-card">
        <div className="card-details">
          <div className="card-content">
            <small className="heading-text">{label}</small>
          </div>
          <span className="crypto">
            <h4>{totalApplicationsCount || 0}</h4>
          </span>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
