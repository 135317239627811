import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// import { GOOGLE_SITE_KEY } from "services/urql/constants";
import { GOOGLE_SITE_KEY } from "../../config";

const FormFieldCaptcha = (props) => {
  const captchaRef = useRef(null);

  const { errorText, value, showError = false } = props;
  const [haveError, setHaveError] = useState(false);

  useEffect(() => {
    setHaveError(Boolean(errorText) && showError);
  }, [props]);

  useEffect(() => {
    if (captchaRef.current && value === "") {
      captchaRef.current.reset();
    }
  }, [value]);

  const onChange = (value) => {
    props?.onChange(value);
  };

  return (
    <>
      <div className={`form-field ${haveError ? "erroractive" : ""}`}>
        <div className="form-field-item">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={GOOGLE_SITE_KEY}
            onChange={onChange}
            size="normal"
          />
        </div>
        {haveError ? (
          <>
            <p className="invalid">{errorText}</p>
          </>
        ) : null}
      </div>
    </>
  );
};

export default FormFieldCaptcha;
