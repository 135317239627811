import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { QuickFormConstant } from "../../../constants/quick-form";

import MechanicFlow from "./mechanicFlow";
import ShopOwnerFlow from "./shopOwnerFlow";
import { haveValue } from "../../../utils/helpers";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const QuickApplyStepForm = () => {
  const formData = useSelector((state) => state.formSlice.data);
  console.log("🚀 ~ QuickApplyStepForm ~ formData:", formData);

  const [currentStep, setCurrentStep] = useState(1);
  const isCertified = formData.certifiedTechnician;

  const totalCase = formData.category === "Mechanic" ? 10 : 9;

  const isHeavyVehicleSelected = () => {
    const selectedIndustries = formData.vehicles;
    return (
      selectedIndustries?.includes(
        QuickFormConstant.INDUSTRY_SERVE.HEAVY_MACHINERY
      ) ||
      selectedIndustries?.includes(
        QuickFormConstant.INDUSTRY_SERVE.COMMERCIAL_VEHICLES
      )
    );
  };

  const renderStepContent = () => {
    if (formData.category === "Mechanic") {
      return (
        <MechanicFlow
          setCurrentStep={setCurrentStep}
          totalCase={10}
          currentStep={currentStep}
          isHeavyVehicleSelected={isHeavyVehicleSelected}
          isCertified={isCertified}
        />
      );
    } else {
      return (
        <ShopOwnerFlow
          setCurrentStep={setCurrentStep}
          totalCase={9}
          currentStep={currentStep}
          isHeavyVehicleSelected={isHeavyVehicleSelected}
          isCertified={isCertified}
        />
      );
    }
  };

  const parentDivClassName = `step-form-container step-${currentStep}`;

  useEffect(() => {
    const dynamicWidth = (100 / totalCase) * currentStep + "%";
    const lineElement = document.querySelector(".lineinner");
    lineElement.style.width = dynamicWidth;
  }, [currentStep, totalCase]);


  useScrollTopForm(`formapply-${currentStep}`);


  return (
    <div className={parentDivClassName}>
      <div className="container" id={`formapply-${currentStep}`}>
        <div className="block-bar">
          <div className="line">
            <div className="lineinner"></div>
          </div>
          {renderStepContent()}
        </div>
      </div>
    </div>
  );
};

export default QuickApplyStepForm;
