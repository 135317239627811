import React, { useState } from "react";
import { QuickFormConstant } from "../../../constants/quick-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob, clearJob } from "../../../Redux/Slices/jobsSlice";
import { Form } from "react-bootstrap";
import { mechanicApplyJob } from "../../../api-services/application";
import { haveValue, objectToFormData } from "../../../utils/helpers";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import FormFieldCaptcha from "../FormFieldCaptcha";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const UploadResume = ({ setCurrentStep, totalCase, isCertified }) => {
  const { _t } = useTranslator();
  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaError("");
  };

  useScrollTopForm(`jobapply`);


  const { id } = useParams();

  const handleBack = () => {
    const hasCertified = haveValue(isCertified) ? 2 : 1;
    setCurrentStep((prevStep) =>
      prevStep > 1 ? prevStep - hasCertified : prevStep
    );
  };

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);

  const {
    handleSubmit,
    register,
    reset,
    watch,

    formState: { errors },
  } = useForm({
    defaultValues: formData,
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (!captchaValue) {
      setCaptchaError("Please complete the captcha.");
      return;
    }

    const watchField = watch();
    console.log("🚀 ~ onSubmit ~ watchField:", watchField);
    if (data) {
      dispatch(applyJob(data));

      const formValue = objectToFormData(formData);
      formValue.append("jobId", id);
      if (watchField.resume) {
        formValue.append("resume", watchField.resume[0]);
      }
      mechanicApplyJob(formValue)
        .then((submitMechnical) => {
          if (submitMechnical.status === "success") {
            toast.success(_t("Job application submit successfully"));
            setCurrentStep(totalCase);
            dispatch(clearJob());
            reset();
          }
        })
        .catch((err) => {
          toast.error(_t(err?.message));
        });
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4>If you have a resume, please upload it here</h4>
        <div className="dflex-inputbar form-100 mb-4">
          <Form.Group className="forminput" controlId="resume">
            <Form.Control
              aria-label="Default select example"
              type="file"
              className="form-control arrow-dropdown"
              {...register("resume")}
            />
            <p className="error">
              {" "}
              {errors.resume && `${errors.resume.message}`}
            </p>
          </Form.Group>
        </div>
        <p>
          {errors.specificVehicles && `${_t(errors.specificVehicles.message)}`}
        </p>
        <div className="dflex-inputbar form-100">
          <FormFieldCaptcha
            value={captchaValue}
            onChange={handleCaptchaChange}
            errorText={captchaError}
            showError={Boolean(captchaError)}
          />
        </div>
      </div>

      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Submit")}
        </button>
      </div>
    </form>
  );
};

export default UploadResume;
