import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";

import useTranslator from "../../hooks/useTranslator";
import { requestHandler } from "../../utils/comman";
import toast from "react-hot-toast";
import { updatePassword } from "../../api-services/user";

const UpdatePasswordModel = ({ id, show, setShow }) => {
  const { _t } = useTranslator();
  const [password, setPassword] = useState(null);

  const handleHide = () => {
    setShow(false);
  };

  const updatePasswordHandler = () => {
    requestHandler(
      async () => await updatePassword({ password: password, id }),
      null,
      (res) => {
        toast.success("update password successfully");
        setShow(false);
      },
      (error) => {
        toast.error(error);
      }
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        centered
        className="darkmode-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="userrole-detail-modal ">
            <Form.Control
              required
              type="text"
              onChange={(e) => setPassword(e.target.value)}
              placeholder={_t("enter your update password")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btnwhite" onClick={updatePasswordHandler}>
            Save Change
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdatePasswordModel;
