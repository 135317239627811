const isBrowser = typeof window !== "undefined";

export const requestHandler = async (
  api = () => {},
  setLoading = () => {},
  onSuccess = () => {},
  onError = () => {}
) => {
  // Show loading state if setLoading function is provided
  setLoading && setLoading(true);
  try {
    // Make the API request
    const response = await api();

    const { data } = response;
    if (response?.status === "success") {
      // Call the onSuccess callback with the response data
      onSuccess(response);
    }
  } catch (error) {
    // Handle error cases, including unauthorized and forbidden cases

    onError(error?.message || "Something went wrong");
  } finally {
    // Hide loading state if setLoading function is provided
    setLoading && setLoading(false);
  }
};
