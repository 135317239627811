import React, { useEffect, useState } from "react";
import BackButton from "../../../Components/BackToPrev";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  capitalizedString,
  haveValue,
  uniqueValueArray,
} from "../../../utils/helpers";
import Accordion from "react-bootstrap/Accordion";

import toast from "react-hot-toast";
import {
  getLangData,
  updateEnglishLang,
} from "../../../api-services/language.api";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const LangData = ({ langCode }) => {
  const [formData, setFormData] = useState([]);
  console.log("🚀 ~ useEffect ~ langCode:", langCode);

  useEffect(() => {
    getLangData({ lang: langCode })
      .then((data) => {
        if (data.status === "success") {
          setFormData(data.data);
        }
      })
      .catch((e) => console.log("e", e));
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevData) =>
      prevData.map((item) =>
        item.key === name ? { ...item, content: value } : item
      )
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEnglishLang({ data: formData })
      .then((resUpdate) => {
        if (resUpdate.status === "success") {
          toast.success("Update Language  successfully");
        }
      })
      .catch((err) => console.log("error while update", err));
  };

  const categoriesList = uniqueValueArray(formData, (item) => item.page);
  console.log("🚀 ~ EnglishLang ~ categoriesList:", categoriesList);

  return (
    <div className="account-settings mt-4">
      <Accordion defaultActiveKey="0">
        <Form className="" onSubmit={handleSubmit}>
          {categoriesList?.map((category, index) => (
            <Accordion.Item eventKey={category.value}>
              <Accordion.Header>{category.value}</Accordion.Header>
              <Accordion.Body key={index}>
                {formData.length &&
                  formData
                    ?.filter((item) => item.page === category.value)
                    ?.map((item, index) => (
                      <Form.Group
                        className="formninputbar"
                        controlId={item.key}>
                        <Form.Label>{item.key}</Form.Label>
                        <Form.Control
                          type="text"
                          name={item.key}
                          value={item.content}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
          <button type="submit" className="btn btnorange btn btn-primary mt-5">
            Publish Content
          </button>
        </Form>
      </Accordion>
    </div>
  );
};

export default LangData;
