import React from "react";
import useTranslator from "../../../hooks/useTranslator";

const JobSummary = ({ qualifications }) => {
  const { _t } = useTranslator();

  return (
    <>
      <div className="spacer-40"></div>
      <h5>{_t("Qualifications")}</h5>

      <ul className="listing-ul">
        {qualifications?.education?.map((info) => (
          <li key={info}>{info?.name}</li>
        ))}
      </ul>
      <div className="spacer-40"></div>

      <h5>{_t("Experience")}</h5>
      <ul className="listing-ul">
        {qualifications?.experience?.map((info, index) => (
          <li key={index}>{info?.name}</li>
        ))}
      </ul>
    </>
  );
};

export default JobSummary;
