import React from "react";
import { Link } from "react-router-dom";
import { formatDateWithoutTime, urlEncode } from "../../../utils/helpers";
import useTranslator from "../../../hooks/useTranslator";
import { blogCoverImageUrl } from "../../../config";

const FeatureArticle = ({ primaryBlog }) => {
  const { _t } = useTranslator();
  return (
    <>
      <section className="articleblog-block">
        <div className="container">
          <h4>{_t("Featured article")}</h4>

          <div className="Articlebloglisting bloglisting">
            <div className="blog-content-detail">
            <img src={`${blogCoverImageUrl}/${primaryBlog.coverImage}`} alt="img" />
            </div>
            <div className="blog-title">
              <div className="title-tophead">
                <div className="btn btngray">{primaryBlog?.category}</div>
                <h6>
                  <img src="/images/calender.webp" alt="icon" />
                  {formatDateWithoutTime(primaryBlog?.createdAt)}
                </h6>
              </div>
              <h5>{primaryBlog?.title}</h5>
              <p>{primaryBlog?.introduction}</p>
              <div className="blogbtn">
                <Link
                  className="btn btnorange"
                  to={`/blog/${urlEncode(primaryBlog?.title)}`}>
                  {_t("Learn More")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureArticle;
