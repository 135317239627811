import React from "react";
import { QuickFormConstant } from "../../../constants/quick-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob } from "../../../Redux/Slices/jobsSlice";
import { t } from "i18next";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const USER_EXPERIENCES = QuickFormConstant.USER_EXPERIENCES;

const Experience = ({ setCurrentStep, totalCase }) => {
  const { _t } = useTranslator();
  const userRole = useSelector((state) => state.authSlice.usrRole);
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);

  const handleNext = () => {
    setCurrentStep((prevStep) =>
      prevStep < totalCase ? prevStep + 1 : prevStep
    );
  };

  useScrollTopForm(`jobapply`);


  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const schema = yup
    .object()
    .shape({
      experienceYears: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      dispatch(applyJob(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4 className="mb-5">
          {userRole === "business_owner"
            ? t("How many years have you been in business?")
            : t("How many years of experience do you have?")}
        </h4>
        <div className="flexbar">
          <label className="selectbox w-100 h-auto">
            <input
              type="radio"
              name="experienceYears"
              value={USER_EXPERIENCES.UNDER_5}
              {...register("experienceYears")}
            />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("Under 5 Years")}</p>
            </div>
          </label>
          <label className="selectbox w-100 h-auto">
            <input
              type="radio"
              name="experienceYears"
              value={USER_EXPERIENCES.FIVE_PLUS}
              {...register("experienceYears")}
            />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("5+ Years")}</p>
            </div>
          </label>
          <label className="selectbox w-100 h-auto">
            <input
              type="radio"
              name="experienceYears"
              value={USER_EXPERIENCES.TEN_PLUS}
              {...register("experienceYears")}
            />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("10+ Years")}</p>
            </div>
          </label>
          <label className="selectbox w-100 h-auto">
            <input
              type="radio"
              name="experienceYears"
              value={USER_EXPERIENCES.FIFTEEN_PLUS}
              {...register("experienceYears")}
            />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("15+ Years")}</p>
            </div>
          </label>
          <p className="error">
            {" "}
            {errors.experienceYears && `${_t(errors.experienceYears.message)}`}
          </p>
        </div>

        <div className="btnform">
          <button className="btn btnorange" onClick={handleBack}>
            {_t("Back")}
          </button>
          <button className="btn btnorange" type="submit">
            {_t("Next")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Experience;
