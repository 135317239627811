export const QuickFormConstant = {
  servicesInterest: {
    REMOTE_SERVICES: "Remote Services",
    IN_PERSON_SERVICES: "In-person Services",
    BREAKDOWN_SERVICES: "Breakdown Services",
  },
  INDUSTRY_SERVE: {
    AUTOMOTIVE: "Automotive",
    CLASSIC_VEHICLES: "Classic Vehicles",
    MOTORCYCLE: "Motorcycle",
    COMMERCIAL_VEHICLES: "Commercial Vehicles",
    RECREATIONAL_VEHICLES: "RV",
    MARINE_VEHICLES: "Marine Vehicles",
    SMALL_ENGINES: "Small Engines",
    HEAVY_MACHINERY: "Heavy Machinery",
  },
  PROVIDED_SERVICES: {
    WHEEL_TIRE: "Wheel & Tire Services",
    TOW: "Tow Services",
  },
  SPECIFIC_VEHICLES: {
    COMMERCIAL_TRUCKS: "Commercial Truck",
    COMMERCIAL_BUSES: "Commercial Bus",
    RECREATIONAL: "RV",
    HEAVY_DUTY_MACHINERY: "Heavy Duty Machinery",
  },
  USER_EXPERIENCES: {
    UNDER_5: "Under 5 year",
    FIVE_PLUS: "5+ Years",
    TEN_PLUS: "10+ Years",
    FIFTEEN_PLUS: "15+ Years",
  },
};
