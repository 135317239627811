import React from "react";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Switch } from "antd";

import {
  getAdminSetting,
  updateAdminSetting,
} from "../../../api-services/dashboard";
import { useForm } from "react-hook-form";
import { haveValue } from "../../../utils/helpers";
import toast from "react-hot-toast";

const EmailSetting = ({ accountSettingData, fetchData }) => {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    updateAdminSetting(data)
      .then((resUpdate) => {
        if (resUpdate.status === "success") {
          toast.success("Update setting successfully");
          fetchData();
        }
      })
      .catch((err) => console.log("error while update", err));
  };

  const onError = (error) => console.log("error in form ", error);

  const onChange = (label, checked) => {
    setValue(label, checked);
  };

  useEffect(() => {
    if (haveValue(accountSettingData))
      reset({
        notificationEmail: accountSettingData.notificationEmail,
        applicationEmailNotification:
          accountSettingData.applicationEmailNotification,
        leadEmailNotification: accountSettingData.leadEmailNotification,
        contactQueryEmailNotification:
          accountSettingData.contactQueryEmailNotification,
      });
  }, [accountSettingData]);
  return (
    <Form
      className="accountform mt-4"
      onSubmit={handleSubmit(onSubmit, onError)}>
      <Row>
        <Col lg={12}>
          <Form.Group className="formninputbar" controlId="notificationEmail">
            <Form.Label>Notification Email </Form.Label>
            <Form.Control
              type="text"
              {...register("notificationEmail")}
              placeholder="Enter interval in hours ex: 12, 24, 6"
            />
          </Form.Group>
        </Col>
        <Col lg={12} className="mb-3">
          <Form.Group
            className="formninputbar"
            controlId="isNotificationActive">
            <Form.Label>New Application Notification </Form.Label>
            <Switch
              defaultChecked={accountSettingData?.applicationEmailNotification}
              onChange={(e) => onChange("applicationEmailNotification", e)}
            />
          </Form.Group>
        </Col>
        <Col lg={12} className="mb-3">
          <Form.Group
            className="formninputbar"
            controlId="leadEmailNotification">
            <Form.Label>New Lead Notification </Form.Label>
            <Switch
              defaultChecked={accountSettingData?.leadEmailNotification}
              onChange={(e) => onChange("leadEmailNotification", e)}
            />
          </Form.Group>
        </Col>
        <Col lg={12} className="mb-3">
          <Form.Group
            className="formninputbar"
            controlId="contactQueryEmailNotification">
            <Form.Label>Contact Query Notification </Form.Label>
            <Switch
              defaultChecked={accountSettingData?.contactQueryEmailNotification}
              onChange={(e) => onChange("contactQueryEmailNotification", e)}
            />
          </Form.Group>
        </Col>

        <Col lg={12}>
          <Form.Group
            className="formninputbar inputbar-btnpink"
            controlId="btnsave">
            <Button className="btn btnorange" type="submit">
              Save Changes
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default EmailSetting;
