import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import JobSummary from "../../Components/PagesAssets/Jobs/JobSummary";
import JobResponsibility from "../../Components/PagesAssets/Jobs/JobResponsibility";
import JobProfile from "../../Components/PagesAssets/Jobs/JobProfile";
import { getUserSingleJob } from "../../api-services/jobs";
import { capitalizedString, haveValue } from "../../utils/helpers";
import Accordion from "react-bootstrap/Accordion";
import useTranslator from "../../hooks/useTranslator";
import JobApplyForm from "../../Components/Forms/JobApply";
import JobPostingSchema from "../../providers/googleSchema";
import { Helmet } from "react-helmet";
import useScrollTopForm from "../../hooks/useScrollTopForm";

const JobDetailBanner = () => {
  const { id } = useParams();
  const { _t } = useTranslator();
  const [jobInfo, setJobInfo] = useState(null);

  const hash = window.location.hash;
  const navigate = useNavigate();

  useEffect(() => {
    getUserSingleJob({ jobId: id })
      .then((jobData) => {
        if (jobData) {
          setJobInfo(jobData?.data);
        }
      })
      .catch((error) => {
        if (error.status === "error") {
          navigate("/not-found");
        }
        console.error("Error while getting job info", error);
      });
  }, [id]);

  useEffect(() => {
    if (hash === "#applyform") {
      console.log("applyform inner");
      const formApplyElement = document.getElementById("applyform");
      console.log("formApplyElement", formApplyElement);
      formApplyElement.scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("out running ");
      window.scrollTo(0, 0);
    }
  }, [hash]);

  if (haveValue(jobInfo)) {
    return (
      <>
        <Helmet>
          <title>{jobInfo?.jobTitle}</title>
          {/* Set the canonical URL */}
          <link
            rel="canonical"
            href={`https://www.remotemechanicjobs.com/alljobs/${jobInfo?._id}`}
          />
          <link
            rel="alternate"
            hreflang={`${jobInfo?.jobLanguage === "English" ? "en" : "es"}`}
            href={window.location.href}
          />

          <link
            rel="alternate"
            hreflang="en-us"
            href={`https://www.remotemechanicjobs.com/en-us/alljobs/${jobInfo?._id}`}
          />
          <link
            rel="alternate"
            hreflang="es-es"
            href={`https://www.remotemechanicjobs.com/es-es/alljobs/${jobInfo?._id}`}
          />

          {/* Set the meta description */}
          <meta name="description" content={jobInfo?.summary} />
        </Helmet>
        <section className="contact-banner">
          <div className="container">
            <div className="contact-head">
              <h6>
                {_t(capitalizedString(jobInfo?.jobStatus))} {_t("position")}
              </h6>
              <h3>{capitalizedString(jobInfo?.jobTitle)}</h3>
            </div>
            <div className="contact-banner-bottom">
              <ul className="contactdetails">
                <li>
                  <h4>{_t("Location")}</h4>
                  <p>
                    {jobInfo?.city && jobInfo?.city + ","} {jobInfo?.state} -{" "}
                    {_t("Remote")}
                  </p>
                </li>

                <li>
                  <h4>{_t("Pay Range")}</h4>
                  <p>
                    <span>$ </span>
                    {jobInfo?.rate?.rateType === "range"
                      ? jobInfo?.rate?.min + " - " + "$ " + jobInfo?.rate?.max
                      : jobInfo?.rate?.rateValue}
                    / {capitalizedString(jobInfo?.rate?.rateTimeLine)}
                  </p>
                </li>
              </ul>
              <div className="spacer-50"></div>
              <div className="btnapply">
                <Link
                  to={`/alljobs/${jobInfo?._id}#applyform`}
                  className="btn btnorange">
                  {_t("Apply")}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <div className="applyjob-conditions">
          <div className="container">
            <h4>{_t("Summary")}</h4>
            <p className="preline">{_t(jobInfo?.summary)}</p>

            <div className="spacer-70"></div>
            <h4>{_t("The Specifics")}</h4>
            <JobResponsibility responsibility={jobInfo?.specifics} />
            <div className="spacer-70"></div>
            <h4>{_t("Qualifications and Experience")}</h4>
            <JobSummary qualifications={jobInfo?.qualifications} />
            {/* <JobProfile /> */}
          </div>
        </div>
        <div class="jobdetail-accordion">
          <div className="container">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{_t("Why apply?")}</Accordion.Header>
                <Accordion.Body className="preline">
                  {jobInfo?.whyApply}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>{_t("Who we are?")}</Accordion.Header>
                <Accordion.Body className="preline">
                  {jobInfo?.aboutCompany}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="job-apply-form">
          <div className="container">
            <h1>{_t("Apply for this Job")}</h1>
            <div id="applyform" className="spacer-70"></div>

            <JobApplyForm jobId={jobInfo?._id} jobInfo={jobInfo} />
          </div>
        </div>
        {jobInfo && <JobPostingSchema jobInfo={jobInfo} />}
      </>
    );
  }
};

export default JobDetailBanner;
