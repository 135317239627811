import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useTranslator from "../../../../hooks/useTranslator";
import {
  addBlog,
  getBlogById,
  updateBlogs,
  updateCoverImage,
} from "../../../../api-services/blog";
import { haveValue, objectToFormData } from "../../../../utils/helpers";
import { requestHandler } from "../../../../utils/comman";
import Loader from "../../../../Components/Loader";
import EditorBox from "../../../../Components/Editor";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddblogScehma } from "../../../../validations/form-schema";
import BackButton from "../../../../Components/BackToPrev";
import useBlogInfo from "../../../../data-fetch/useBlogInfo";
import { blogCoverImageDoc, blogCoverImageUrl, imageBaseUrl } from "../../../../config";

const AddBlogPage = () => {
  const params = window.location.search.toString();
  const edit = params.includes("edit");

  const { id } = useParams();
  const navigate = useNavigate();

  const { _t } = useTranslator();
  const [blogInfo, setBlogInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [content, setContent] = useState("");

  const fetchData = () => {
    if (haveValue(id)) {
      requestHandler(
        async () => await getBlogById({ id }),
        setLoading,
        (res) => {
          const { data } = res;
          setBlogInfo(data);
        },
        setError
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      altTag: "",
    },
    resolver: yupResolver(AddblogScehma),
    mode: "onSubmit",
  });

  const watchField = watch();

  const onSubmit = (data) => {
    const formData = new FormData();

    for (let key in watchField) {
      let value = watchField[key];

      if (key === "coverImage") {
        const image = value[0];
        formData.append(key, image);
      } else {
        formData.append(key, value);
      }
    }

    formData.forEach((value, key) => {
      console.log("value -----------------", value, key);
    });
    if (edit) {
      formData.append("id", id);
      requestHandler(
        async () => await updateBlogs(formData),
        null,
        (res) => {
          const { data } = res;
          if (haveValue(data)) {
            toast.success("update blog successfully");
            fetchData();
          }
        },
        (error ) => {
          console.log("error during update blog images", error)
        }
      );
    } else {
      requestHandler(
        async () => await addBlog(formData),
        setLoading,
        (res) => {
          const { data } = res;
          toast.success("blog added successfully");
          navigate("/admin/blogs");
        },
        setError
      );
    }
  };

  const onError = (error) =>
    console.log("error white adding and edit blog post", error);

  useEffect(() => {
    if (haveValue(blogInfo) && edit) {
      reset({
        title: blogInfo.title,
        summary: blogInfo.summary,
        keywords: blogInfo.keywords,
        introduction: blogInfo.introduction,
        content: blogInfo.content,
        coverImage: blogInfo.coverImage || [],
        category: blogInfo.category || "",
        tags: blogInfo.tags,
        altTag: blogInfo?.altTag,
        blogLang: blogInfo?.blogLang,
      });
      setContent(blogInfo.content);
    }
  }, [blogInfo, id]);

  useEffect(() => {
    if (haveValue(content)) {
      setValue("content", content);
    }
  }, [content]);

  if (edit && loading) {
    return <Loader />;
  }

  if (error && edit) {
    toast.error(error);
  }

  return (
    <div>
      <div className="top-header">
        <div className="page-name title-with-backbtn mb-10">
          <BackButton />
          <h4>{edit ? "Edit Blog" : "Add Blog"}</h4>
        </div>
      </div>
      <Form className="accountform" onSubmit={handleSubmit(onSubmit, onError)}>
        <Row>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="isLicensed">
              <Form.Label>{_t("Blog Language")}</Form.Label>
              <div className="filter">
                <select {...register("blogLang")}>
                  <option value="en-us">{_t("English")}</option>
                  <option value="es-es">{_t("Spanish")}</option>
                </select>
              </div>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="title">
              <Form.Label>{_t("Blog title")}</Form.Label>
              <Form.Control type="text" {...register("title")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.title && `${errors.title.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="category">
              <Form.Label>{_t("Category")}</Form.Label>
              <Form.Control type="text" {...register("category")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.title && `${errors.title.message}`}
            </p>
          </Col>
          {haveValue(blogInfo?.coverImage) && (
            <img src={`${blogCoverImageUrl}/${blogInfo.coverImage}`} className="mb-3" />
          )}
          <Row className="mb-3">
            <Col lg={6}>
              <Form.Group className="forminput" controlId="coverImage">
                {/* <Form.Control
                aria-label="Default select example"
                type="file"
                className="form-control arrow-dropdown"
                {...register("coverImage")}
              /> */}
                <Form.Label>{_t("Cover Image")}</Form.Label>
                <input
                  class="form-control "
                  type="file"
                  accept=".webp,.jpeg,.png,jpg"
                  {...register("coverImage")}
                />
                <p className="error">
                  {" "}
                  {errors.coverImage && `${errors.coverImage.message}`}
                </p>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="forminput" controlId="altTag">
                <Form.Label>{_t("Alt Tag")}</Form.Label>
                <Form.Control
                  aria-label="Default select example"
                  type="text"
                  className="form-control arrow-dropdown"
                  {...register("altTag")}
                />

                <p className="error">
                  {" "}
                  {errors.altTag && `${errors.altTag.message}`}
                </p>
              </Form.Group>
            </Col>
          </Row>

          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="introduction">
              <Form.Label>{_t("Introduction")}</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={6}
                {...register("introduction")}
              />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.introduction && `${errors.introduction.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <EditorBox content={content} setContent={setContent} />
            <p className="error">
              {" "}
              {errors.content && `${errors.content.message}`}
            </p>
          </Col>

          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="summary">
              <Form.Label>{_t("Summary")}</Form.Label>
              <Form.Control type="text" {...register("summary")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.summary && `${errors.summary.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="tags">
              <Form.Label>{_t("Tags")}</Form.Label>
              <Form.Control type="text" {...register("tags")} />
            </Form.Group>
            <p className="error"> {errors.tags && `${errors.tags.message}`}</p>
          </Col>
        </Row>
        <button className="btn btnorange" type="submit">
          {edit ? "Edit" : "Add Blog"}
        </button>
      </Form>
    </div>
  );
};

export default AddBlogPage;
