import React, { useEffect } from "react";
import { haveValue } from "../utils/helpers";

const useScrollTopForm = (newHash) => {
  const hash = window.location.hash;
  console.log("🚀 ~ useScrollTopForm ~ hash:", hash);

  useEffect(() => {
    if (haveValue(hash)) {
      const id = newHash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        console.log("🚀 ~ useEffect ~ element:", element);
        const y = element.getBoundingClientRect().top + window.scrollY;
        console.log("🚀 ~ useEffect ~ y:", y);

        setTimeout(() => {
          window.scroll({ top: y, behavior: "smooth" });
        }, 100);
      }
    }
  }, [newHash]);
};

export default useScrollTopForm;
