import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import useScrollTopForm from "../../../hooks/useScrollTopForm";
import { useNavigate } from "react-router-dom";

const StepOne = ({ setCurrentStep, totalCase }) => {
  const { _t } = useTranslator();

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formSlice.data);
  useScrollTopForm(`fromapply`);
  const navigate = useNavigate()

  const schema = yup
    .object()
    .shape({
      category: yup.string().required(),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      dispatch(saveData(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
      navigate("#formapply")
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4 className="mb-5">{_t("Which category best describes you?")}</h4>
        <div className="flexbar">
          <label className="selectbox">
            <input
              type="radio"
              name="category"
              value="Shop Owner"
              {...register("category")}
            />
            <div class="primebox">
              <img
                src="/images/multiuser.svg"
                alt="icon"
                srcSet="/images/multiuser.svg 4x,/images/multiuser.svg 2x"
              />
              <h5>{_t("Shop Owner")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="radio"
              name="category"
              value="Mechanic"
              {...register("category")}
            />
            <div class="primebox">
              <img
                src="/images/singleuser.svg"
                alt="icon"
                srcSet="/images/multiuser.svg 4x,/images/multiuser.svg 2x"
              />
              <h5>{_t("Mechanic")} </h5>
            </div>
          </label>
          <p className="error">
            {" "}
            {errors.category && `${_t(errors.category.message)}`}
          </p>
        </div>
        <div className="btnform">
          <button className="btn btnorange" type="submit">
            {_t("Next")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepOne;
