import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getUserJobs = async (data) => {
  const response = await sendRequest(Routes.job.userListing, "GET", data);
  return response;
};

export const AdminJobsFetch = async (data) => {
  const response = await sendRequest("/jobs/admin", "GET", data);
  return response;
};

export const postJob = async (data) => {
  const response = await sendRequest("jobs", "POST", data);
  return response;
};

export const getUserSingleJob = async (data) => {
  const response = await sendRequest(Routes.job.usrJobs, "GET", data);
  return response;
};
export const getJobListing = async (data) => {
  const response = await sendRequest(Routes.job.jobListing, "GET", data);
  return response;
};
export const editJobInfo = async (data) => {
  const response = await sendRequest(Routes.job.jobEdit, "PUT", data);
  return response;
};
export const deleteJobInfo = async (data) => {
  const response = await sendRequest(Routes.job.jobDeleted, "PUT", data);
  return response;
};
export const rePublishJobInfo = async (data) => {
  const response = await sendRequest(Routes.job.jobRepublish, "PUT", data);
  return response;
};

export const states = async (data) => {
  const response = await sendRequest(Routes.helper.states, "GET", data);
  return response;
};
export const cities = async (data) => {
  console.log("🚀 ~ cities ~ data:", data)
  const response = await sendRequest(Routes.helper.cities, "GET", data);
  return response;
};
