import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { QuickFormConstant } from "../../../constants/quick-form";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob } from "../../../Redux/Slices/jobsSlice";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const serviceConstant = QuickFormConstant.servicesInterest;

const ServiceInfo = ({ setCurrentStep, totalCase }) => {
  const { _t } = useTranslator();
  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);
  const [selectAll, setSelectAll] = useState(false);

  useScrollTopForm(`jobapply`);


  const schema = yup
    .object()
    .shape({
      servicesInterest: yup.array(yup.string().optional()).optional(),
    })
    

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      dispatch(applyJob(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const toggleSelectAll = (e) => {
    setSelectAll(!selectAll);
    const allServices = [
      serviceConstant.BREAKDOWN_SERVICES,
      serviceConstant.IN_PERSON_SERVICES,
      serviceConstant.REMOTE_SERVICES,
    ];
    if (selectAll) {
      setValue("servicesInterest", []);
    } else {
      setValue("servicesInterest", allServices);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4>{_t("What Services Are You interested in Providing?")}</h4>
        <h6 className="mb-4 text-center">( Select all that apply )</h6>
        <label className="selectbox w-100 h-auto">
          <input
            type="checkbox"
            name="servicesInterest"
            value={serviceConstant.REMOTE_SERVICES}
            {...register("servicesInterest")}
          />
          <span className="checkmark"></span>
          <div className="primebox">
            <p>{_t("Remote Services")}</p>
          </div>
        </label>
        <label className="selectbox w-100 h-auto">
          <input
            type="checkbox"
            name="servicesInterest"
            value={serviceConstant.IN_PERSON_SERVICES}
            {...register("servicesInterest")}
          />
          <span className="checkmark"></span>
          <div className="primebox">
            <p>{_t("In-person Services")}</p>
          </div>
        </label>
        <label className="selectbox w-100 h-auto">
          <input
            type="checkbox"
            name="servicesInterest"
            value={serviceConstant.BREAKDOWN_SERVICES}
            {...register("servicesInterest")}
          />
          <span className="checkmark"></span>
          <div className="primebox">
            <p>{_t("Breakdown Services")}</p>
          </div>
        </label>
        {/* <label className="selectbox w-100 h-auto">
          <input
            type="checkbox"
            name="servicesInterest"
            onChange={(e) => toggleSelectAll(e)}
          />
          <span className="checkmark"></span>
          <div className="primebox">
            <p>{_t("( Select all that apply )")}</p>
          </div>
        </label> */}
        <p className="error">
          {" "}
          {errors.servicesInterest && `${_t(errors.servicesInterest.message)}`}
        </p>

        <div className="btnform">
          <button className="btn btnorange" onClick={handleBack}>
            {_t("Back")}
          </button>
          <button className="btn btnorange" type="submit">
            {_t("Next")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ServiceInfo;
