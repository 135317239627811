import React, { useEffect } from "react";

const Termcondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="aboutbanner">
        <div className="container">
          <h2>Coming Soon.</h2>
          <div class="bar-line"></div>
        </div>
      </div>
    </>
  );
};

export default Termcondition;
