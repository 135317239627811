import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link, useParams } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import Multiselect from "multiselect-react-dropdown";
import { IoMdDocument } from "react-icons/io";
import { MdDoNotDisturb } from "react-icons/md";

import {
  applicationProfile,
  editApplicantProfile,
} from "../../../api-services/application";
import {
  StateList,
  formatDateWithoutTime,
  haveValue,
  multiSelectOptions,
} from "../../../utils/helpers";
import toast from "react-hot-toast";
import { useForm, useWatch } from "react-hook-form";
import { ApplicationSTATUS } from "../../../constants/applicant";
import { QuickFormConstant } from "../../../constants/quick-form";
import BackButton from "../../../Components/BackToPrev";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";
import { blogCoverImageDoc } from "../../../config";
import useStateAndCity from "../../../data-fetch/useStateAndCity";

const ApplicantPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const { jobCategories } = useFetchJobCategory();
  const [createdAt, setCreateAt] = useState(null);
  const { userid } = useParams();

  const {
    fetchCities,
    stateList: stateListMap,
    cityList,
    loading,
  } = useStateAndCity();

  const [applicationData, setApplicationData] = useState(null);

  const { _t } = useTranslator();

  const fetchProfile = () => {
    applicationProfile({ applicantId: userid })
      .then((res) => {
        if (haveValue(res.data)) {
          setApplicationData(res.data);
        }
      })
      .catch((error) => {
        toast.error(_t(error?.message));
      });
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onSubmit",
  });

  const show = useWatch({ control });
  console.log("🚀 ~ ApplicantPage ~ show:", show);
  const isMechanic = show.category === "Mechanic" ? true : false;

  useEffect(() => {
    fetchProfile();
  }, []);

  const valueUpdate = (array) => array.map((item) => item.name);
  const providedServicesSeledct = (selectedList) => {
    valueUpdate(selectedList);
    setValue("providedServices", valueUpdate(selectedList));
  };

  const providedServicesRemove = (selectedList) => {
    setValue("providedServices", valueUpdate(selectedList));
  };
  const interestedServicesSelect = (selectedList) => {
    setValue("servicesInterest", valueUpdate(selectedList));
  };

  const interestedServicesSelectRemove = (selectedList) => {
    setValue("servicesInterest", valueUpdate(selectedList));
  };

  const industryServicesSelect = (selectedList) => {
    setValue("vehicles", valueUpdate(selectedList));
  };

  const industryServicesSelectRemove = (selectedList) => {
    setValue("vehicles", valueUpdate(selectedList));
  };

  const selectJobNiche = (selectedList) => {
    setValue("jobNiche", valueUpdate(selectedList));
  };
  const removeJobNiche = (selectedList) => {
    setValue("jobNiche", valueUpdate(selectedList));
  };

  useEffect(() => {
    if (haveValue(applicationData)) {
      console.log("🚀 ~ useEffect ~ applicationData:", applicationData);
      reset({
        id: applicationData?._id,
        name: applicationData?.name || "",
        lName: applicationData?.lName || "",
        business_name: applicationData?.business_name || "",
        category: applicationData?.category,
        certificationText: applicationData?.certificationText || "",
        certifiedTechnician: applicationData?.certifiedTechnician || false,
        city: applicationData?.city || "",
        email: applicationData?.email || "",
        experienceYears: applicationData?.experienceYears || "",
        vehicles: applicationData?.vehicles || [],
        jobNiche: applicationData?.jobNiche || [],
        isLicensed: applicationData?.isLicensed || false,
        jobId: applicationData?.jobId?._id,
        mobileNo: applicationData?.mobileNo || "",
        numberOfTechnicians: applicationData?.numberOfTechnicians,
        providedServices: applicationData?.providedServices || [],
        resume: applicationData?.resume || null,
        servicesInterest: applicationData?.servicesInterest || [],
        state: applicationData?.state || "",
        status: applicationData.status,
        notes: haveValue(applicationData?.notes) ? applicationData?.notes : "",
      });
      setCreateAt(formatDateWithoutTime(applicationData.createdAt));
    }
  }, [userid, applicationData]);

  const onSubmit = (data) => {
    editApplicantProfile(data)
      .then((res) => {
        if (res.status === "success") {
          toast.success(_t(res.message));
          fetchProfile();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const onError = (error) => console.log("err during form submission", error);

  useEffect(() => {
    if (applicationData) {
      const filterState = StateList.find(
        (state) => state.name === applicationData.state
      );

      setSelectedCity(applicationData?.city);
      setSelectedState(filterState?.iso);
    }
  }, [applicationData?.state, applicationData?.city]);

  const handleState = (e) => {
    const value = e.target.value;
    console.log("🚀 ~ StepSeven ~ value:", value);
    const filterState = StateList.find((st) => st.iso === value);
    fetchCities(value);
    console.log("🚀 ~ handleState ~ filterState:", filterState);
    setValue("state", filterState?.name);
    setSelectedState(value);
  };

  if (applicationData) {
    return (
      <div className="users-page">
        <div className="top-header mb-5">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Application Edit</h4>
          </div>
        </div>
        <Form
          className="accountform"
          onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Col lg={6}>
              {applicationData?.jobId?.jobTitle && (
                <Col lg={12}>
                  <Form.Group className="formninputbar" controlId="jobId">
                    <Form.Label>{_t("Job title")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={applicationData?.jobId?.jobTitle}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              )}

              <Col lg={12}>
                <Form.Group className="formninputbar" controlId="isLicensed">
                  <Form.Label>{_t("User Category")}</Form.Label>
                  <div className="filter">
                    <select {...register("category")}>
                      <option value="Mechanic">{_t("Mechanic")}</option>
                      <option value="Shop Owner">{_t("Shop Owner")}</option>
                    </select>
                  </div>
                </Form.Group>
              </Col>

              <div className="multiselect-bar">
                <h5 className="formtitle">{_t("Job Niche")}</h5>
                <Multiselect
                  options={jobCategories}
                  selectedValues={multiSelectOptions(applicationData?.jobNiche)}
                  onSelect={selectJobNiche}
                  onRemove={removeJobNiche}
                  displayValue="name"
                />
              </div>

              <Col lg={12}>
                <Form.Group className="formninputbar" controlId="firstName">
                  <Form.Label>{_t("First Name")}</Form.Label>
                  <Form.Control type="text" {...register("name")} />
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="formninputbar" controlId="firstName">
                  <Form.Label>{_t("Last Name")}</Form.Label>
                  <Form.Control type="text" {...register("lName")} />
                </Form.Group>
              </Col>

              {!isMechanic && (
                <Col lg={12}>
                  <Form.Group
                    className="formninputbar"
                    controlId="business_name">
                    <Form.Label>{_t("Business Name")}</Form.Label>
                    <Form.Control type="text" {...register("business_name")} />
                  </Form.Group>
                </Col>
              )}
              <Col lg={12}>
                <Form.Group className="formninputbar" controlId="email">
                  <Form.Label>{_t("Email")}</Form.Label>
                  <Form.Control type="email" {...register("email")} />
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Group className="formninputbar" controlId="email">
                  <Form.Label>{_t("Phone Number")}</Form.Label>
                  <Form.Control type="text" {...register("mobileNo")} />
                </Form.Group>
              </Col>
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="formninputbar" controlId="status">
                    <Form.Label>{_t("Status")}</Form.Label>
                    <div className="filter">
                      <select {...register("status")}>
                        <option value="">All</option>
                        <option value={ApplicationSTATUS.APPLIED}>
                          {ApplicationSTATUS.APPLIED}
                        </option>
                        <option value={ApplicationSTATUS.APPROVED}>
                          {ApplicationSTATUS.APPROVED}
                        </option>
                        <option value={ApplicationSTATUS.CONTACTED}>
                          {ApplicationSTATUS.CONTACTED}
                        </option>
                        <option value={ApplicationSTATUS.INTERESTED}>
                          {ApplicationSTATUS.INTERESTED}
                        </option>
                        <option value={ApplicationSTATUS.NOT_INTERESTED}>
                          {ApplicationSTATUS.NOT_INTERESTED}
                        </option>
                        <option value={ApplicationSTATUS.ONBOARDED}>
                          {ApplicationSTATUS.ONBOARDED}
                        </option>
                        <option value={ApplicationSTATUS.REJECTED}>
                          {ApplicationSTATUS.REJECTED}
                        </option>
                        <option value={ApplicationSTATUS.Lead}>
                          {ApplicationSTATUS.Lead}
                        </option>
                      </select>
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="formninputbar" controlId="email">
                    <Form.Label>{_t("Application Date")}</Form.Label>
                    <Form.Control type="text" readOnly value={createdAt} />
                  </Form.Group>
                </Col>
              </Row>

              <div className="multiselect-bar">
                <h5 className="formtitle">{_t("Services Applied")}</h5>
                <Multiselect
                  options={[
                    {
                      name: QuickFormConstant?.servicesInterest
                        .BREAKDOWN_SERVICES,
                    },
                    {
                      name: QuickFormConstant?.servicesInterest
                        .IN_PERSON_SERVICES,
                    },
                    {
                      name: QuickFormConstant?.servicesInterest.REMOTE_SERVICES,
                    },
                  ]}
                  selectedValues={multiSelectOptions(
                    applicationData?.servicesInterest
                  )}
                  onSelect={(value) => interestedServicesSelect(value)}
                  onRemove={(value) => interestedServicesSelectRemove(value)}
                  displayValue="name"
                />
              </div>

              <div className="multiselect-bar mt-10">
                <h5 className="formtitle">Additional Services</h5>
                <Multiselect
                  options={multiSelectOptions([
                    QuickFormConstant.PROVIDED_SERVICES.TOW,
                    QuickFormConstant.PROVIDED_SERVICES.WHEEL_TIRE,
                  ])}
                  selectedValues={multiSelectOptions(
                    applicationData?.providedServices
                  )}
                  onSelect={(value) => providedServicesSeledct(value)}
                  onRemove={(value) => providedServicesRemove(value)}
                  displayValue="name"
                />
              </div>

              <div className="multiselect-bar">
                <h5 className="formtitle">Vehicles</h5>
                <Multiselect
                  options={multiSelectOptions([
                    QuickFormConstant.INDUSTRY_SERVE.AUTOMOTIVE,
                    QuickFormConstant.INDUSTRY_SERVE.CLASSIC_VEHICLES,
                    QuickFormConstant.INDUSTRY_SERVE.COMMERCIAL_VEHICLES,
                    QuickFormConstant.INDUSTRY_SERVE.HEAVY_MACHINERY,
                    QuickFormConstant.INDUSTRY_SERVE.MARINE_VEHICLES,
                    QuickFormConstant.INDUSTRY_SERVE.MOTORCYCLE,
                    QuickFormConstant.INDUSTRY_SERVE.RECREATIONAL_VEHICLES,
                    QuickFormConstant.INDUSTRY_SERVE.SMALL_ENGINES,
                    QuickFormConstant.SPECIFIC_VEHICLES.COMMERCIAL_BUSES,
                    QuickFormConstant.SPECIFIC_VEHICLES.COMMERCIAL_TRUCKS,
                    QuickFormConstant.SPECIFIC_VEHICLES.HEAVY_DUTY_MACHINERY,
                  ])}
                  selectedValues={multiSelectOptions(applicationData?.vehicles)}
                  onSelect={(value) => industryServicesSelect(value)}
                  onRemove={(value) => industryServicesSelectRemove(value)}
                  displayValue="name"
                />
              </div>

              {applicationData?.jobId?.jobLanguage && (
                <Col lg={12}>
                  <Form.Group className="formninputbar" controlId="Language">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      value={applicationData?.jobId?.jobLanguage}
                    />
                  </Form.Group>
                </Col>
              )}

              {isMechanic && (
                <Col lg={12}>
                  <Form.Group className="formninputbar" controlId="Language">
                    <Form.Label>Resume</Form.Label>
                    {applicationData?.resume ? (
                      <button type="button" className="doc-btn">
                        <a
                          href={`${blogCoverImageDoc}/${applicationData?.resume}`}
                          target="_blank">
                          <IoMdDocument /> View Resume
                        </a>
                      </button>
                    ) : (
                      <button type="button" className="doc-btn">
                        <MdDoNotDisturb /> N/A
                      </button>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group className="formninputbar" controlId="state">
                <Form.Label>State</Form.Label>

                <Form.Select
                  aria-label="Default select example"
                  onChange={handleState}
                  value={selectedState}
                  className="form-control arrow-dropdown">
                  <option>State</option>
                  {stateListMap?.map((item) => (
                    <option value={item.isoCode} key={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>

                <p className="error">
                  {" "}
                  {errors.state && `${errors.state.message}`}
                </p>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="formninputbar" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedCity(e.target.value);
                    setValue("city", e.target.value);
                  }}
                  value={selectedCity}
                  className="form-control arrow-dropdown">
                  <option>City</option>
                  {cityList?.map(
                    (item) => (
                      <option value={item.name} key={item.name}>
                        {item.name}
                      </option>
                    )
                  )}
                </Form.Select>

                <p className="error">
                  {" "}
                  {errors.city && `${errors.city.message}`}
                </p>
              </Form.Group>
            </Col>
          </Row>

          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="experienceYears">
              <Form.Label>
                {isMechanic ? "Years Experience" : "Years in Business"}
              </Form.Label>
              <div className="filter">
                <select {...register("experienceYears")}>
                  {/* <option value="">All</option> */}
                  <option value={QuickFormConstant.USER_EXPERIENCES.UNDER_5}>
                    {QuickFormConstant.USER_EXPERIENCES.UNDER_5}
                  </option>

                  <option value={QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS}>
                    {QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS}
                  </option>

                  <option value={QuickFormConstant.USER_EXPERIENCES.TEN_PLUS}>
                    {QuickFormConstant.USER_EXPERIENCES.TEN_PLUS}
                  </option>

                  <option
                    value={QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS}>
                    {QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS}
                  </option>
                </select>
              </div>
            </Form.Group>
          </Col>

          <Row>
            <Col lg={6}>
              <Form.Group
                className="formninputbar"
                controlId="certifiedTechnician">
                <Form.Label>
                  {isMechanic ? "Certified" : "Certified Technicians"}
                </Form.Label>
                <div className="filter">
                  <select {...register("certifiedTechnician")}>
                    <option value={true}>{_t("Yes")}</option>
                    <option value={false}>{_t("No")}</option>
                  </select>
                </div>
              </Form.Group>
            </Col>

            {!isMechanic && (
              <Col lg={6}>
                <Form.Group
                  className="formninputbar"
                  controlId="certificationText">
                  <Form.Label>{_t("Number of Technician")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("numberOfTechnicians")}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          {applicationData?.certifiedTechnician === true && isMechanic && (
            <Col lg={12}>
              <Form.Group
                className="formninputbar"
                controlId="certificationText">
                <Form.Label>{_t("Certification Info")}</Form.Label>
                <Form.Control type="text" {...register("certificationText")} />
              </Form.Group>
            </Col>
          )}
          <Row>
            {!isMechanic && (
              <>
                <Col lg={6}>
                  <Form.Group className="formninputbar" controlId="isLicensed">
                    <Form.Label>{_t("Licensed and Insured")}</Form.Label>
                    <div className="filter">
                      <select {...register("isLicensed")}>
                        <option value={true}>{_t("Yes")}</option>
                        <option value={false}>{_t("No")}</option>
                      </select>
                    </div>
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="formninputbar" controlId="isOem">
                    <Form.Label>{_t("OEM Certified Business")}</Form.Label>
                    <div className="filter">
                      <select {...register("isOem")}>
                        <option value={true}>{_t("Yes")}</option>
                        <option value={false}>{_t("No")}</option>
                      </select>
                    </div>
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>

          <Row>
            <div className="dflex-inputbar form-100">
              <Form.Label>{_t("Applicant Notes  ")}</Form.Label>
              <Form.Group className="forminput" controlId="jobDescription">
                <Form.Control
                  as="textarea"
                  rows={4}
                  type="text"
                  {...register("notes")}
                />
                <p className="error">
                  {" "}
                  {/* {errors?.summary && `${errors?.summary.message}`} */}
                </p>
              </Form.Group>
            </div>

            <Col lg={12}>
              <Form.Group
                className="formninputbar inputbar-btnpink"
                controlId="btnsave">
                <Button className="btn btnorange" type="submit">
                  Save Changes
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
};

export default ApplicantPage;
