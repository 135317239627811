import { useState } from "react";
import Form from "react-bootstrap/Form";
import { AiOutlinePlus } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loader";

const Accountsetting = () => {
  const { userData } = useSelector((state) => state.authSlice);
 


  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));

    // Simulate an upload delay
    setIsUploading(true);
    setTimeout(() => {
      setIsUploading(false);
    }, 2000); // Replace with actual upload logic
  };
  if (userData) {
    return (
      <>
        <div className="account-settings mt-4">
          <Form className="accountform">
            <div className="profile-picture-bar">
              <div className="profile-image-box">
                <div className="plusbtn">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <AiOutlinePlus />
                </div>
                <div className="imageuplaod-area">
                  {isUploading ? (
                    <div className="loaderbar">
                      <Loader />
                    </div>
                  ) : selectedImage ? (
                    <img src={selectedImage} alt="Uploaded" />
                  ) : (
                    <img src={userData?.profileImage} alt="Uploaded" />
                  )}
                </div>
              </div>
            </div>

            <Row>
              <Col lg={6}>
                <Form.Group className="formninputbar" controlId="firstName">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control type="text" value={userData?.name} />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="formninputbar" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={userData?.email} />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="formninputbar" controlId="password">
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="(555) 555-1234"
                    value={userData?.mobileNo}
                  />
                </Form.Group>
              </Col>
            

              <Col lg={12}>
                <Form.Group
                  className="formninputbar inputbar-btnpink"
                  controlId="btnsave">
                  <Button className="btn btnorange" type="submit">
                    Save Changes
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
};

export default Accountsetting;
