import React, { useEffect, useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import useTranslator from "../../../hooks/useTranslator";
import { useParams } from "react-router-dom";
import { haveValue } from "../../../utils/helpers";
import { getUserSingleJob } from "../../../api-services/jobs";
const JobPost = ({ edit }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [jobFetchData, setJobInfo] = useState(null);
  const [errorJobError, setJobError] = useState(null);
  const { _t } = useTranslator();
  const { id } = useParams();

  useEffect(() => {
    if (haveValue(id)) {
      getUserSingleJob({ jobId: id })
        .then((jobData) => {
          if (jobData) {
            setJobInfo(jobData?.data);
          }
        })
        .catch((error) => {
          console.error("Error while getting job info", error);
          setJobError(error);
        });
    } else {
      // If id is empty, reset the jobFetchData to null
      setJobInfo(null);
    }
  }, [id]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <StepOne
              setCurrentStep={setCurrentStep}
              edit={edit}
              jobFetchData={jobFetchData}
            />
          </>
        );
      case 2:
        return (
          <>
            <StepTwo
              setCurrentStep={setCurrentStep}
              edit={edit}
              jobFetchData={jobFetchData}
            />
          </>
        );

      default:
        return null;
    }
  };
  return (
    <div className="container">
      <div className="block-bar">
        <h1 className="heading">{_t("Publish Job")}</h1>
        <div className="step-form-container">{renderStepContent()}</div>
      </div>
    </div>
  );
};

export default JobPost;
