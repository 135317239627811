import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Headers/logo";
import useTranslator from "../../../hooks/useTranslator";

const Footer = () => {
  const { _t } = useTranslator();
  return (
    <div className="footerbar">
      <div className="container">
        <Row>
          <Col md={12}>
            <div className="foot-logobar">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </Col>
          <Col md={4}>
            <div className="footerleft">
              <h6>{_t("Ready to get started?")}</h6>
              <div className="btn-radius">
                <Link to="/#formapply" className="btn btnorange">
                  {_t("Apply Now")}
                </Link>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <ul className="footernavbar">
              <li>
                <Link to="/">{_t("Home")}</Link>
              </li>
              <li>
                <Link to="/about">{_t("About")}</Link>
              </li>
              <li>
                <Link to="/alljobs">{_t("Jobs")}</Link>
              </li>
              {/* <li>
                <NavLink to="/contact">Contact</NavLink>
              </li> */}
              
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="Copyrightbar">
          <div className="container">
            <Row>
              <Col md={12} lg={12} sm={12}>
                <ul className="copyryt-left">
                  {/* <li>
                    <NavLink to="/termscondition">{_t("Terms & Conditions")}</NavLink>
                  </li> */}
                  <li>
                    <Link to="/privacypolicy">{_t("Privacy Policy")}</Link>
                  </li>
                </ul>
              </Col>
              {/* <Col md={6} lg={6} sm={12}>
                    <ul className="social-icon">
                        <li><Link to="/" ><FaFacebookF /></Link></li>
                        <li><Link to="/" ><FaTwitter /></Link></li>
                        <li><Link to="/" ><FaInstagram /></Link></li>
                    </ul>
                </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
