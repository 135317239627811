import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import useTranslator from "../../../hooks/useTranslator";
import PropTypes from "prop-types";
import JobsFilter from "../../Filter/JobsFilter";
import { useJobList } from "../../../data-fetch/useJobList";

const CovertLead = ({
  show,
  setShowModel,
  onSubmit,
  setSelectedJob,
  selectJob,
  selectJobCategory,
}) => {
  const { _t } = useTranslator();

  const { jobSelectList, error } = useJobList();
  const handleHide = () => {
    setShowModel(false);
  };
  const data = jobSelectList
    .filter((item) => item.category === selectJobCategory)
    .map((item) => ({
      id: item.id,
      value: item.name,
    }));

  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        centered
        className="darkmode-modal">
        <Modal.Header closeButton>
          <Modal.Title>{_t(`Convert To Lead`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="userrole-detail-modal mt-3 mb-3 ">
            <Form.Select
              aria-label="Jobsexample"
              className="filter-dropdown"
              value={selectJob}
              onChange={(e) => {
                setSelectedJob(e.target.value);
              }}>
              <option value="">{_t("Choose job from list")}</option>
              {data?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </Form.Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onSubmit} className="btn btnwhite">
            {_t("Covert Lead")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CovertLead;
