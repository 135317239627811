import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import MainHeader from './../Headers/MainHeader';

import { Crisp } from "crisp-sdk-web";

const HomeLayout = () => {

    useEffect(() => {
        Crisp.configure("84b01440-0e86-4d67-93b4-f39aaf22bed6");
      }, []);


    return (
        <>
            <div className="max-width-window">
                <MainHeader/>
                <div className='home-wrapper'>
                    <Outlet />
                </div>
                <Footer/>
            </div>

        </>
    );
}

export default HomeLayout;