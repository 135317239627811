import React from "react";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

const BenefitshopOwner = () => {
  const { _t } = useTranslator();
  return (
    <div className="CareerOpportunitydivider benefitbar">
      <div className="container">
        <h4>{_t("Benefits for Shop Owners")}</h4>
        <div className="btnlearnmore">
          <Link to="/#formapply" className="btn btnwhite">
            {_t("Apply Now")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BenefitshopOwner;
