import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getAllBlogs = async (data) => {
  const response = await sendRequest(Routes.blogs.allblogs, "GET", data);
  return response;
};

export const updateBlogs = async (data) => {
  const response = await sendRequest(
    Routes.blogs.updateblog,
    "PUT",
    data,
    null,
    "multipart/form-data "
  );
  return response;
};
export const getBlogById = async (data) => {
  const response = await sendRequest(Routes.blogs.getBlogById, "GET", data);
  return response;
};
export const addBlog = async (data) => {
  const response = await sendRequest(
    Routes.blogs.create,
    "POST",
    data,
    null,
    "multipart/form-data "
  );
  return response;
};
export const deleteBlog = async (data) => {
  const response = await sendRequest(Routes.blogs.delete, "POST", data);
  return response;
};
export const updateCoverImage = async (data) => {
  const response = await sendRequest(
    Routes.blogs.updateCoverImage,
    "POST",
    data
  );
  return response;
};
export const categoryBlogs = async (data) => {
  const response = await sendRequest(Routes.blogs.categoryBlogs, "GET", data);
  return response;
};
export const categoryList = async (data) => {
  const response = await sendRequest(Routes.blogs.categoriesList, "GET", data);
  return response;
};
export const setPrimaryBlog = async (data) => {
  const response = await sendRequest(Routes.blogs.isPrimary, "PUT", data);
  return response;
};
