import React, { useState, useEffect } from "react";
import BackButton from "../../../../Components/BackToPrev";
import { AiOutlineSearch } from "react-icons/ai";
import { InputGroup, Form, Table } from "react-bootstrap";
import { LuSettings2 } from "react-icons/lu";
import Paginate from "../../../../Components/Paginate";
import { Link, useParams } from "react-router-dom";
import debounce from "lodash/debounce";

import {
  applicationByJobId,
  applicationStatusUpdate,
} from "../../../../api-services/application";
import { IoDocumentTextOutline } from "react-icons/io5";
import { ApplicationSTATUS } from "../../../../constants/applicant";
import toast from "react-hot-toast";
import useTranslator from "../../../../hooks/useTranslator";
import Loader from "../../../../Components/Loader";
import { MdLocationPin } from "react-icons/md";

const JobApplications = () => {
  const [applicationList, setApplicationList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [jobInfo, setJobInfo] = useState(null);
  const [statusUpdate, setUpdateStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [loading, setLoading] = useState(false);

  let { jobId } = useParams();

  const fineJobInfo = (data) => {
    if (data instanceof Array) {
      const findJob = data?.find((item) => item?.jobId._id === jobId);
      setJobInfo(findJob.jobId);
    }
  };

  const updateStatus = (data) => {
    applicationStatusUpdate(data)
      .then((updateData) => {
        if (updateData.status === "success") {
          toast.success(updateData.message);
        }
      })
      .catch((error) => {
        console.log("Error while update status ", error);
        toast.error(error.message);
      });
  };

  const debouncedSearch = debounce((value) => {
    setLoading(true);
    applicationByJobId({ searchText: value, page, limit, jobId, status })
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          setApplicationList(data?.data?.docs);
          setTotalDocs(data.data.totalDocs);
          fineJobInfo(data?.data?.docs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500); // Adjust the debounce time as needed

  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, page, limit, jobId, status]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const { _t } = useTranslator();

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>{jobInfo?.jobTitle}</h4>
          </div>
          <div className="import-export">
            {/* <Link to="/jobs/add">
              <button>
                <AiFillPlusCircle /> Add Job
              </button>
            </Link> */}
            {/* <button>
              <AiOutlineUpload /> Export
            </button> */}
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Applicant"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
            </InputGroup>
          </div>
          <div className="filter">
            <LuSettings2 size={30} />
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="">All</option>
              <option value={ApplicationSTATUS.APPLIED}>
                {ApplicationSTATUS.APPLIED}
              </option>
              <option value={ApplicationSTATUS.APPROVED}>
                {ApplicationSTATUS.APPROVED}
              </option>
              <option value={ApplicationSTATUS.CONTACTED}>
                {ApplicationSTATUS.CONTACTED}
              </option>
              <option value={ApplicationSTATUS.INTERESTED}>
                {ApplicationSTATUS.INTERESTED}
              </option>
              <option value={ApplicationSTATUS.NOT_INTERESTED}>
                {ApplicationSTATUS.NOT_INTERESTED}
              </option>
              <option value={ApplicationSTATUS.ONBOARDED}>
                {ApplicationSTATUS.ONBOARDED}
              </option>
              <option value={ApplicationSTATUS.REJECTED}>
                {ApplicationSTATUS.REJECTED}
              </option>
            </select>
          </div>
        </div>
        {/* --------------table-------------- */}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>{_t("Name")}</th>
                    <th>{_t("Email")}</th>
                    <th>{_t("Phone")}</th>
                    <th>{_t("Location")}</th>
                    <th>{_t("Status")}</th>

                    <th>{_t("Experience")}</th>
                    <th>{_t("Resume")}</th>
                    <th>{_t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {applicationList?.map((application, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{application?.name}</p>
                          </div>
                        </div>
                      </td>

                      <td>{application?.email}</td>
                      <td className="red-text"> {application?.mobileNo}</td>
                      <td className="red-text">
                        <MdLocationPin /> {application?.city},{" "}
                        {application?.state}
                      </td>
                      <td className="red-text">
                        <Form.Select
                          aria-label="Contractexample"
                          className="form-select"
                          onChange={(e) => {
                            updateStatus({ jobId, status: e.target.value });
                            setUpdateStatus(e.target.value);
                          }}
                          value={application?.status}>
                          <option value={ApplicationSTATUS.APPLIED}>
                            {ApplicationSTATUS.APPLIED}
                          </option>
                          <option value={ApplicationSTATUS.APPROVED}>
                            {ApplicationSTATUS.APPROVED}
                          </option>
                          <option value={ApplicationSTATUS.CONTACTED}>
                            {ApplicationSTATUS.CONTACTED}
                          </option>
                          <option value={ApplicationSTATUS.INTERESTED}>
                            {ApplicationSTATUS.INTERESTED}
                          </option>
                          <option value={ApplicationSTATUS.NOT_INTERESTED}>
                            {ApplicationSTATUS.NOT_INTERESTED}
                          </option>
                          <option value={ApplicationSTATUS.ONBOARDED}>
                            {ApplicationSTATUS.ONBOARDED}
                          </option>
                          <option value={ApplicationSTATUS.REJECTED}>
                            {ApplicationSTATUS.REJECTED}
                          </option>
                        </Form.Select>
                      </td>

                      <td className="red-text">
                        {_t(application?.experienceYears)}
                      </td>
                      <td className="red-text">
                        <IoDocumentTextOutline /> Resume
                      </td>
                      <td>
                        <Link to={`/applicant/${application?._id}`}>
                          <td className="red-text">View More</td>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                total={totalDocs}
                onChange={handlePageChange}
                page={page}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default JobApplications;
