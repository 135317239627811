import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobpost: {
    rate: {
      rateValue: 0,
      min: 0,
      max: 0,
    },
  },
  applyJobData: {
    servicesInterest: [],
    vehicles: [],
    providedServices: [],
    resume: null,
  },
};

const jobSlice = createSlice({
  name: "jobSlice",
  initialState,
  reducers: {
    clearJob: (state) => {
      state.jobpost = {};
      state.applyJobData = {};
    },
    publishJob: (state, action) => {
      state.jobpost = { ...state.jobpost, ...action.payload };
    },
    applyJob: (state, action) => {
      state.applyJobData = { ...state.applyJobData, ...action.payload };
    },
  },
});

export const { jobsList, locations, publishJob, clearJob, applyJob } =
  jobSlice.actions;

export default jobSlice.reducer;
