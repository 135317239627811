import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getApplications = async (data) => {
  const response = await sendRequest(Routes.applicant.GetALL, "GET", data);
  return response;
};

export const applicationByJobId = async (data) => {
  const response = await sendRequest(
    Routes.applicant.ApplicationByJobId,
    "GET",
    data
  );
  return response;
};
export const applicationStatusUpdate = async (data) => {
  const response = await sendRequest(
    Routes.applicant.ApplicationStatusUpdate,
    "PATCH",
    data
  );
  return response;
};

export const mechanicApplyJob = async (data) => {
  const response = await sendRequest(
    Routes.applicant.jobApply,
    "POST",
    data,
    null,
    "multipart/form-data "
  );
  return response;
};
export const businessOwnerApplyJob = async (data) => {
  const response = await sendRequest(Routes.applicant.jobApply, "POST", data);
  return response;
};

export const shortApplicationApply = async (data) => {
  const response = await sendRequest(
    Routes.shortApplication.create,
    "POST",
    data,
    null,
    "multipart/form-data"
  );
  return response;
};
export const getAllShortApplications = async (data) => {
  const response = await sendRequest(Routes.shortApplication.get, "GET", data);
  return response;
};
export const applicationProfile = async (data) => {
  const response = await sendRequest(
    Routes.applicant.applicationProfile,
    "GET",
    data
  );
  return response;
};

export const editApplicantProfile = async (data) => {
  const response = await sendRequest(Routes.applicant.editProfile, "PUT", data);
  return response;
};

export const exportSheetDataApplication = async (data) => {
  const response = await sendRequest(
    Routes.applicant.exportSheetData,
    "PUT",
    data
  );
  return response;
};
export const sendMailOutReach = async (data) => {
  const response = await sendRequest(
    Routes.applicant.sendMailOutReach,
    "PUT",
    data
  );
  return response;
};
