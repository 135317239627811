import React from "react";
import useTranslator from "../../../hooks/useTranslator";
import BasicInfo from "./BasinInfo";
import ServiceInfo from "./ServicesInfo";
import IndustryInfo from "./IndustryInfo";
import FollowingServices from "./FollowingServices";
import Experience from "./Experience";
import IsCertified from "./IsCertified";
import CertificationTextStep from "./CertficationText";
import UploadResume from "./UploadResume";
import IsHeavy from "./IsHeavy";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const MechanicalForm = ({
  setCurrentStep,
  totalCase,
  currentStep,
  isHeavyVehicleSelected,
  isCertified,
  isNumberOfTechnicians,
  jobInfo,
}) => {
  const { _t } = useTranslator();


  useScrollTopForm('jobapply')

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <BasicInfo
              setCurrentStep={setCurrentStep}
              totalCase={totalCase}
              jobInfo={jobInfo}
            />
          </>
        );
      case 2:
        return (
          <>
            <ServiceInfo
              setCurrentStep={setCurrentStep}
              totalCase={totalCase}
            />
          </>
        );

      case 3:
        return (
          <>
            <IndustryInfo
              setCurrentStep={setCurrentStep}
              totalCase={totalCase}
            />
          </>
        );
      case 4:
        if (isHeavyVehicleSelected())
          return (
            <>
              <IsHeavy setCurrentStep={setCurrentStep} totalCase={totalCase} />
            </>
          );
        setCurrentStep(currentStep + 1);
        break;
      case 5:
        if (isHeavyVehicleSelected)
          return (
            <>
              <FollowingServices
                setCurrentStep={setCurrentStep}
                totalCase={totalCase}
                isHeavyVehicleSelected={isHeavyVehicleSelected}
              />
            </>
          );
        setCurrentStep(currentStep + 1);
        break;

      case 6:
        return (
          <>
            <Experience setCurrentStep={setCurrentStep} totalCase={totalCase} />
          </>
        );
      case 7:
        return (
          <>
            <IsCertified
              setCurrentStep={setCurrentStep}
              totalCase={totalCase}
              isNumberOfTechnicians={isNumberOfTechnicians}
            />
          </>
        );
      case 8:
        if (isCertified) {
          return (
            <>
              <CertificationTextStep
                setCurrentStep={setCurrentStep}
                totalCase={totalCase}
              />
            </>
          );
        }
        setCurrentStep(currentStep + 1);
        break;
      case 9:
        return (
          <>
            <UploadResume
              setCurrentStep={setCurrentStep}
              totalCase={totalCase}
              isCertified={isCertified}
            />
          </>
        );
      case 10:
        return (
          <>
            <h4 className="text-center mt-5">{_t("Thank You")}</h4>
          </>
        );
      default:
        return null;
    }
  };

  return <div>{renderContent()}</div>;
};

export default MechanicalForm;
