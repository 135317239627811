import React from "react";
import useTranslator from "../../../hooks/useTranslator";
import { LocalLang } from "../../../utils/helpers";
import { Link } from "react-router-dom";



const CareerOpportunityDivider = () => {
  const lang = LocalLang()
  const { _t } = useTranslator();
  return (
    <div className="CareerOpportunitydivider">
      <div className="container">
        <h4>{_t("Discover Remote Service Opportunities")}</h4>
        <div className="btnlearnmore">
          <Link to={`/alljobs`} className="btn btnwhite">
            {_t("Learn More")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CareerOpportunityDivider;
