import React from "react";
import BackButton from "../../../../Components/BackToPrev";
import AddLeadForm from "../../../../Components/Forms/AddLeadForm";

const AddLead = () => {
  return (
    <div className="users-page">
      <div className="top-header mb-5">
        <div className="page-name title-with-backbtn mb-0">
          <BackButton />
          <h4>Add Lead</h4>
        </div>
      </div>

      <AddLeadForm />
    </div>
  );
};

export default AddLead;
