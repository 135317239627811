import React, { Suspense, useEffect } from "react";
import "../src/Css/style.css";
import "../src/Css/responsive.css";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import MechanicLayout from "./Components/Layout/MechanicLayout";

// import Home from "./Pages/Home";
import About from "./Pages/AboutUs/index";
import Blog from "./Pages/Blog/index";
import Contact from "./Pages/Contact/index";
import BlogDetail from "./Pages/Blog/BlogDetail";

import AllJobs from "./Pages/Jobs/Index";
import JobDetail from "./Pages/Jobs/JobDetail";
import Login from "./Pages/Auth/Login";

// admin component
import SuperAdminLayout from "./Components/Layout/SuperAdminLayout";

import AdminAccount from "./Pages/SuperAdminPages/Account";
import Jobs from "./Pages/SuperAdminPages/Jobs";
import AddJob from "./Pages/SuperAdminPages/Jobs/Add-jobs";
import { useSelector } from "react-redux";

import Applications from "./Pages/SuperAdminPages/Applications";
import JobApplications from "./Pages/SuperAdminPages/Jobs/JobApplications";
import Leads from "./Pages/SuperAdminPages/Leads";
import JobCategory from "./Pages/SuperAdminPages/JobCategory";
import ShortApplications from "./Pages/SuperAdminPages/ShortApplications";
import PrivacyPolicy from "./Pages/FooterPages/privacypolicy";
import Termcondition from "./Pages/FooterPages/termscondition";
import ApplicantPage from "./Pages/SuperAdminPages/ApplicantPage";
import ApplicantProfileCreate from "./Pages/SuperAdminPages/Applications/AddApplicant";
import CookieBanner from "./Components/CookieBanner";
import EditJobs from "./Pages/SuperAdminPages/Jobs/Edit-jobs";
import Loader from "./Components/Loader";
import DashboardPage from "./Pages/SuperAdminPages/Dashboard";
import SettingPage from "./Pages/SuperAdminPages/Setting";
import BlogAdminPage from "./Pages/SuperAdminPages/Blogs";
import AddBlogPage from "./Pages/SuperAdminPages/Blogs/AddBlogs";
import AddLead from "./Pages/SuperAdminPages/Leads/AddLead";
import Users from "./Pages/SuperAdminPages/Users";
import ContactLeads from "./Pages/SuperAdminPages/ContactLeads";
import NotFoundPage from "./Components/NotFound";
import LanguageSetting from "./Pages/SuperAdminPages/Language";
import ApplicantCommunication from "./Pages/SuperAdminPages/OutReachEmail";
import Home from "./Pages/Home";

function AppRouter() {
  const cookiePreference = localStorage.getItem("cookiePreference");
  const { status: isLogin } = useSelector((state) => state.authSlice);

  const history = useNavigate();
  useEffect(() => {
    document.body.setAttribute("data-page", window?.location?.pathname);
  }, [history]);

  return (
    <>
      <Routes>
        <Route element={<MechanicLayout />}>
          <Route exact="true" path="/" element={<Home />} />

          <Route exact="true" path="/about" element={<About />} />
          <Route exact="true" path="/blog" element={<Blog />} />
          <Route exact={true} path="/blog/:id" element={<BlogDetail />} />

          <Route exact="true" path="/contact" element={<Contact />} />
          <Route exact="true" path="/alljobs" element={<AllJobs />} />

          <Route exact="true" path="/alljobs/:id" element={<JobDetail />} />
          <Route
            exact="true"
            path="/termscondition"
            element={<Termcondition />}
          />
          <Route
            exact="true"
            path="/privacypolicy"
            element={<PrivacyPolicy />}
          />
          <Route exact="true" path="/not-found" element={<NotFoundPage />} />
        </Route>
        {/* admin layout */}

        <Route element={<SuperAdminLayout />}>
          <Route path="*" element={<Navigate to="/" />} />

          <Route exact="true" path="/admin" element={<AdminAccount />} />
          <Route exact="true" path="/job/edit/:id" element={<EditJobs />} />
          <Route
            exact="true"
            path="/admin/blog/:id"
            element={<AddBlogPage />}
          />
          <Route exact="true" path="/leads/add" element={<AddLead />} />
          <Route
            exact="true"
            path="/admin/blog/add"
            element={<AddBlogPage />}
          />
          <Route exact="true" path="/admin/blogs" element={<BlogAdminPage />} />
          <Route exact="true" path="/admin/setting" element={<SettingPage />} />
          <Route
            exact="true"
            path="/admin/overview"
            element={<DashboardPage />}
          />
          <Route
            exact="true"
            path="/applicant/mails/:email"
            element={<ApplicantCommunication />}
          />
          <Route
            exact="true"
            path="/applicant/:userid"
            element={<ApplicantPage />}
          />

          <Route exact="true" path="/jobs" element={<Jobs />} />
          <Route exact="true" path="/applications" element={<Applications />} />
          <Route
            exact="true"
            path="/applicant/add"
            element={<ApplicantProfileCreate />}
          />

          <Route
            exact="true"
            path="/short-application"
            element={<ShortApplications />}
          />
          <Route
            exact={true}
            path="/jobs/:jobId"
            element={<JobApplications />}
          />
          <Route exact={true} path="/job-category" element={<JobCategory />} />
          <Route exact="true" path="/jobs/add" element={<AddJob />} />
          <Route exact="true" path="/admin/users" element={<Users />} />
          <Route exact="true" path="/account" element={<AdminAccount />} />
          <Route exact="true" path="/leads" element={<Leads />} />
          <Route
            exact="true"
            path="/admin/language"
            element={<LanguageSetting />}
          />
          <Route
            exact="true"
            path="/contacts-leads"
            element={<ContactLeads />}
          />
        </Route>
        {isLogin ? (
          <Route path="/login" element={<Navigate to="/admin" />} />
        ) : (
          <Route exact="true" path="/login" element={<Login />} />
        )}
      </Routes>

      {!cookiePreference && <CookieBanner />}
    </>
  );
}

export default AppRouter;
