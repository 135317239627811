import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Languagebar from "./Languagebar";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

const MainNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleNavToggle = () => {
    setExpanded(!expanded);
  };

  const handleNavLinkClick = () => {
    setExpanded(!expanded);
  };

  const { _t } = useTranslator();

  return (
    <>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={handleNavToggle}
      />
      {/* Desktop menu bar  */}
      <Navbar.Collapse className="desktop-menu-bar">
        <Nav>
          <Link to="/" onClick={handleNavLinkClick}>
            {_t("Home")}
          </Link>
          <Link to="/about" onClick={handleNavLinkClick}>
            {_t("About")}
          </Link>
          <Link to="/alljobs" onClick={handleNavLinkClick}>
            {_t("Jobs")}
          </Link>
          <Link to="/blog" onClick={handleNavLinkClick}>
            {_t("Blogs")}
          </Link>
        </Nav>
        <div className="rightbar-nav">
          <Languagebar />
          <Link
            to="/login"
            className="btn btnwhite"
            onClick={handleNavLinkClick}>
            {_t("Login")}
          </Link>
        </div>
      </Navbar.Collapse>
      {/* mobile menu  */}
      <Navbar.Collapse
        className="mobile-togglemenu"
        id="basic-navbar-nav"
        in={expanded}>
        <div className="mobile-log-main">
          <div className="mobile-logo">
            <img
              src="/images/logo.svg"
              width={247}
              height={37}
              loading="lazy"></img>
          </div>
          <div className="cross-icon" onClick={handleNavLinkClick}>
            <img src="/images/close-menu.png"></img>
          </div>
        </div>
        <Nav className="mobile-menu-bar">
          <Link to="/" onClick={handleNavLinkClick}>
            {_t("Home")}
          </Link>
          <Link to="/about" onClick={handleNavLinkClick}>
            {_t("About")}
          </Link>
          <Link to="/alljobs" onClick={handleNavLinkClick}>
            {_t("Jobs")}
          </Link>
          <Link to="/blog" onClick={handleNavLinkClick}>
            {_t("Blogs")}
          </Link>
        </Nav>
        <div className="rightbar-nav">
          <Languagebar />
          <Link
            to="/login"
            className="btn btnwhite"
            onClick={handleNavLinkClick}>
            {_t("Login")}
          </Link>
        </div>
      </Navbar.Collapse>
    </>
  );
};

export default MainNavbar;
