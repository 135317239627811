import React, { useState } from "react";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

import ContectModel from "../../Modals/ContactModel";
const Letstalk = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const { _t } = useTranslator();

  return (
    <>
      <div className="let-talk-block">
        <div className="container">
          <div className="letstalk-inner">
            <h6>{_t("let’s talk")}</h6>
            <h3>{_t("Have Questions? Feel Free To Reach Out")}</h3>
            <div className="btn-radius">
              <Link onClick={handleShow} className="btn btnorange">
                {_t("Contact Us")}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ContectModel show={show} setShow={setShow} />
    </>
  );
};

export default Letstalk;
