const ApplicationSTATUS = {
  APPLIED: "Applied",
  CONTACTED: "Contacted",
  INTERESTED: "Interested",
  NOT_INTERESTED: "Not Interested",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ONBOARDED: "Onboarded",
  Lead: "Lead",
};

export { ApplicationSTATUS };
