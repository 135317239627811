import React, { useEffect, useState, memo } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setUserPreferences } from "../../Redux/Slices/authSlice";
import useTranslator from "../../hooks/useTranslator";
import { useLocation } from "react-router-dom";
import Languagebar from "../Layout/Headers/Languagebar";

const UserRole = () => {
  const { _t } = useTranslator();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.authSlice.usrRole);

  const [show, setShow] = useState(true);
  const [hide, setHide] = useState(false);

  const userPreferencesHandler = (data) => {
    dispatch(setUserPreferences(data));
    setHide(true);
    setShow(false);
  };

  const location = useLocation();

  const localRole = localStorage.getItem("usrRole");

  const updateRole = () => {
    if (!userRole && !localRole && location.pathname !== "/alljobs") {
      setShow(true);
    }
    if (location.pathname === "/alljobs") {
      dispatch(setUserPreferences("mechanic"));
    }
  };
  useEffect(() => {
    updateRole();
  }, [localRole, userRole]);

  return (
    <>
      <Modal show={show} centered className="darkmode-modal userrole-modal">
        <Modal.Header>
          <Modal.Title>{_t("User Preferences")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="userrole-modalbody">
          <h2>{_t("Find Your Path to Professional Success")}</h2>
          <div class="bar-line"></div>
          <p>
            {_t(
              "We are resourcing for both independent mechanics and technicians as well as service shops of all sizes."
            )}
          </p>
          <div className="btngroup">
            <button
              className="btn btnorange"
              onClick={() => userPreferencesHandler("mechanic")}>
              {_t("I'm a Mechanic")}
            </button>
            <button
              className="btn btnwhite"
              onClick={() => userPreferencesHandler("business_owner")}>
              {_t("I'm a Business Owner")}
            </button>
          </div>
          <div className="lang-mobile">
           <Languagebar />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(UserRole);
