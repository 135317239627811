import axios from "axios";
import config from "../config";
import sendRequest from "../providers/axios";
import Routes from "./routes";

const baseUrl = config.server.url;

export const quickRegister = async (data) => {
  const axisRes = await sendRequest(`leads`, "POST", data);
  return axisRes;
};

export const contactFormRequestApi = async (data) => {
  try {
    const axisRes = await axios.post(`${baseUrl}/contacts/create`, data);
    return axisRes.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const userAccessControl = async (data) => {
  const response = await sendRequest(Routes.users.accessControl, "PUT", data);
  return response;
};
export const userList = async (data) => {
  const response = await sendRequest(Routes.users.userList, "GET", data);
  return response;
};
export const userInfo = async (data) => {
  const response = await sendRequest(Routes.users.userInfo, "PUT", data);
  return response;
};
export const editUser = async (data) => {
  const response = await sendRequest(Routes.users.editUser, "PUT", data);
  return response;
};
export const updatePassword = async (data) => {
  const response = await sendRequest(Routes.users.updatePassword, "PUT", data);
  return response;
};
