import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob } from "../../../Redux/Slices/jobsSlice";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const IsCertified = ({ setCurrentStep, totalCase, isNumberOfTechnicians }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);

  const isBusinessOwner = formData.category;

  const handleBack = () => {
    setCurrentStep((prevStep) =>
      prevStep > 1 ? prevStep - (isNumberOfTechnicians ? 1 : 2) : prevStep
    );
  };
  useScrollTopForm(`jobapply`);


  const schema = yup
    .object()
    .shape({
      certifiedTechnician: yup.boolean().required(),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      dispatch(applyJob(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const { _t } = useTranslator();

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4 className="mb-5">
          {_t(
            isBusinessOwner !== "Mechanic"
              ? "Are your technicians certified?"
              : "Are you a certified technician?"
          )}
        </h4>
        <div className="flexbar">
          <label className="selectbox w-100 h-auto">
            <input
              type="radio"
              value={true}
              {...register("certifiedTechnician")}
            />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("Yes")}</p>
            </div>
          </label>
          <label className="selectbox w-100 h-auto">
            <input
              type="radio"
              value={false}
              {...register("certifiedTechnician")}
            />
            <span className="checkmark"></span>
            <div className="primebox">
              <p>{_t("No")}</p>
            </div>
          </label>
        </div>
        <p className="error">
          {" "}
          {errors.certifiedTechnician &&
            `${_t(errors.certifiedTechnician.message)}`}
        </p>
      </div>
      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Next")}
        </button>
      </div>
    </form>
  );
};

export default IsCertified;
