import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import useTranslator from "../../../hooks/useTranslator";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  StateList,
  haveValue,
  isBusinessOwner,
  multiSelectOptions,
} from "../../../utils/helpers";
import toast from "react-hot-toast";
import { useForm, useWatch } from "react-hook-form";
import { QuickFormConstant } from "../../../constants/quick-form";
import { quickRegister } from "../../../api-services";
import useStateAndCity from "../../../data-fetch/useStateAndCity";
const AddLeadForm = () => {
  const [selectedCountry, setSelectCountry] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [applicationData, setApplicationData] = useState({});
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const applicantSchema = Yup.object({
    name: Yup.string().required("first name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    lName: Yup.string(),
    mobileNo: Yup.string().required("Mobile number is required"),
    city: Yup.string(),
    state: Yup.string().required("State is required"),
    country: Yup.string(),
    certifiedTechnician: Yup.boolean().required(),
    numberOfTechnicians: Yup.number(),
    certificationText: Yup.string(),
    business_name: Yup.string().optional().nullable().default(""),
    servicesInterest: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("You can choose multiple"),
    vehicles: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("You can choose multiple"),
    providedServices: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("You can choose multiple"),
    experienceYears: Yup.string().required().nullable(),
    notes: Yup.string().nullable(),
    category: Yup.string().required(),
  });

  const {
    fetchCities,
    stateList: stateListMap,
    cityList,
    loading,
  } = useStateAndCity();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      category: "",
      specificVehicles: [],
      providedServices: [],
      certificationText: "",
      vehicles: [],
      numberOfTechnicians: 0,
      experienceYears: "",
      business_name: "",
      servicesInterest: [],
      certifiedTechnician: false,
    },
    resolver: yupResolver(applicantSchema),
    mode: "onSubmit",
  });

  const show = useWatch({ control });
  console.log("🚀 ~ AddLeadForm ~ show:", show);

  const isMechanic = show.category === "Mechanic";

  const valueUpdate = (array) => array.map((item) => item.name);
  const providedServicesSeledct = (selectedList) => {
    valueUpdate(selectedList);
    setValue("providedServices", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      providedServices: selectedList,
    }));
  };

  const providedServicesRemove = (selectedList) => {
    setValue("providedServices", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      providedServices: selectedList,
    }));
  };
  const interestedServicesSelect = (selectedList) => {
    setValue("servicesInterest", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      servicesInterest: selectedList,
    }));
  };

  const interestedServicesSelectRemove = (selectedList) => {
    setValue("servicesInterest", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      servicesInterest: selectedList,
    }));
  };

  const industryServicesSelect = (selectedList) => {
    setValue("vehicles", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      vehicles: selectedList,
    }));
  };

  const industryServicesSelectRemove = (selectedList) => {
    setValue("vehicles", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      vehicles: selectedList,
    }));
  };

  const onSubmit = (data) => {
    quickRegister(data)
      .then((applicationProfile) => {
        if (applicationProfile.status === "success") {
          toast.success(_t("Leads  create successfully"));
          reset();
          navigate("/leads");
        }
      })
      .catch((err) => {
        toast.error(_t(err?.message));
      });
  };

  const onError = (error) => console.log("err during form submission", error);

  const watchFieldBusinessOwner = watch("category", null);

  const isCertifiedTechnician = watch("certifiedTechnician");

  const handleState = (e) => {
    const value = e.target.value;
    console.log("🚀 ~ StepSeven ~ value:", value);
    const filterState = StateList.find((st) => st.iso === value);
    fetchCities(value);
    console.log("🚀 ~ handleState ~ filterState:", filterState);
    setValue("state", filterState?.name);
    setSelectedState(value);
  };

  return (
    <Form className="accountform" onSubmit={handleSubmit(onSubmit, onError)}>
      <Row>
        <Col lg={6}>
          {/* basic info */}
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="category">
              <Form.Label>{_t("User Category")}</Form.Label>
              <div className="filter">
                <select {...register("category")}>
                  <option value="">Choose User Category</option>
                  <option value="Shop Owner">Shop Owner</option>
                  <option value="Mechanic">Mechanic</option>
                </select>
              </div>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.category && `${errors.category.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="name">
              <Form.Label>{_t("First Name")}</Form.Label>
              <Form.Control type="text" {...register("name")} />
            </Form.Group>
            <p className="error"> {errors.name && `${errors.name.message}`}</p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="lName">
              <Form.Label>{_t("Last Name")}</Form.Label>
              <Form.Control type="text" {...register("lName")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.lName && `${errors.lName.message}`}
            </p>
          </Col>

          {isBusinessOwner(watchFieldBusinessOwner) && (
            <Col lg={12}>
              <Form.Group className="formninputbar" controlId="business_name">
                <Form.Label>{_t("Business Name")}</Form.Label>
                <Form.Control type="text" {...register("business_name")} />
              </Form.Group>
              <p className="error">
                {" "}
                {errors.business_name && `${errors.business_name.message}`}
              </p>
            </Col>
          )}

          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="email">
              <Form.Label>{_t("Email")}</Form.Label>
              <Form.Control type="email" {...register("email")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.email && `${errors.email.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="email">
              <Form.Label>{_t("Phone Number")}</Form.Label>
              <Form.Control type="text" {...register("mobileNo")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.mobileNo && `${errors.mobileNo.message}`}
            </p>
          </Col>
        </Col>
        <Col lg={6}>
          {/* important info */}

          <div className="multiselect-bar">
            <h5 className="formtitle">{_t("Services Applied.")}</h5>
            <Multiselect
              options={[
                {
                  name: QuickFormConstant?.servicesInterest.BREAKDOWN_SERVICES,
                },
                {
                  name: QuickFormConstant?.servicesInterest.IN_PERSON_SERVICES,
                },
                { name: QuickFormConstant?.servicesInterest.REMOTE_SERVICES },
              ]}
              selectedValues={applicationData?.servicesInterest}
              onSelect={(value) => interestedServicesSelect(value)}
              onRemove={(value) => interestedServicesSelectRemove(value)}
              displayValue="name"
            />
            <p className="error">
              {errors.servicesInterest && `${errors.servicesInterest.message}`}
            </p>
          </div>
          <div className="multiselect-bar mt-10">
            <h5 className="formtitle">{_t("Additional Services")}</h5>
            <Multiselect
              options={multiSelectOptions([
                QuickFormConstant.PROVIDED_SERVICES.TOW,
                QuickFormConstant.PROVIDED_SERVICES.WHEEL_TIRE,
              ])}
              selectedValues={applicationData?.providedServices}
              onSelect={(value) => providedServicesSeledct(value)}
              onRemove={(value) => providedServicesRemove(value)}
              displayValue="name"
            />
            <p className="error">
              {errors.providedServices && `${errors.providedServices.message}`}
            </p>
          </div>
          <div className="multiselect-bar">
            <h5 className="formtitle">{_t("Vehicles")}</h5>
            <Multiselect
              options={multiSelectOptions([
                QuickFormConstant.INDUSTRY_SERVE.AUTOMOTIVE,
                QuickFormConstant.INDUSTRY_SERVE.CLASSIC_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.COMMERCIAL_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.HEAVY_MACHINERY,
                QuickFormConstant.INDUSTRY_SERVE.MARINE_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.MOTORCYCLE,
                QuickFormConstant.INDUSTRY_SERVE.RECREATIONAL_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.SMALL_ENGINES,
                QuickFormConstant.SPECIFIC_VEHICLES.COMMERCIAL_BUSES,
                QuickFormConstant.SPECIFIC_VEHICLES.COMMERCIAL_TRUCKS,
                QuickFormConstant.SPECIFIC_VEHICLES.HEAVY_DUTY_MACHINERY,
              ])}
              selectedValues={applicationData?.vehicles}
              onSelect={(value) => industryServicesSelect(value)}
              onRemove={(value) => industryServicesSelectRemove(value)}
              displayValue="name"
            />
            <p className="error">
              {errors.vehicles && `${errors.vehicles.message}`}
            </p>
          </div>
        </Col>

        <Col lg={6}>
          <Form.Group className="formninputbar" controlId="state">
            <Form.Label>State</Form.Label>

            <Form.Select
              aria-label="Default select example"
              onChange={handleState}
              value={selectedState}
              className="form-control arrow-dropdown">
              <option>State</option>
              {stateListMap?.map((item) => (
                <option value={item.isoCode} key={item.isoCode}>
                  {item.name}
                </option>
              ))}
            </Form.Select>

            <p className="error">
              {" "}
              {errors.state && `${errors.state.message}`}
            </p>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="formninputbar" controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setSelectedCity(e.target.value);
                setValue("city", e.target.value);
              }}
              value={selectedCity}
              className="form-control arrow-dropdown">
              <option>City</option>
              {cityList?.map((item) => (
                <option value={item.name} key={item.name}>
                  {item.name}
                </option>
              ))}
            </Form.Select>

            <p className="error"> {errors.city && `${errors.city.message}`}</p>
          </Form.Group>
        </Col>

        <Row>
          <Col lg={6}>
            <Form.Group
              className="formninputbar"
              controlId="certifiedTechnician">
              <Form.Label>
                {isMechanic ? "Certified" : "Certified Technicians"}
              </Form.Label>
              <div className="filter">
                <select {...register("certifiedTechnician")}>
                  <option value="">{_t("Choose Option")}</option>
                  <option value={true}>{_t("Yes")}</option>
                  <option value={false}>{_t("No")}</option>
                </select>
              </div>
            </Form.Group>
          </Col>

          {!isMechanic && (
            <Col lg={6}>
              <Form.Group
                className="formninputbar"
                controlId="numberOfTechnicians">
                <Form.Label>{_t("Number of Technicians")}</Form.Label>
                <Form.Control
                  type="number"
                  {...register("numberOfTechnicians")}
                />
              </Form.Group>
              <p className="error">
                {" "}
                {errors.numberOfTechnicians &&
                  `${errors.numberOfTechnicians.message}`}
              </p>
            </Col>
          )}
        </Row>

        {Boolean(isCertifiedTechnician) && isMechanic && (
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="certificationText">
              <Form.Label>{_t("Certification Info")}</Form.Label>
              <Form.Control type="text" {...register("certificationText")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.certificationText &&
                `${errors.certificationText.message}`}
            </p>
          </Col>
        )}

        <Col lg={12}>
          <Form.Group className="formninputbar" controlId="experienceYears">
            <Form.Label>{_t("Experience Years")}</Form.Label>
            <div className="filter">
              <select {...register("experienceYears")}>
                {/* <option value="">All</option> */}
                <option value="">Choose Experience</option>
                <option value={QuickFormConstant.USER_EXPERIENCES.UNDER_5}>
                  {QuickFormConstant.USER_EXPERIENCES.UNDER_5}
                </option>

                <option value={QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS}>
                  {QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS}
                </option>

                <option value={QuickFormConstant.USER_EXPERIENCES.TEN_PLUS}>
                  {QuickFormConstant.USER_EXPERIENCES.TEN_PLUS}
                </option>

                <option value={QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS}>
                  {QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS}
                </option>
              </select>
            </div>
          </Form.Group>
          <p className="error">
            {" "}
            {errors.experienceYears && `${errors.experienceYears.message}`}
          </p>
        </Col>

        <div className="dflex-inputbar form-100">
          <Form.Label>{_t("Applicant Notes  ")}</Form.Label>
          <Form.Group className="formninputbar" controlId="jobDescription">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              {...register("notes")}
            />
            <p className="error">
              {" "}
              {errors?.notes && `${errors?.notes.message}`}
            </p>
          </Form.Group>
        </div>
        <Col lg={12}>
          <Form.Group
            className="formninputbar inputbar-btnpink"
            controlId="btnsave">
            {isSubmitting ? (
              <Button className="btn btnorange">Submitting...</Button>
            ) : (
              <Button className="btn btnorange" type="submit">
                Add Lead
              </Button>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default AddLeadForm;
