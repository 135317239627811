import React, { useEffect, useState } from "react";
import BackButton from "../../../Components/BackToPrev";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  getAdminSetting,
  updateAdminSetting,
} from "../../../api-services/dashboard";
import { useForm } from "react-hook-form";
import { haveValue } from "../../../utils/helpers";
import toast from "react-hot-toast";

const JobSetting = ({ fetchData, accountSettingData }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    const final = parseInt(data.blogInterval) * parseInt(data.articlePerDay);

    if (final > 24) {
      toast.error(
        "Either decrease the no of article per or blog interval time"
      );
      return;
    }

    updateAdminSetting(data)
      .then((resUpdate) => {
        if (resUpdate.status === "success") {
          toast.success("Update setting successfully");
          fetchData();
        }
      })
      .catch((err) => console.log("error while update", err));
  };

  const onError = (error) => console.log("error in form ", error);

  useEffect(() => {
    if (haveValue(accountSettingData))
      reset({
        blogInterval: accountSettingData.blogInterval,
        blogKeywords: accountSettingData.blogKeywords,
        articleLength: accountSettingData.articleLength,
        articlePerDay: accountSettingData?.articlePerDay,
        coverImagePrompt: accountSettingData?.coverImagePrompt,
        jobRepublishInterval: accountSettingData?.jobRepublishInterval,
        jobExpireInterval: accountSettingData?.jobExpireInterval,
      });
  }, [accountSettingData]);

  return (
    <div className="account-settings mt-4">
      <Form className="accountform" onSubmit={handleSubmit(onSubmit, onError)}>
        <Row>
          <Col lg={6}>
            <Form.Group className="formninputbar" controlId="articlePerDay">
              <Form.Label>Job Expire Interval day's </Form.Label>
              <Form.Control
                type="number"
                {...register("jobExpireInterval")}
                placeholder="Enter interval in days ex: 12, 24, 6"
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="formninputbar" controlId="firstName">
              <Form.Label>Job Republish Interval (In Day's) </Form.Label>
              <Form.Control
                type="text"
                {...register("jobRepublishInterval")}
                placeholder="Enter interval in days ex: 12, 24, 6"
              />
            </Form.Group>
          </Col>

          <Col lg={12}>
            <Form.Group
              className="formninputbar inputbar-btnpink"
              controlId="btnsave">
              <Button className="btn btnorange" type="submit">
                Save Changes
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default JobSetting;
