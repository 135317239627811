import React, { useState } from "react";
import { QuickFormConstant } from "../../../constants/quick-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const INDUSTRY_SERVE_CONSTANT = QuickFormConstant.INDUSTRY_SERVE;

const StepThree = ({ setCurrentStep, totalCase }) => {
  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formSlice.data);

  const schema = yup.object().shape({
    vehicles: yup
      .array()
      .min(1)
      .of(yup.string().required())
      .required("You can choose multiple"),
  });

  useScrollTopForm(`fromapply`);


  const {
    handleSubmit,
    register,
    watch,
    setValue,

    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      dispatch(saveData(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);
  const { _t } = useTranslator();

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4>{_t("What vehicles or equipment do you service?")}</h4>
        <h6 className="mb-4 text-center">( Select all that apply )</h6>
        <div className="flexbar">
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={INDUSTRY_SERVE_CONSTANT.AUTOMOTIVE}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/sedan.svg" alt="icon" />
              <h5>{_t("Automotive")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={INDUSTRY_SERVE_CONSTANT.CLASSIC_VEHICLES}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/hatchback.svg" alt="icon" />
              <h5>{_t("Classic Cars")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={INDUSTRY_SERVE_CONSTANT.MOTORCYCLE}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/sportbike.svg" alt="icon" />
              <h5>{_t("Motorcycles")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              value={INDUSTRY_SERVE_CONSTANT.COMMERCIAL_VEHICLES}
              name="vehicles"
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/truck.svg" alt="icon" />
              <h5>{_t("Commercial Vehicles")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={INDUSTRY_SERVE_CONSTANT.RECREATIONAL_VEHICLES}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/minitruck.svg" alt="icon" />
              <h5>{_t("RV’s")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={INDUSTRY_SERVE_CONSTANT.MARINE_VEHICLES}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/boat.svg" alt="icon" />
              <h5>{_t("Marine Vehicles")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={INDUSTRY_SERVE_CONSTANT.SMALL_ENGINES}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/small-engine.svg" alt="icon" />
              <h5>{_t("Small Engines")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              value={INDUSTRY_SERVE_CONSTANT.HEAVY_MACHINERY}
              name="vehicles"
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/jcb.svg" alt="icon" />
              <h5>{_t("Heavy Machinery")}</h5>
            </div>
          </label>
        </div>

        <p className="error">
          {" "}
          {errors.vehicles && `${_t(errors.vehicles.message)}`}
        </p>
      </div>
      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Next")}
        </button>
      </div>
    </form>
  );
};

export default StepThree;
