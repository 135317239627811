import BackButton from "../../../../Components/BackToPrev";
import AddApplicationForm from "../../../../Components/Forms/ApplicationForm/AddApplicationForm";
const ApplicantProfileCreate = () => {
  return (
    <div className="users-page">
      <div className="top-header mb-5">
        <div className="page-name title-with-backbtn mb-0">
          <BackButton />
          <h4>Add Applicant </h4>
        </div>
      </div>

      <AddApplicationForm />
    </div>
  );
};

export default ApplicantProfileCreate;
