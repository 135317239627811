import React from "react";
import { Helmet } from "react-helmet";
import { formattedDate } from "../utils/helpers";

const JobPostingSchema = ({ jobInfo }) => {
  let {
    jobTitle,
    summary,
    // companyName,
    // companyWebsite,
    createdAt,
    expiredAt: validThrough,
    rate,
    city,
    state,
    jobType,
    country,
    skills,
    employmentType,
  } = jobInfo;

  

  const jobPostingSchema = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    title: jobTitle,
    description: summary,
    identifier: {
      "@type": "PropertyValue",
      name: "Remote Mechanic Jobs",
      value: "Remote Mechanic Jobs",
    },
    datePosted: createdAt && formattedDate(createdAt),
    validThrough: validThrough && formattedDate(validThrough),
    employmentType: employmentType,
    hiringOrganization: {
      "@type": "Organization",
      name: "Remote Mechanic Jobs",
      sameAs: "https://www.remotemechanicjobs.com/",
    },
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: city,
        addressRegion: state,
        addressCountry: "US",
      },
    },
    baseSalary: {
      "@type": "MonetaryAmount",
      currency: "USD",
      value: {
        "@type": "QuantitativeValue",
        value: rate.rateValue,
        // maxValue: 80000,
        unitText: rate.rateTimeLine.toUpperCase(),
      },
    },
    skills: skills,
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jobPostingSchema)}
      </script>
    </Helmet>
  );
};

export default JobPostingSchema;
