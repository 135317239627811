import React from "react";
import useTranslator from "../../../hooks/useTranslator";
import WhyAppluSliderasBusiness from "../WhyApplySlider/WhyAppluSliderasBusiness";
import { Link } from "react-router-dom";

const WhyApplyasbusiness = () => {
  const { _t } = useTranslator();
  return (
    <div className="whyapply">
      <div className="container">
        <div className="primary-title">
          <h4>{_t("Why apply?")}</h4>
          <h2>
            {_t("Benefits for Shop Owners")}
          </h2>
          <div className="bar-line"></div>
        </div>
        <WhyAppluSliderasBusiness />
        <div className="btngroup">
          <Link to="/#formapply">
            <button className="btn btnorange">{_t("Apply now")}</button>
          </Link>
          <Link to="/about/#user-benefit">
            <button className="btn btnwhite">{_t("See Details")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhyApplyasbusiness;
