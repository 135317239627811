import React from "react";
import { QuickFormConstant } from "../../../constants/quick-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob } from "../../../Redux/Slices/jobsSlice";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const SPECIFIC_VEHICLES_CONSTANT = QuickFormConstant.SPECIFIC_VEHICLES;

const IsHeavy = ({ setCurrentStep, totalCase }) => {
  const { _t } = useTranslator();

  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);
  useScrollTopForm(`jobapply`);

  const schema = yup
    .object()
    .shape({
      vehicles: yup.array().min(1).of(yup.string().required()).required(),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      data.vehicles = Array.from(
        new Set([...formData.vehicles, ...data.vehicles])
      );
      dispatch(applyJob(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4>
          {_t(
            "Let’s be a little more specific, which vehicle types do you service?"
          )}
        </h4>
        <h6 className="mb-4 text-center">( Select all that apply )</h6>
        <div className="flexbar">
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={SPECIFIC_VEHICLES_CONSTANT.HEAVY_DUTY_MACHINERY}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/jcb.svg" alt="icon" />
              <h5>{_t("Heavy Duty Machinery")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={SPECIFIC_VEHICLES_CONSTANT.COMMERCIAL_TRUCKS}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/truck.svg" alt="icon" />
              <h5>{_t("Commercial Trucks")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              name="vehicles"
              value={SPECIFIC_VEHICLES_CONSTANT.COMMERCIAL_BUSES}
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/bus.svg" alt="icon" />
              <h5>{_t("Commercial Buses")}</h5>
            </div>
          </label>
          <label className="selectbox">
            <input
              type="checkbox"
              value={SPECIFIC_VEHICLES_CONSTANT.RECREATIONAL}
              name="vehicles"
              {...register("vehicles")}
            />
            <div className="primebox">
              <img src="/images/minitruck.svg" alt="icon" />
              <h5> {_t("RV’s")}</h5>
            </div>
          </label>
        </div>
        <p>
          {errors.vehicles && `${_t(errors.vehicles.message)}`}
        </p>
      </div>
      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Next")}
        </button>
      </div>
    </form>
  );
};

export default IsHeavy;
