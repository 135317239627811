import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useTranslator from "../../../hooks/useTranslator";
/*** forms Components  */
import { QuickFormConstant } from "../../../constants/quick-form";

import ShopOwnerFlow from "./ShopOwnerFlow";
import MechanicalForm from "./MechanicFlow";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const JobApplyForm = ({ jobInfo }) => {
  const formData = useSelector((state) => state.jobsSlice.applyJobData);

  const userRole = jobInfo?.category;

  const { _t } = useTranslator();

  const [currentStep, setCurrentStep] = useState(1);

  const totalCase = userRole === "Shop Owner" ? 11 : 10;

  const isHeavyVehicleSelected = () => {
    const selectedIndustries = formData.vehicles;
    if (
      selectedIndustries.includes(
        QuickFormConstant.INDUSTRY_SERVE.HEAVY_MACHINERY
      ) ||
      selectedIndustries.includes(
        QuickFormConstant.INDUSTRY_SERVE.COMMERCIAL_VEHICLES
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCertified = Boolean(formData.certifiedTechnician);
  const isNumberOfTechnicians = formData.isNumberOfTechnicians;

  const renderStepContent = () => {
    if (userRole === "Shop Owner") {
      return (
        <ShopOwnerFlow
          setCurrentStep={setCurrentStep}
          totalCase={totalCase}
          currentStep={currentStep}
          isHeavyVehicleSelected={isHeavyVehicleSelected}
          isCertified={isCertified}
          isNumberOfTechnicians={isNumberOfTechnicians}
          jobInfo={jobInfo}
        />
      );
    } else {
      return (
        <MechanicalForm
          setCurrentStep={setCurrentStep}
          totalCase={totalCase}
          currentStep={currentStep}
          isHeavyVehicleSelected={isHeavyVehicleSelected}
          isCertified={isCertified}
          isNumberOfTechnicians={isNumberOfTechnicians}
          jobInfo={jobInfo}
        />
      );
    }
  };
  const parentDivClassName = `step-form-container step-${currentStep}`;

  useEffect(() => {
    const dynemicWidth = (100 / totalCase) * currentStep + "%";
    const lineElement = document.querySelector(".lineinner");
    lineElement.style.cssText = `width: ${dynemicWidth}`;
    const parentElement = lineElement.parentElement;
    parentElement.appendChild(lineElement);
  }, [currentStep, totalCase]);

  const hash = window.location.hash;

  useScrollTopForm(`jobapply`);

  return (
    <div className={parentDivClassName}>
      {/* <h1>Step {currentStep}</h1> */}
      <div className="container">
        <div className="block-bar" id="jobapply">
          <div className="line">
            <div className="lineinner"></div>
          </div>
          {renderStepContent()}
        </div>
      </div>
    </div>
  );
};

export default JobApplyForm;
