import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { applyJob } from "../../../Redux/Slices/jobsSlice";
import { StateList, haveValue } from "../../../utils/helpers";
import useStateAndCity from "../../../data-fetch/useStateAndCity";
import { useNavigate, useParams } from "react-router-dom";

const BasicInfo = ({ setCurrentStep, totalCase, jobInfo, currentStep }) => {
  const dispatch = useDispatch();
  const { _t } = useTranslator();
  const formData = useSelector((state) => state.jobsSlice.applyJobData);
  const userRole = jobInfo?.category;

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const navigate = useNavigate();

  const params = useParams();
  console.log("🚀 ~ BasicInfo ~ params:", params);
  const handleNext = () => {
    setCurrentStep((prevStep) =>
      prevStep < totalCase ? prevStep + 1 : prevStep
    );
    navigate(`/alljobs/${params.id}#jobapply`);
  };

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      lName: yup.string().required(),
      business_name: yup.string(),
      mobileNo: yup.string().required(),
      email: yup.string().email().required(),
      state: yup.string().required(),
      city: yup.string().notRequired(),
    })
    .required();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      const userCategory = localStorage.getItem("usrRole");

      data = { ...data, category: jobInfo.category };

      dispatch(applyJob(data));
      handleNext();
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const {
    fetchCities,
    stateList: stateListMap,
    cityList,
    loading,
  } = useStateAndCity();

  const handleState = (e) => {
    const value = e.target.value;
    console.log("🚀 ~ StepSeven ~ value:", value);
    const filterState = StateList.find((st) => st.iso === value);
    fetchCities(value);
    console.log("🚀 ~ handleState ~ filterState:", filterState);
    setValue("state", filterState?.name);
    setSelectedState(value);
  };

  console.log("selectedState", selectedState);

  return (
    <>
      <div className="formsteps">
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <h4>{_t("Please fill in your information below")}</h4>
          <div className="formrow">
            <div className="dflex-inputbar form-50">
              <Form.Group className="forminput" controlId="Name">
                <Form.Control
                  type="text"
                  placeholder="name"
                  autoComplete="given-name"
                  {...register("name")}
                />
                <p className="error">
                  {" "}
                  {errors.name && `${_t(errors.name.message)}`}
                </p>
              </Form.Group>
            </div>
            <div className="dflex-inputbar form-50">
              <Form.Group className="forminput" controlId="lName">
                <Form.Control
                  type="text"
                  placeholder="last name"
                  autoComplete="family-name"
                  {...register("lName")}
                />
                <p className="error">
                  {" "}
                  {errors.lName && `${_t(errors.lName.message)}`}
                </p>
              </Form.Group>
            </div>
            <div className="dflex-inputbar form-50">
              <Form.Group className="forminput" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                />
                <p className="error">
                  {" "}
                  {errors.email && `${_t(errors.email.message)}`}
                </p>
              </Form.Group>
            </div>
            <div className="dflex-inputbar form-50">
              <Form.Group className="forminput" controlId="mobileNo">
                <Form.Control
                  type="text"
                  placeholder="Phone"
                  {...register("mobileNo")}
                />
                <p className="error">
                  {" "}
                  {errors.mobileNo && `${_t("errors.mobileNo.message")}`}
                </p>
              </Form.Group>
            </div>

            {userRole !== "Mechanic" ? (
              <div className="dflex-inputbar form-100">
                <Form.Group className="forminput" controlId="business_name">
                  <Form.Control
                    type="text"
                    placeholder="Business name"
                    {...register("business_name")}
                  />
                  <p className="error">
                    {" "}
                    {errors.business_name &&
                      `${_t("errors.business_name.message")}`}
                  </p>
                </Form.Group>
              </div>
            ) : null}

            <div className="dflex-inputbar form-50">
              <Form.Group className="forminput" controlId="state">
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleState}
                  value={selectedState}
                  className="form-control arrow-dropdown">
                  <option>State</option>
                  {stateListMap?.map((item) => (
                    <option value={item.isoCode} key={item.isoCode}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>

                <p className="error">
                  {" "}
                  {errors.state && `${errors.state.message}`}
                </p>
              </Form.Group>
            </div>

            <div className="dflex-inputbar form-50">
              <Form.Group className="forminput" controlId="city">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setSelectedCity(e.target.value);
                    setValue("city", e.target.value);
                  }}
                  value={selectedCity}
                  className="form-control arrow-dropdown">
                  <option>City</option>
                  {cityList?.map((item) => (
                    <option value={item.name} key={item.name}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>

                <p className="error">
                  {" "}
                  {errors.city && `${errors.city.message}`}
                </p>
              </Form.Group>
            </div>

            <div className="dflex-inputbar form-100">
              <div className="btnbar twobtncenter">
                <button className="btnorange btn" type="submit">
                  {_t("Next")}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BasicInfo;
