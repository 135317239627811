import React from "react";
import useTranslator from "../../../hooks/useTranslator";

const BlogBanner = () => {
  const { _t } = useTranslator();
  return (
    <>
      <section className="contact-banner BlogBanner">
        <div className="container">
          <div className="blog-head">
            <h2>{_t("Explore Our Latest Publications and News")}</h2>
            {/* <p>Lorem ipsum dolor sit amet consectetur. Aliquam enim nec a pharetra at ac nascetur lacus Fusce orci odio sit quis. Mauris purus leo sapien</p> */}
          </div>
          
          
        </div>
      </section>
    </>
  );
};

export default BlogBanner;
