import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getAdminLeads = async (data) => {
  const response = await sendRequest(Routes.leads.adminLeads, "GET", data);
  return response;
};
export const covertLeads = async (data) => {
  const response = await sendRequest(Routes.leads.covertLeads, "PUT", data);
  return response;
};
