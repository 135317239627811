import { useEffect, useState } from "react";
import { selectAllCategory } from "../api-services/job-category";
import { getJobListing } from "../api-services/jobs";

export function useJobList() {
  const [jobSelectList, setJobSelectList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    getJobListing()
      .then((jobCategoryData) => {
        if (jobCategoryData.status === "success") {
          const ModifyData = jobCategoryData?.data?.map((data) => ({
            name: data.jobTitle,
            id: data._id,
            category:data.category
          }));
          setJobSelectList(ModifyData);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  return {
    jobSelectList,
    error,
  };
}
