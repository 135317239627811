import React from 'react';
import BackButton from '../../../Components/BackToPrev'
import { Form } from 'react-bootstrap';
import { Tabs } from 'antd';
import Payment from './Payment';
import Accountsetting from './Accountsetting';


const AdminAccount = (props) => {

    return (
        <>
            <div className='admin-account'>
                <div className="title-with-backbtn">
                    <BackButton />
                    <h4>Account Setting</h4>
                </div>
                <div className='form_setting'>
            <Accountsetting />
                </div>

            </div>
        </>
    );
}

export default AdminAccount;