import React, { useEffect } from "react";
import AboutBanner from "../../Components/PagesAssets/AboutUs/AboutBanner";
import AboutContent from "../../Components/PagesAssets/AboutUs/AboutContent";
import WhyApply from "../../Components/PagesAssets/AboutUs/WhyApply";
import BenefitshopOwner from "../../Components/PagesAssets/AboutUs/BenefitshopOwner";
import AboutListing from "../../Components/PagesAssets/AboutUs/AboutListing";
import BenefitsFreelancer from "../../Components/PagesAssets/AboutUs/BenefitsFreelancer";
import ContactUs from "../../Components/PagesAssets/AboutUs/ContactUs";
import AboutListingV2 from "../../Components/PagesAssets/AboutUs/AboutListingV2";
import Letstalk from "../../Components/PagesAssets/Home/Letstalk";
import { useSelector } from "react-redux";
import UserRole from "../../Components/Modals/UserRole";
import { Helmet } from "react-helmet";

const About = () => {
  const userRole = useSelector((state) => state.authSlice.usrRole);
  const hash = window.location.hash;

  useEffect(() => {
    if (hash === "#user-benefit") {
      const formApplyElement = document.getElementById("user-benefit");
      formApplyElement.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo(0, 0);
    }
  }, [hash]);

  return (
    <>
      <Helmet>
        <title>About - Remote Mechanic Job</title>
        {/* Set the canonical URL */}
        <link
          rel="canonical"
          href={`https://www.remotemechanicjobs.com/about`}
        />
        {/* Set the meta description */}
        <meta
          name="description"
          content="Welcome to a space where supplementary income and cutting-edge technology converge to redefine success. Here, the vision is rooted in empowering individuals and propelling businesses towards financial freedom. Success is not just a destination; it's a collaborative journey, marked by innovative solutions that reshape conventional notions of prosperity."
        />
         <link
          rel="alternate"
          hreflang="en-us"
          href="https://www.remotemechanicjobs.com/en-us/about"
        />
        <link
          rel="alternate"
          hreflang="es-es"
          href="https://www.remotemechanicjobs.com/es-es/about"
        />
      </Helmet>
      {!userRole && <UserRole />}

      <div className="Aboutbar">
        <AboutBanner />
        <AboutContent />
        <WhyApply />
        <div id="user-benefit">
          {userRole === "business_owner" ? (
            <>
              <BenefitshopOwner />
              <AboutListingV2 />
            </>
          ) : (
            <>
              <BenefitsFreelancer />
              <AboutListing />
            </>
          )}
        </div>
        {/* 
        <Letstalk /> */}
        <ContactUs />
      </div>
    </>
  );
};

export default About;
