const KEY_PREFIX = "remote-job-";
function getKeyName(key) {
  return KEY_PREFIX + key;
}
export function setLocalStorageItem(key, val) {
  localStorage.setItem(getKeyName(key), val);
}
export function getLocalStorageItem(key, defaultValue = null) {
  let val = localStorage.getItem(getKeyName(key));

  if (
    val === undefined ||
    val === "undefined" ||
    val === "" ||
    val === "null"
  ) {
    val = null;
  }
  if (val === null) {
    return defaultValue;
  }
  return val;
}
export function removeLocalStorageItem(key) {
  localStorage.removeItem(getKeyName(key));
}
