import React from "react";
import Form from "react-bootstrap/Form";
import useTranslator from "../../hooks/useTranslator";

const LocationFilter = ({ locations, setLocation, type }) => {
  
  const { _t } = useTranslator();
  return (
    <>
      <Form.Select
        aria-label="Locationexample"
        className="filter-dropdown"
        onChange={(e) => {
          setLocation(e.target.value);
        }}>
        <option value="">{_t(type === "state" ? "State" : "City")}</option>
        {locations?.map((loc) => (
          <option key={loc} value={loc}>
            {loc}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default LocationFilter;
