import React, { useState, useEffect } from "react";
import BackButton from "../../../Components/BackToPrev";
import { AiFillPlusCircle, AiOutlineSearch } from "react-icons/ai";
import { InputGroup, Form, Table } from "react-bootstrap";
import { LuSettings2 } from "react-icons/lu";
import Paginate from "../../../Components/Paginate";
import { covertLeads, getAdminLeads } from "../../../api-services/leads";
import debounce from "lodash/debounce";
import { LEADCATEGORY } from "../../../constants/leads";
import Loader from "../../../Components/Loader";
import { Link, useNavigate } from "react-router-dom";
import {
  MdCached,
  MdLocationPin,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";
import CovertLead from "../../../Components/Modals/CovertLead";
import { requestHandler } from "../../../utils/comman";

const Leads = () => {
  const [leadsList, setLeadList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectJob, setSelectedJob] = useState(null);
  const [leadId, setLeadId] = useState(null);
  const [selectJobCategory, setJobCategory] = useState(null);

  const navigate = useNavigate();

  const debounceList = debounce((value) => {
    setLoading(true);
    getAdminLeads({ page, limit, searchText: value, category })
      .then((data) => {
        if (data.status === "success") {
          setLoading(false);
          setLeadList(data.data.docs);
          setTotalDocs(data.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("error while fetch jobs admin", error);
      })
      .finally(() => setLoading(false));
  }, 600);

  useEffect(() => {
    debounceList(searchText);
  }, [page, limit, searchText, category]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const pageHandler = (page) => {
    setPage(page);
  };

  const handleModel = (lead) => {
    setShowModel(true);
    setLeadId(lead._id);
    setJobCategory(lead.category);
  };
  const covertHandler = (value) => {
    requestHandler(
      async () => await covertLeads({ jobId: selectJob, leadId }),
      null,
      (resData) => {
        console.log(resData, "res Data");
        setShowModel(false);
        setSelectedJob("");
        debounceList(searchText);
        navigate(`/applicant/${leadId}`);
      },
      null
    );
  };

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Leads</h4>
          </div>
          <div className="import-export">
            <Link to="/leads/add" className="btn btnorange">
              <AiFillPlusCircle /> Add Lead
            </Link>
            {/* <button>
              <AiOutlineUpload /> Export
            </button> */}
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Lead"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
            </InputGroup>
          </div>
          <div className="filter">
            <LuSettings2 size={30} />
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}>
              <option value="">All</option>
              {LEADCATEGORY.map((category) => (
                <option value={category} key={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* --------------table-------------- */}

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>location</th>
                    <th>phone</th>
                    <th>Email</th>
                    <th>Category</th>
                    <th>Experience Years </th>
                    <th>Services Applied</th>
                    {/* <th>Industry Served</th> */}
                    <th>Vehicles</th>
                    <th>Additional Services</th>
                    <th>Action </th>
                    <th>Communications </th>
                  </tr>
                </thead>
                <tbody>
                  {leadsList?.map((lead, index) => (
                    <tr key={index}>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{lead.name}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="user-details">
                          <div>
                            <p className="user-name">{lead.lName}</p>
                          </div>
                        </div>
                      </td>

                      <td className="red-text">
                        {" "}
                        <MdLocationPin /> {lead?.city ? `${lead?.city},` : null}
                        {lead?.state}
                      </td>
                      <td className="red-text">
                        {lead?.mobileNo ? lead?.mobileNo : "N/A"}
                      </td>
                      <td className="red-text">{lead?.email}</td>
                      <td className="red-text">{lead?.category}</td>
                      <td>
                        {lead?.experienceYears ? lead?.experienceYears : "N/A"}
                      </td>
                      <td className="red-text">
                        {lead?.servicesInterest.length > 0
                          ? lead?.servicesInterest.join(",")
                          : "N/A"}
                      </td>
                      {/* <td className="red-text">
                        {lead?.industryServe.join(",")}
                      </td> */}
                      <td className="red-text">
                        {lead?.vehicles.length > 0
                          ? lead?.vehicles.join(",")
                          : "N/A"}
                      </td>
                      <td className="red-text">
                        {lead?.providedServices?.length
                          ? lead?.providedServices.join(",")
                          : "N/A"}
                      </td>
                      <td
                        className="red-text cursor-pointer"
                        onClick={() => handleModel(lead)}>
                        <button>
                          <MdCached className="ml-2" />
                          Convert Application
                        </button>
                      </td>

                      <td className="red-text">
                        <Link
                          to={`/applicant/mails/${lead?.email}?name=${lead?.name}`}>
                          <MdOutlineMarkEmailUnread className="ml-2" />{" "}
                          Communications
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate total={totalDocs} onChange={pageHandler} page={page} />
            </div>
            <CovertLead
              show={showModel}
              setShowModel={setShowModel}
              setSelectedJob={setSelectedJob}
              onSubmit={covertHandler}
              selectJobCategory={selectJobCategory}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Leads;
