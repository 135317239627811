import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Slices/authSlice";
import formSlice from "./Slices/formSlice";
import jobsSlice from "./Slices/jobsSlice";

const store = configureStore({
  reducer: {
    authSlice,
    formSlice,
    jobsSlice
  },
});

export default store;
