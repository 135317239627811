import React from 'react';
import useTranslator from "../../../hooks/useTranslator";
const AboutListingV2 = () => {
    const { _t } = useTranslator();
    return (
        <>
            <section className='about-listing'>
                <div className='container'>
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/profithand.svg" alt='icon'/>
                            <h6>{_t("Revolutionize Customer Connections: Expand Your Reach with Remote Support Solutions")}</h6>
                        </div>
                        <p className='content-p'>{_t("Introduce a lucrative new revenue stream by effortlessly connecting with thousands of potential customers through innovative remote support solutions.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Instant Nationwide Access: Effortlessly connect with potential customers nationwide, breaking geographical barriers and unlocking new market segments.")}</li>
                            <li>{_t("Seamless Customer Interaction: Provide real-time assistance and support, enhancing customer satisfaction and loyalty through innovative remote support solutions.")}</li>
                            <li>{_t("24/7 Availability: Maximize customer engagement by offering round-the-clock support, ensuring your business is accessible to clients in different time zones.")}</li>
                            <li>{_t("Enhanced Efficiency: Streamline operations with remote support tools, reducing response times and resolving issues faster, leading to increased customer trust.")}</li>
                            <li>{_t("Data-Driven Insights: Utilize analytics from remote support interactions to understand customer behavior and preferences, refining your marketing strategies.")}</li>
                        </ul>
                    </div> 
                    <div className='listingbar'>
                        <div className='topbar-title'>
                            <img src="/images/Revolutionize.svg" alt='icon'/>
                            <h6>{_t("Virtualize and Thrive: Elevate Services and Optimize Space Allocation")}</h6>
                        </div>
                        <p className='content-p'>{_t("Transform your business dynamics by transitioning low-income services to a virtual environment opportunities.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Liberated Service Bay Space: Transition low-income services to a virtual environment, freeing up physical space for higher-profit activities and expanding your service portfolio.")}</li>
                            <li>{_t("Cost-Efficient Operations: Save on overhead costs associated with physical service bays while maintaining or even improving service quality through virtualization.")}</li>
                            <li>{_t("Scalable Opportunities: Easily adapt to changing demands by virtualizing services, allowing your business to scale up or down based on market trends.")}</li>
                            <li>{_t("Increased Flexibility: Provide services from anywhere, offering convenience to both your business and customers while adapting to the evolving nature of work.")}</li>
                            <li>{_t("Diversified Revenue Streams: Explore new markets and revenue opportunities by virtualizing services and tapping into previously untapped customer segments.")}</li>
                        </ul>
                    </div> 
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/skills.webp" alt='icon'/>
                            <h6>{_t("Revenue Resilience: Sustain Business Income During Slow Periods")}</h6>
                        </div>
                        <p className='content-p'>{_t("Ensure continuous revenue flow and keep your team gainfully employed.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Off-Peak Productivity Boost: Keep your team engaged and maintain productivity during slow times, ensuring a steady income stream throughout the year.")}</li>
                            <li>{_t("Flexible Work Arrangements: Implement remote work strategies to adapt to varying workloads, promoting a healthy work-life balance for your team.")}</li>
                            <li>{_t("Continuous Innovation: Use slower periods as an opportunity to innovate and develop new products or services, staying ahead of competitors.")}</li>
                            <li>{_t("Client Retention: Offer special promotions or loyalty programs during slow times to retain existing clients and attract new ones.")}</li>
                            <li>{_t("Strategic Marketing Campaigns: Execute targeted marketing campaigns during slow periods to generate interest and boost sales.")}</li>
                        </ul>
                    </div> 
                    <div className='listingbar  '>
                        <div className='topbar-title'>
                            <img src="/images/Modernize.svg" alt='icon'/>
                            <h6>{_t("Modernize without the Cost: Elevate Client Experiences with Zero Startup Investment")}</h6>
                        </div>
                        <p className='content-p'>{_t("Upgrade your service offerings for existing clients without breaking the bank – embrace new and modern solutions with no upfront expenses.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Seamless Integration: Upgrade services without disruption, seamlessly integrating modern solutions with existing operations and workflows.")}</li>
                            <li>{_t("Risk-Free Investment: Embrace cutting-edge technology with zero upfront expenses, allowing your business to modernize without financial constraints.")}</li>
                            <li>{_t("Immediate Implementation: Start enjoying the benefits of modern solutions right away, without the delays associated with traditional implementation processes.")}</li>
                            <li>{_t("Customizable Solutions: Tailor modern offerings to meet the unique needs of your clients, enhancing their overall experience with your business.")}</li>
                            <li>{_t("Competitive Advantage: Gain a competitive edge by offering advanced services without the burden of startup costs, positioning your business as a local leader in the industry.")}</li>
                        </ul>
                    </div> 
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/skills.webp" alt='icon'/>
                            <h6>{_t("Finance Tracking Suite: Effortless Income Tracking with Integrated Finance Tools")}</h6>
                        </div>
                        <p className='content-p'>{_t("Take control of your virtual earnings effortlessly with a comprehensive suite of tools that seamlessly integrate with your business systems, ensuring easy income tracking and financial management.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Real-Time Insights: Access up-to-date financial data, enabling quick decision-making and strategic planning for business growth.")}</li>
                            <li>{_t("Automated Processes: Save time and reduce manual errors with automated finance tracking processes, ensuring accuracy in income reporting.")}</li>
                            <li>{_t("Tax Compliance: Stay organized and compliant with tax regulations by utilizing finance tools that facilitate accurate record-keeping and reporting.")}</li>
                            <li>{_t("Scalable Solutions: Grow your business with confidence, knowing that the finance tracking suite can seamlessly scale alongside your expanding operations.")}</li>
                        </ul>
                    </div> 
                    <div className='listingbar  '>
                        <div className='topbar-title'>
                            <img src="/images/Marketing.svg" alt='icon'/>
                            <h6>{_t("Precision Marketing Advantage: Target Your Ideal Audience with Affordable Campaigns")}</h6>
                        </div>
                        <p className='content-p'>{_t("Access a powerful advertising platform that targets your audience with precision, delivering impactful messages at extremely affordable prices.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Laser-Focused Targeting: Reach your specific audience with precision, ensuring that your marketing messages resonate with the right demographics.")}</li>
                            <li>{_t("Budget-Friendly Advertising: Maximize your marketing budget with cost-effective campaigns, getting the most value for your advertising dollars.")}</li>
                            <li>{_t("Data-Driven Campaigns: Utilize analytics to refine your marketing strategy, continuously optimizing campaigns for better audience engagement.")}</li>
                            <li>{_t("Increased Conversion Rates: Targeting the right audience leads to higher conversion rates, maximizing the impact of your marketing efforts.")}</li>
                            <li>{_t("Customizable Campaigns: Tailor your marketing messages based on audience segments, creating personalized experiences that drive customer loyalty.")}</li>
                        </ul>
                    </div> 
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/Revolution.svg" alt='icon'/>
                            <h6>{_t("Tech Revolution Awaits: Join Groundbreaking Change and Stay Ahead")}</h6>
                        </div>
                        <p className='content-p'>{_t("Be at the forefront of industry transformation with cutting-edge technology – secure your competitive edge by joining a groundbreaking movement from the ground level.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Early Adopter Advantage: Position your business as an early adopter of cutting-edge technology, showcasing your commitment to innovation and progress.")}</li>
                            <li>{_t("Industry Leadership: Stay ahead of competitors by embracing groundbreaking technology, becoming a leader in your industry and setting new standards.")}</li>
                            <li>{_t("Future-Proofing: Prepare your business for the future by integrating technology that anticipates and adapts to evolving market trends.")}</li>
                            <li>{_t("Enhanced Efficiency: Improve operational efficiency with state-of-the-art technology, streamlining processes and reducing manual workload.")}</li>
                            <li>{_t("Customer Attraction: Appeal to tech-savvy customers by demonstrating your business's commitment to staying at the forefront of industry advancements.")}</li>
                        </ul>
                    </div> 
                </div>
            </section>
        </>
    );
}

export default AboutListingV2;
