import i18next from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { LocalLang, haveValue, updateLangUrl } from "../../../utils/helpers";

const Languagebar = () => {
  const [currentLang, setCurrentLang] = useState(null);

  const languages = {
    "en-us": {
      code: "en-us",
      label: "EN",
      url: "/images/usa.png",
    },
    "es-es": {
      code: "es-es",
      label: "SP",
      url: "/images/spain.png",
    },
  };

  const setLang = (lang) => {
    localStorage.setItem("i18nextLng", lang);
    i18next.changeLanguage(lang);
    updateLangUrl(lang);
  };

  const pathname = window.location.pathname;

  useEffect(() => {
    const storedLang = LocalLang();
    console.log("🚀 ~ useEffect ~ storedLang:", storedLang)
    const supportedLangs = Object.keys(languages);

    if (!haveValue(storedLang)) {
      console.log("first --------");
      const browserLang = window.navigator.language?.split("-")[0];
      if (supportedLangs.some((item) => item.includes(browserLang))) {
        let browserLangCode = browserLang === "es" ? "es-es" : "en-us";
        setLang(languages[browserLangCode].code);
      }
    } else {
      console.log("second--------22222");
      if (supportedLangs.some((item) => item.includes(storedLang))) {
        console.log("2 ------1");
        setCurrentLang(storedLang);
      } else {
        console.log("third -33333333");
        setLang(languages["en-us"].code);
      }
    }
  }, [pathname]);

  return (
    <ul className="language-bar language-moblie ">
      {Object.keys(languages).map((key, index) => (
        <React.Fragment key={key}>
          <li>
            <button
              className={`lang-btn ${
                currentLang === languages[key].code ? "active" : ""
              }`}
              onClick={() => setLang(languages[key].code)}>
              <img
                src={languages[key].url}
                alt="country flags"
                width={30}
                height={30}
                loading="lazy"
              />

              {languages[key].label}
            </button>
          </li>
          {index !== Object.keys(languages).length - 1 && (
            <li className="slashing">/</li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Languagebar;
