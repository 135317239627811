import React from 'react';

const ContactBanner = () => {
    return (
        <>
            <section className='contact-banner'>
                <div className='container'>
                    <div className='contact-head'>
                        <h6>Any questions?</h6>
                        <h3>Contact us!</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscingelit, sed do eiusmod tempor incididunt ut labore .</p>
                    </div>
                    <div className='contact-banner-bottom'>
                        <ul className='contactdetails'>
                            <li>
                                <h4>Company</h4>
                                <p>Lorem ipsum dolor sit amet consectetur. Eget sollicitudin</p>
                            </li>
                            <li>
                                <h4>Call us</h4>
                                <p><a href="tel:+33 (0) 448 19 40 50">+33 (0) 448 19 40 50</a></p>
                            </li>
                            <li>
                                <h4>Working hours</h4>
                                <p>Monday – Friday</p>
                                <p>9:00 – 18:00</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactBanner;
