import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import AddUserForm from "../Forms/AddUserForm";
import useTranslator from "../../hooks/useTranslator";

const AddUserModel = ({
  show = false,
  setShow,
  onSubmit,
  eventType = "Create",
  user = null,
}) => {
  const { _t } = useTranslator();

  const handleHide = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        centered
        className="darkmode-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {_t(`${eventType === "Create" ? "Add" : "Edit"} User`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="userrole-detail-modal ">
            <AddUserForm
              onSubmit={onSubmit}
              setShow={setShow}
              edit={eventType === "Create" ? false : true}
              user={user}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUserModel;
