import React from "react";
import { Pagination } from "antd";

const Paginate = ({ total, onChange, page = 1 }) => {
  return (
    <>
      <Pagination
        showQuickJumper
        defaultCurrent={page}
        total={total}
        onChange={onChange}
      />
    </>
  );
};

export default Paginate;
