import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Tabs } from "antd";

import BlogSetting from "./BlogSetting";
import EmailSetting from "./EmailSetting";
import BackButton from "../../../Components/BackToPrev";
import { getAdminSetting } from "../../../api-services/dashboard";
import JobSetting from "./JobSetting";
const onChange = (key) => {};

const AdminAccount = (props) => {
  const [accountSettingData, setAccountSettingData] = useState(null);

  const fetchData = () => {
    getAdminSetting()
      .then((res) => {
        if (res.status === "success") {
          setAccountSettingData(res.data);
        }
      })
      .catch((err) => console.log("error while getting account setting", err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const items = [
    {
      key: "1",
      label: `Blog Setting`,
      children: (
        <BlogSetting
          accountSettingData={accountSettingData}
          fetchData={fetchData}
        />
      ),
    },
    {
      key: "2",
      label: `Job Setting`,
      children: (
        <JobSetting
          accountSettingData={accountSettingData}
          fetchData={fetchData}
        />
      ),
    },
    {
      key: "3",
      label: `Email Setting`,
      children: (
        <EmailSetting
          accountSettingData={accountSettingData}
          fetchData={fetchData}
        />
      ),
    },
  ];

  return (
    <>
      <div className="account-settings mt-4">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-10">
            <BackButton />
            <h4> Setting</h4>
          </div>
        </div>

        <div className="tabs-account">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
    </>
  );
};

export default AdminAccount;
