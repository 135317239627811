import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import ContactForm from "../Forms/ContactForm";
import useTranslator from "../../hooks/useTranslator";

const ContectModel = ({ show, setShow }) => {
  const { _t } = useTranslator();

  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <Modal className="modalcontact" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Need Further information? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm setShow={setShow} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContectModel;
