import sendRequest from "../providers/axios";
import Routes from "./routes";

export const getLangData = async (data) => {
  const response = await sendRequest(Routes.langs.get, "GET", data);
  return response;
};
export const updateEnglishLang = async (data) => {
  const response = await sendRequest(Routes.langs.updateEnglish, "POST", data);
  return response;
};
export const updateSpanish = async (data) => {
  const response = await sendRequest(Routes.langs.updateSpanish, "POST", data);
  return response;
};
