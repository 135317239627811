import React from "react";
import { Col, Row } from "react-bootstrap";
import useTranslator from "../../../hooks/useTranslator";
const AboutContent = () => {
  const { _t } = useTranslator();
  return (

    <>
      <section className="aboutcontent">
        <div className="container">
          <Row>
            <Col md={5}>
              <div className="aboutleft-img">
                <img src="/images/boy-work.webp" alt="boy work" />
              </div>
            </Col>
            <Col md={7}>
              <div className="about-rytside">
                <div className="primary-title title-leftside">
                  <h4>{_t("Change is Coming")}</h4>
                  <h2>{_t("We’re Paving The way")}</h2>
                  <div className="bar-line"></div>
                </div>
                <p className="content-p">{_t("Welcome to a space where supplementary income and cutting-edge technology converge to redefine success. Here, the vision is rooted in empowering individuals and propelling businesses towards financial freedom. Success is not just a destination; it's a collaborative journey, marked by innovative solutions that reshape conventional notions of prosperity.")}</p>
                <p className="content-p">{_t("At the heart of the mission lies a commitment to flexibility, autonomy, and the empowerment of tailored success. A dynamic platform for you to take control of your journey awaits. Whether you're breaking free from traditional work constraints, customizing your schedule around your lifestyle, or determining your value through personalized pay scales, this is where possibilities unfold.")}</p>
                <p className="content-p">{_t("Distinguishing characteristics include an unwavering commitment to innovation, offering tools and resources for modernization. Seamlessly connecting with a vast customer base through your phone and leading the evolution of remote mechanic technology are among the transformative opportunities. Submitting an application means more than looking for a job or growing your existing business; it involves becoming a participant in a movement that redefines success. Embark on this exhilarating journey, and contribute to shaping the industry’s future success collectively!")}</p>
                <div className="welder-img">
                    <img src='/images/welder.webp' alt="welding boy"/>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default AboutContent;
