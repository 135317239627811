import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { editJobInfo, postJob } from "../../../api-services/jobs";
import { clearJob, publishJob } from "../../../Redux/Slices/jobsSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import { haveValue } from "../../../utils/helpers";

const StepTwo = ({ setCurrentStep, jobFetchData, edit }) => {
  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };
  const navigate = useNavigate();

  const formData = useSelector((state) => state.jobsSlice.jobpost);

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    rate: yup.object().shape({
      rateValue: yup.number().optional(),
      min: yup.number().notRequired(),
      max: yup.number().notRequired(),
      rateTimeLine: yup.string().required("Rate timeline is required"),
      rateType: yup.string().required("Rate type is required"),
    }),
    summary: yup.string().required("Job summary is required"),
    specifics: yup.array(
      yup.object({
        name: yup.string().required("Please define specifics requirement"),
      })
    ),
    qualifications: yup.object().shape({
      education: yup.array(
        yup.object({
          name: yup.string().required("Please define specifics requirement"),
        })
      ),
      experience: yup.array(
        yup.object({
          name: yup.string().required("Please define specifics requirement"),
        })
      ),
    }),
    whyApply: yup.string().required("why apply section is required"),
    aboutCompany: yup.string().required("about section is required"),
  });

  const initialFormValues = {
    ...formData,
    rate: {
      rateType: formData?.rate?.rateType || "range",
      rateValue: formData?.rate?.rateValue || 0, // Initialize as null
      min: formData?.rate?.min || 0, // Initialize as null
      max: formData?.rate?.max || 0, // Initialize as null
      rateTimeLine: formData?.rate?.rateTimeLine || "",
    },
    qualifications: {
      education: [{ name: "" }],
      experience: [{ name: "" }],
    },
    specifics: [{ name: "" }],

    // ...other fields
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const {
    append: specificsAppendHandler,
    fields: specifics,
    remove: specificsRemoveHandler,
  } = useFieldArray({
    name: "specifics",
    control,
  });
  const {
    append: educationAppendHandler,
    fields: educations,
    remove: educationRemoveHandler,
  } = useFieldArray({
    name: "qualifications.education",
    control,
  });
  const {
    append: experienceAppendHandler,
    fields: experiences,
    remove: experienceRemoveHandler,
  } = useFieldArray({
    name: "qualifications.experience",
    control,
  });

  const editJobServices = (data) => {
    if (haveValue(jobFetchData._id) && edit) {
      data = { id: jobFetchData._id, ...data };
      editJobInfo(data)
        .then((jobEditRes) => {
          if (jobEditRes?.status === "success") {
            toast.success("Job Edit Successfully");
            dispatch(clearJob());
            reset();
            navigate("/jobs");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const RateType = watch("rate.rateType");
  const watchField = watch();

  const onSubmit = (data) => {
    if (data) {
      dispatch(publishJob(data));

      if (edit) {
        editJobServices(data);
      } else {
        postJob(data)
          .then((data) => {
            if (data.status === "success") {
              toast.success("Job post successfully");
              dispatch(clearJob());
              reset();
              navigate("/jobs");
            }
          })
          .catch((error) => {
            toast.error(error?.message);
          });
      }
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const { _t } = useTranslator();

  useEffect(() => {
    if (haveValue(jobFetchData) && edit) {
      const specifics = jobFetchData?.specifics?.map((item) => ({
        name: item.name,
      }));

      const education = jobFetchData?.qualifications?.education?.map(
        (item) => ({ name: item.name })
      );
      const experience = jobFetchData?.qualifications?.experience?.map(
        (item) => ({ name: item.name })
      );
      reset({
        rate: {
          rateType: jobFetchData?.rate?.rateType,
          rateTimeLine: jobFetchData?.rate?.rateTimeLine,
          rateValue: jobFetchData?.rate?.rateValue,
        },
        summary: jobFetchData?.summary,
        specifics: jobFetchData?.specifics,
        specifics: specifics,
        whyApply: jobFetchData?.whyApply,
        aboutCompany: jobFetchData?.aboutCompany,
        qualifications: {
          education: education,
          experience: experience,
        },
        ...formData,
      });
    }
  }, [jobFetchData, edit]);

  return (
    <div className="block-bar">
      <Form className="mt-0" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="formrow">
          <div className="dflex-inputbar form-100">
            <Form.Label>Show pay by</Form.Label>

            <Form.Group className="forminput" controlId="range">
              <Form.Select
                {...register("rate.rateType")}
                className="form-control arrow-dropdown">
                <option value="range">Range</option>
                <option value="starting amount">starting amount</option>
                <option value="maximum amount">maximum amount</option>
                <option value="fixed amount">fixed amount</option>
              </Form.Select>
              <p className="error">
                {" "}
                {errors?.rate?.rateType && `${errors?.rate?.rateType.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="render_row">
            {RateType === "range" ? (
              <>
                <div className="dflex-inputbar ">
                  <Form.Label>Minimum</Form.Label>
                  <Form.Group className="forminput" controlId="min">
                    <Form.Control
                      type="number"
                      placeholder="$ 100"
                      {...register("rate.min", {
                        valueAsNumber: true,
                      })}
                    />
                    <p className="error">
                      {" "}
                      {errors?.rate?.min && `${errors?.rate?.min.message}`}
                    </p>
                  </Form.Group>
                </div>
                <div className="dflex-inputbar ">
                  <Form.Label>Maximum</Form.Label>
                  <Form.Group className="forminput" controlId="max">
                    <Form.Control
                      type="number"
                      placeholder="$ 100"
                      {...register("rate.max", {
                        valueAsNumber: true,
                      })}
                    />
                    <p className="error">
                      {" "}
                      {errors?.rate?.max && `${errors?.rate?.max.message}`}
                    </p>
                  </Form.Group>
                </div>
              </>
            ) : (
              <div className="dflex-inputbar ">
                <Form.Label>Amount</Form.Label>
                <Form.Group className="forminput" controlId="rateValue">
                  <Form.Control
                    type="number"
                    placeholder="$ 100"
                    {...register("rate.rateValue", {
                      valueAsNumber: true,
                    })}
                  />
                  <p className="error">
                    {" "}
                    {errors?.rate?.rateValue &&
                      `${errors?.rate?.rateValue.message}`}
                  </p>
                </Form.Group>
              </div>
            )}

            <div className="dflex-inputbar">
              <Form.Label>Rate</Form.Label>
              <Form.Group className="forminput" controlId="rate.rateTimeLine">
                <Form.Select
                  aria-label="rateTimeLine"
                  {...register("rate.rateTimeLine")}
                  className="form-control arrow-dropdown">
                  <option value="">Basic pay range</option>
                  <option value="hourly">Hourly</option>
                  <option value="monthly"> Monthly</option>
                  <option value="weekly">Weekly</option>
                  <option value="daily">Daily</option>
                  <option value="yearly">Yearly</option>
                </Form.Select>
                <p className="error">
                  {" "}
                  {errors?.rate?.rateTimeLine &&
                    `${errors?.rate?.rateTimeLine.message}`}
                </p>
              </Form.Group>
            </div>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Label>{_t("Job Summary ")}</Form.Label>
            <Form.Group className="forminput" controlId="jobDescription">
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                placeholder="Job Summary"
                {...register("summary")}
              />
              <p className="error">
                {" "}
                {errors?.summary && `${errors?.summary.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="dflex-inputbar form-100">
            <div className="btnlignself">
              <Form.Label>Job Specific Requirement</Form.Label>
              <Button
                className="btn btnorange"
                onClick={() => specificsAppendHandler({ name: "" })}>
                Add More
              </Button>
            </div>

            <Form.Group
              className="forminput remove-inputbar"
              controlId="specifics">
              {specifics.map((field, index) => {
                return (
                  <>
                    <div className="dflex-inputbar form-100  p-0">
                      <Form.Control
                        key={field.id}
                        name={`specifics.${index}.name`}
                        type="text"
                        placeholder="Job specifics"
                        {...register(`specifics.${index}.name`)}
                      />
                      <div className="removebtn">
                        {index > 0 && (
                          <span onClick={() => specificsRemoveHandler(index)}>
                            Remove
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
              <p className="error">
                {" "}
                {errors?.qualifications?.education &&
                  `${errors?.qualifications?.education?.message}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <div className="btnlignself">
              <Form.Label>{_t("Qualifications")}</Form.Label>
              <Button
                className="btn btnorange"
                onClick={() => educationAppendHandler({ name: "" })}>
                Add More
              </Button>
            </div>

            <Form.Group
              className="forminput remove-inputbar"
              controlId="education">
              {educations.map((field, index) => {
                return (
                  <>
                    <div className="dflex-inputbar form-100  p-0">
                      <Form.Control
                        key={field.id}
                        name={`qualifications.education.${index}.name`}
                        type="text"
                        placeholder="Job education"
                        {...register(`qualifications.education.${index}.name`)}
                      />
                      <div className="removebtn">
                        {index > 0 && (
                          <span onClick={() => educationRemoveHandler(index)}>
                            Remove
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
              <p className="error">
                {" "}
                {errors?.qualifications?.education &&
                  `${errors?.qualifications?.education?.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="dflex-inputbar form-100">
            <div className="btnlignself">
              <Form.Label>{_t("Job Experiences Requirement")}</Form.Label>
              <Button
                className="btn btnorange"
                onClick={() => experienceAppendHandler({ name: "" })}>
                Add More
              </Button>
            </div>

            <Form.Group
              className="forminput remove-inputbar"
              controlId="experience">
              {experiences.map((field, index) => {
                return (
                  <>
                    <div className="dflex-inputbar form-100  p-0">
                      <Form.Control
                        key={field.id}
                        name={`qualifications.experience.${index}.name`}
                        type="text"
                        placeholder="Job experience"
                        {...register(`qualifications.experience.${index}.name`)}
                      />
                      <div className="removebtn">
                        {index > 0 && (
                          <span onClick={() => experienceRemoveHandler(index)}>
                            Remove
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
              <p className="error">
                {" "}
                {errors?.qualifications?.experience &&
                  `${errors?.qualifications?.experience?.message}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Label>{_t("Why apply for this job")}</Form.Label>
            <Form.Group className="forminput" controlId="whyApply">
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                placeholder="why apply for this job"
                {...register("whyApply")}
              />
              <p className="error">
                {" "}
                {errors?.whyApply && `${errors?.whyApply.message}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Label>About your company</Form.Label>
            <Form.Group className="forminput" controlId="aboutCompany">
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                placeholder="About your company"
                {...register("aboutCompany")}
              />
              <p className="error">
                {errors?.aboutCompany && `${errors?.aboutCompany?.message}`}
              </p>
            </Form.Group>
          </div>
        </div>
        <div className="btnform mt-2">
          <div className="btnbar">
            <button
              className="btnorange btn"
              type="button"
              onClick={handleBack}>
              Back
            </button>
          </div>
          {edit ? (
            <div className="btnbar">
              <button className="btnorange btn" type="submit">
                Edit
              </button>
            </div>
          ) : (
            <div className="btnbar">
              <button className="btnorange btn" type="submit">
                Post
              </button>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default StepTwo;
