import React, { useEffect, useState } from "react";
import { requestHandler } from "../utils/comman";
import { adminRegister } from "../api-services/auth";
import toast from "react-hot-toast";
import { editUser, userAccessControl, userList } from "../api-services/user";
import { debounce } from "../utils/helpers";

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotaldocs] = useState(null);

  const debounceList = debounce(() => {
    fetchList();
  }, 500);

  const fetchList = () => {
    return requestHandler(
      async () => await userList({ page, limit, searchText }),
      setLoading,
      (resUser) => {
        setUsers(resUser.data.docs);
        setTotaldocs(resUser.data.totalDocs);
      },
      setError
    );
  };

  const addUser = (data) => {
    requestHandler(
      async () => await adminRegister(data),
      setLoading,
      (userAdd) => {
        if (userAdd.status === "success") {
          toast.success("user add successfully");
          fetchList();
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };

  const activatedUser = (data) => {
    const newData = { type: "activated", ...data };
    const confinOk = window.confirm("Are you sure for activated user");
    if (confinOk) {
      requestHandler(
        async () => await userAccessControl(newData),
        setLoading,
        (res) => {
          toast.success("user activated successfully");
          fetchList();
        },
        (error) => {
          toast.error(error);
        }
      );
    }
  };

  const deactivateUser = (data) => {
    const newData = { type: "deactivated", ...data };
    const confinOk = window.confirm("Are you sure for deactivated user");
   
    if (confinOk) {
      requestHandler(
        async () => await userAccessControl(newData),
        setLoading,
        (res) => {
          toast.success("user deactivated successfully");
          fetchList();
        },
        (error) => {
          toast.error(error);
        }
      );
    }
  };
  const editUserhandler = (data) => {
  
    requestHandler(
      async () => await editUser(data),
      setLoading,
      (res) => {
        if (res.status === "success") {
          toast.success("user update successfully");
          fetchList();
        }
      },
      (error) => {
        toast.error(error);
      }
    );
  };

  const pageHandler = (page) => {
    setPage(page);
  };

  useEffect(() => {
    debounceList();
  }, [page, limit, searchText]);

  if (error) {
    console.log("error", error);
  }

  return {
    users,
    loading,
    error,
    addUser,
    activatedUser,
    deactivateUser,
    setSearchText,
    searchText,
    totalDocs,
    pageHandler,
    page,
    fetchList,
    editUserhandler,
  };
};

export default useUsers;
