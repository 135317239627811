import React, { useEffect } from "react";
import BlogBanner from "../../Components/PagesAssets/Blog/BlogBanner";
import AllBlog from "../../Components/PagesAssets/Blog/AllBlog";
import ContactUs from "../../Components/PagesAssets/AboutUs/ContactUs";
import FeatureArticle from "../../Components/PagesAssets/Blog/FeatureArticle";
import { Helmet } from "react-helmet";
import { useBlogs } from "../../data-fetch/useBlogs";

const Blog = (props) => {
  const { primaryBlog } = useBlogs();
  const hash = window.location.hash;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hash]);
  return (
    <>
      <Helmet>
        <title>Remote Mechanic Jobs Blogs </title>
        {/* Set the canonical URL */}
        <link
          rel="canonical"
          href={`https://www.remotemechanicjobs.com/blog`}
        />
        {/* Set the meta description */}
        <meta
          name="description"
          content="The same vehicle and equipment maintenance industry , with some upgraded service options. Offering Remote Services provides you with an entirely new revenue stream at your own pace."
        />
      </Helmet>
      <div className="Blogbar">
        <BlogBanner />
        {primaryBlog && <FeatureArticle primaryBlog={primaryBlog} />}

        <AllBlog />
        <ContactUs />
      </div>
    </>
  );
};

export default Blog;
