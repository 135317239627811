import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const formSchema = Yup.object().shape({
  firstname: Yup.string().label("First Name").trim().required().min(3).max(64),
  lastname: Yup.string().label("Last Name").trim().required().min(3).max(64),
  address: Yup.string().label("Address").trim().required().min(3),
  number: Yup.number().label("Number").required(),
  work: Yup.string().label("Work").oneOf(["unemployed", "employed"]),
  company: Yup.string().when("work", ([work], schema) => {
    if (work === "employed") {
      return schema.required().min(3).max(64);
    }
    return schema.notRequired();
  }),
  role: Yup.string().when("work", ([work], schema) => {
    if (work === "employed") {
      return schema.required().min(3).max(64);
    }
    return schema.notRequired();
  }),
});

export const AddblogScehma = Yup.object().shape({
  title: Yup.string().required().min(10),
  introduction: Yup.string().required().min(10),
  content: Yup.string().required().min(10),
  tags: Yup.string().required(),
  category: Yup.string(),
  coverImage: Yup.string(),
  blogLang: Yup.string(),
});
