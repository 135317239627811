import React from "react";
import useTranslator from "../../../hooks/useTranslator";
import { Link } from "react-router-dom";
const WhyApply = () => {
  const { _t } = useTranslator();
  return (
    <>
      <section className="whyapply">
        <div className="container">
          <div className="primary-title">
            <h4>{_t("WHY APPLY?")}</h4>
            <h2>{_t("Application Benefits Explained")}</h2>
            <div className="bar-line"></div>
          </div>
       
        </div>
      </section>
    </>
  );
};

export default WhyApply;
