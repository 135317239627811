import React, { Suspense, memo } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import useTranslator from "../../hooks/useTranslator";
import Loader from "../Loader";
const Icon = React.lazy(() => import('./Icon'));

const { useState, useEffect, useRef, useMemo } = React;

const PROGRESS_UNIT = 0.01;
const PROGRESS_TIMEOUT = 5;

const getArcPath = (start, end, innerRadius, outerRadius) => {
  const startAngle = start * Math.PI * 2;
  const endAngle = end * Math.PI * 2;
  const x1 = innerRadius * Math.sin(startAngle);
  const y1 = innerRadius * -Math.cos(startAngle);
  const x2 = outerRadius * Math.sin(startAngle);
  const y2 = outerRadius * -Math.cos(startAngle);
  const x3 = outerRadius * Math.sin(endAngle);
  const y3 = outerRadius * -Math.cos(endAngle);
  const x4 = innerRadius * Math.sin(endAngle);
  const y4 = innerRadius * -Math.cos(endAngle);
  const bigArc = end - start >= 0.5;
  const outerFlags = bigArc ? "1 1 1" : "0 0 1";
  const innerFlags = bigArc ? "1 1 0" : "1 0 0";
  return `M ${x1},${y1} L ${x2},${y2} A ${outerRadius} ${outerRadius} ${outerFlags} ${x3},${y3} 
        L ${x4},${y4} A ${innerRadius} ${innerRadius} ${innerFlags} ${x1},${y1} Z`;
};

const DonutChart = ({ width, height, items, innerRadius, outerRadius }) => {
  const [visiblePart, setVisiblePart] = useState(0);

  useEffect(() => {
    if (visiblePart < 1) {
      setTimeout(
        () => setVisiblePart(visiblePart + PROGRESS_UNIT),
        PROGRESS_TIMEOUT
      );
    }
  }, [visiblePart]);

  const segments = useMemo(() => {
    const sum = items.reduce((sum, item) => sum + item.value, 0);
    let start = 0;
    return items.map((item) => {
      const delta = (item.value / sum) * visiblePart;
      const path = getArcPath(start, start + delta, innerRadius, outerRadius);
      start += delta;
      return { ...item, path };
    });
  }, [items, innerRadius, outerRadius, visiblePart]);

  return (
    <Suspense fallback={<Loader />}>
      <Icon width={width} height={height} segments={segments} />
    </Suspense>
  );
};

const Pie = ({ count, label }) => {
  const { _t } = useTranslator();
  const [counterStart, setCounterStart] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (counterStart) {
      setVisible(true);
    }
  }, [counterStart]);

  const pendingArea = 100 - parseInt(count);
  const items = [
    { value: count, color: "#df572c" },
    { value: pendingArea, color: "#737373" },
  ];

  return (
    <ScrollTrigger
      onEnter={() => setCounterStart(true)}
      onExit={() => setCounterStart(false)}>
      {visible && (
        <div className="pie-parent">
          <DonutChart
            width={250}
            height={250}
            items={items}
            innerRadius={100}
            outerRadius={120}
            visiblePart={counterStart ? 1 : 0}
          />
          <h3>{count} %</h3>
        </div>
      )}
    </ScrollTrigger>
  );
};

export default memo(Pie);
