import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveData } from "../../../Redux/Slices/formSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { Form } from "react-bootstrap";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const NumberOfTechnicians = ({ setCurrentStep, totalCase }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formSlice.data);
  const isBusinessOwner = formData.category;


  const handleBack = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const schema = yup
    .object()
    .shape({
      numberOfTechnicians: yup.number().required(),
    })
    .required();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  useScrollTopForm(`fromapply`);


  const onSubmit = (data) => {
    if (data) {
      dispatch(saveData(data));
      setCurrentStep((prevStep) =>
        prevStep < totalCase ? prevStep + 1 : prevStep
      );
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const { _t } = useTranslator();

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="formsteps">
        <h4 className="mb-5">{_t("How many technicians do you have ?")}</h4>

        <div className="dflex-inputbar form-100">
          <Form.Group className="forminput" controlId="certificationText">
            <Form.Control
              type="number"
                placeholder="Number of technicians eg: 1, 5, 10"
              {...register("numberOfTechnicians")}
            />
            <p className="error">
              {" "}
              {errors.certificationText &&
                `${_t(errors.numberOfTechnicians.message)}`}
            </p>
          </Form.Group>
        </div>
        <p className="error">
          {" "}
          {errors.certificationText &&
            `${_t(errors.numberOfTechnicians.message)}`}
        </p>
      </div>
      <div className="btnform">
        <button className="btn btnorange" onClick={handleBack}>
          {_t("Back")}
        </button>
        <button className="btn btnorange" type="submit">
          {_t("Next")}
        </button>
      </div>
    </form>
  );
};

export default NumberOfTechnicians;
