import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { contactFormRequestApi } from "../../api-services/user";
import useTranslator from "../../hooks/useTranslator";
import toast from "react-hot-toast";

const ContactForm = ({ setShow }) => {
  const schema = yup
    .object()
    .shape({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup.string().email("Invalid email").nullable().notRequired(),
      organization: yup.string().required("Business name is required"),
      mobile_number: yup.string().notRequired(),
      message: yup.string().notRequired(),
    })
    .required();

  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      message: "",
      mobile_number: "",
      organization: "",
      lastName: "",
      firstName: "",
      email: "",
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      contactFormRequestApi(data)
        .then((data) => {
          if (data.status === "success") {
            toast.success("Thank you for contact us our team reach you soon");
            reset();
            setShow(false);
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const { _t } = useTranslator();

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="formrow">
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="Firstname">
              <Form.Control
                type="text"
                placeholder="First Name"
                {...register("firstName")}
              />
              <p className="error">
                {" "}
                {errors.firstName && `${_t(errors.firstName.message)}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="Lastname">
              <Form.Control
                type="text"
                placeholder="Last Name"
                {...register("lastName")}
              />
              <p className="error">
                {" "}
                {errors.lastName && `${_t(errors.lastName.message)}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="Organization">
              <Form.Control
                type="text"
                placeholder="Business Name"
                {...register("organization")}
              />
              <p className="error">
                {" "}
                {errors.organization && `${_t(errors.organization.message)}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="Email">
              <Form.Control
                type="email"
                placeholder="Email"
                {...register("email")}
              />
              <p className="error">
                {" "}
                {errors.email && `${_t(errors.email.message)}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Group className="forminput" controlId="Phone number">
              <Form.Control
                type="number"
                placeholder="Phone number"
                {...register("mobile_number")}
              />
              <p className="error">
                {" "}
                {errors.mobile_number && `${_t(errors.mobile_number.message)}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Group className="forminput" controlId="Message">
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Message"
                {...register("message")}
              />
              <p className="error">
                {" "}
                {errors.message && `${_t(errors.message.message)}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <div className="dflex-bar">
              {/* <Form.Group className="forminput" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="By clicking send, I accept that my data is processed by XXXXXXXX"
                />
              </Form.Group> */}
              <div className="btnbar">
                <button className="btnorange btn" type="submit">
                  {_t("Send")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ContactForm;
