import React, { useEffect, useState } from "react";

import BackButton from "../../../Components/BackToPrev";

import { categoriesApplication } from "../../../api-services/dashboard";
import DashboardCategoryTable from "../../../Components/DashboardCategoryTable";
import Loader from "../../../Components/Loader";
import DashboardCard from "../../../Components/DashboardCard";

const DashboardPage = () => {
  const [categoriesData, setCategiresData] = useState([]);
  const [categoriesApplicationInfo, setCategoriesApplication] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalApplicationsCount, setTotalApplicationCount] = useState(null);
  const [totalLeadCount, setTotalLeadCount] = useState(null);
  const [totalContactCount, setContactCount] = useState(null);
  const [totalShortApplicationCount, setShortApplicationCount] = useState(null);

  useEffect(() => {
    setLoading(true);
    categoriesApplication()
      .then((resData) => {
        if (resData.status === "success") {
          setLoading(false);
          setCategiresData(resData.data.applicationsData);
          setTotalApplicationCount(resData.data.totalApplication);
          setTotalLeadCount(resData.data.totalLeads);
          setContactCount(resData.data.totalContactSubmission);
          setShortApplicationCount(resData.data.totalShortApplication);
        }
      })
      .catch((error) => console.log(error, "Error"))
      .finally(() => setLoading(false));
  }, []);

  const handleTableData = (data) => {
    setCategoriesApplication(data);
  };

  console.log("categoriesData", categoriesData);

  return (
    <>
      <div className="overview-page">
        <div className="top-header">
          <div className="page-name">
            <BackButton />
            <h4>Overview</h4>
          </div>
          {/* <div className="import-export">
            <button>
              <AiOutlineDownload /> Import
            </button>
            <button>
              <AiOutlineUpload /> Export
            </button>
          </div> */}
        </div>
        {/* -------main page starts------- */}
        {loading ? (
          <Loader />
        ) : (
          <div className="main-page">
            {/* -----------top header-cards---------- */}
            <div className="top-cards">
              <DashboardCard
                totalApplicationsCount={totalApplicationsCount}
                label="New Application"
              />
              <DashboardCard
                totalApplicationsCount={totalContactCount}
                label="New Contact"
              />
              <DashboardCard
                totalApplicationsCount={totalLeadCount}
                label="New Leads"
              />
              <DashboardCard
                totalApplicationsCount={totalShortApplicationCount}
                label="Short Application"
              />
            </div>
            <h2 className="mt-5">Job Niche Statics</h2>
            <div className="top-cards">
              {categoriesData?.map((cat, index) => {
                return (
                  <DashboardCard cat={cat} handleTableData={handleTableData} />
                );
              })}
            </div>

            <DashboardCategoryTable data={categoriesApplicationInfo} />
          </div>
        )}
      </div>
    </>
  );
};
export default DashboardPage;
