import React from "react";

import useTranslator from "../../../hooks/useTranslator";
import LookingTabasBusiness from "../../LookingTabs/LookingTabasBusiness";

const LookingForasBusiness = () => {
  const {_t} =useTranslator()
  return (
    <div className="LookingFor">
      <div className="container">
        <div className="primary-title title-leftside">
          <h4>{_t("Who We’re Looking For")}</h4>
          <h2>{_t("We Need Service Centers Interested in Offering Remote Services")}</h2>
          <div className="bar-line"></div>
        </div>
        <LookingTabasBusiness/>
      </div>
    </div>
  );
};

export default LookingForasBusiness;
