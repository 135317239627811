import React from "react";

import useTranslator from "../../hooks/useTranslator";
const LookingTabasBusiness = () => {
  const { _t } = useTranslator();
  const businessowner = [
    {
      carimage: "/images/sedan.svg",
      cartitle: "Automotive Service Centers",
    },
    {
      carimage: "/images/hatchback.svg",
      cartitle: "Classic Car Service Centers",
    },
    {
      carimage: "/images/sportbike.svg",
      cartitle: "Motorcycle Service Centers",
    },
    {
      carimage: "/images/minitruck.svg",
      cartitle: "RV Service Centers",
    },
    {
      carimage: "/images/boat.svg",
      cartitle: "Marine Vehicle Service Centers",
    },
    {
      carimage: "/images/small-engine.svg",
      cartitle: "Small Engine Service Centers",
    },
    {
      carimage: "/images/jcb.svg",
      cartitle: "Heavy Machinery Service Centers",
    },
    {
      carimage: "/images/bus.svg",
      cartitle: "Commercial Bus Service Centers",
    },
    {
      carimage: "/images/truck.svg",
      cartitle: "Commercial Truck Service Centers",
    },
    {
      carimage: "/images/lowtruck.svg",
      cartitle: "Tow Truck Operators",
    },
    {
      carimage: "/images/wheels.svg",
      cartitle: "Tire & Wheel Service Centers",
    },
    {
      carimage: "/images/settingkey.svg",
      cartitle: "Mobile & Roadside Service Centers",
    },
  ];

  return (
    <>
      <div className="LookingForTabs">
        <div className="container">
          {/* businessowner content  */}
          <div className="gridbar">
            {businessowner.map((business, index) => (
              <div className="cars-element" key={index}>
                <div className="lookingservice">
                  <div className="carside">
                    <img
                      src={business.carimage}
                      alt={business.cartitle}
                      loading="lazy"
                      width={65}
                      height={65}
                    />
                  </div>
                  <div className="service-content">
                    <h4>{_t(business.cartitle)}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LookingTabasBusiness;
