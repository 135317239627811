import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import toast from "react-hot-toast";
import { useForm, useWatch } from "react-hook-form";
import { ApplicationSTATUS } from "../../../constants/applicant";

import { QuickFormConstant } from "../../../constants/quick-form";
import { useJobList } from "../../../data-fetch/useJobList";
import { businessOwnerApplyJob } from "../../../api-services/application";
import useTranslator from "../../../hooks/useTranslator";
import {
  multiSelectOptions,
  haveValue,
  isBusinessOwner,
  StateList,
} from "../../../utils/helpers";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";
import useStateAndCity from "../../../data-fetch/useStateAndCity";
const AddApplicationForm = () => {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const { jobCategories } = useFetchJobCategory();

  const [applicationData, setApplicationData] = useState({});

  const { _t } = useTranslator();
  const navigate = useNavigate();
  const {
    fetchCities,
    stateList: stateListMap,
    cityList,
    loading,
  } = useStateAndCity();

  const { jobSelectList } = useJobList();
  console.log("🚀 ~ AddApplicationForm ~ jobSelectList:", jobSelectList);

  const applicantSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    lName: Yup.string().required("l name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobileNo: Yup.string().required("Mobile number is required"),
    city: Yup.string().notRequired(),
    state: Yup.string().required("State is required"),
    country: Yup.string(),
    certifiedTechnician: Yup.boolean().required(),
    isOem: Yup.boolean().optional(),
    isLicensed: Yup.boolean().optional(),
    numberOfTechnicians: Yup.number().nullable().default(null),
    certificationText: Yup.string().default(""),
    business_name: Yup.string().optional().nullable().default(""),
    servicesInterest: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("You can choose multiple"),
    vehicles: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("You can choose multiple"),
    providedServices: Yup.array().of(Yup.string().optional()),
    jobNiche: Yup.array().of(Yup.string().optional()),
    experienceYears: Yup.string().required().nullable(),
    jobId: Yup.string().required("Job ID is required"),
    notes: Yup.string().nullable(),
    resume: Yup.string().nullable().default(null),
    category: Yup.string().required(),
    status: Yup.string().required(),
  });

  const initialValue = {
    isOem: false,
    isLicensed: false,
    certifiedTechnician: false,
    mobileNo: "",
    email: "",
    name: "",
    lName: "",
    numberOfTechnicians: null,
    state: "",
    city: "",
    country: "US",
    business_name: "",
    numberOfTechnicians: null,
    experienceYears: "",
    jobId: "",
    category: "",
    status: "Applied",
    vehicles: [],
    jobNiche: [],
    servicesInterest: [],
    providedServices: [],
  };

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    isSubmitting,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialValue,
    resolver: yupResolver(applicantSchema),
    mode: "onSubmit",
  });

  const show = useWatch({
    control,
    name: ["jobId", "category", "certifiedTechnician"],
  });
  console.log("🚀 ~ AddApplicationForm ~ show:", show);

  const isCertification = show[2];

  const isMechanic = show[1] === "Mechanic" ? true : false;
  console.log("🚀 ~ AddApplicationForm ~ isMechanic:", isMechanic, show[1]);

  const selectJob = show[0];
  console.log("🚀 ~ AddApplicationForm ~ selectJob:", selectJob);

  const role = jobSelectList.find((j) => j.id === selectJob);

  useEffect(() => {
    if (haveValue(role)) {
      setValue("category", role?.category);
    }
  }, [role]);

  const valueUpdate = (array) => array.map((item) => item.name);
  const providedServicesSeledct = (selectedList) => {
    valueUpdate(selectedList);
    setValue("providedServices", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      providedServices: selectedList,
    }));
  };

  const providedServicesRemove = (selectedList) => {
    setValue("providedServices", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      providedServices: selectedList,
    }));
  };
  const interestedServicesSelect = (selectedList) => {
    setValue("servicesInterest", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      servicesInterest: selectedList,
    }));
  };

  const interestedServicesSelectRemove = (selectedList) => {
    setValue("servicesInterest", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      servicesInterest: selectedList,
    }));
  };

  const industryServicesSelect = (selectedList) => {
    setValue("vehicles", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      vehicles: selectedList,
    }));
  };

  const industryServicesSelectRemove = (selectedList) => {
    setValue("vehicles", valueUpdate(selectedList));
    setApplicationData((prev) => ({
      ...prev,
      vehicles: selectedList,
    }));
  };

  const selectJobNiche = (selectedList) => {
    setValue("jobNiche", valueUpdate(selectedList));
  };
  const removeJobNiche = (selectedList) => {
    setValue("jobNiche", valueUpdate(selectedList));
  };

  const onSubmit = (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    businessOwnerApplyJob(data)
      .then((applicationProfile) => {
        if (applicationProfile.status === "success") {
          toast.success(_t("Application profile create successfully"));
          reset();
          navigate("/applications");
        }
      })
      .catch((err) => {
        toast.error(_t(err?.message));
      });
  };

  const onError = (error) => console.log("err during form submission", error);
  console.log("errors in forms ", errors);

    const handleState = (e) => {
    const value = e.target.value;
    console.log("🚀 ~ StepSeven ~ value:", value);
    const filterState = StateList.find((st) => st.iso === value);
    fetchCities(value);
    console.log("🚀 ~ handleState ~ filterState:", filterState);
    setValue("state", filterState?.name);
    setSelectedState(value);
  };

  return (
    <Form className="accountform" onSubmit={handleSubmit(onSubmit, onError)}>
      <Row>
        <Col lg={6}>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="jobId">
              <Form.Label>{_t("Select Job")}</Form.Label>
              <div className="filter">
                <select {...register("jobId")}>
                  <option value="">{_t("Select Job")}</option>

                  {jobSelectList.length &&
                    jobSelectList?.map((job) => (
                      <option value={job?.id} key={job?.id}>
                        {job?.name}
                      </option>
                    ))}
                </select>
              </div>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.jobId && `${errors.jobId.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="category">
              <Form.Label>{_t("User Category")}</Form.Label>
              <Form.Control type="text" {...register("category")} readOnly />

              {/* <div className="filter">
                <select {...register("category")} readOnly>
                  <option value="">Choose User Category</option>
                  <option value="Shop Owner">Shop Owner</option>
                  <option value="Mechanic">Mechanic</option>
                </select>
              </div> */}
            </Form.Group>
            <p className="error">
              {" "}
              {errors.category && `${errors.category.message}`}
            </p>
          </Col>
          <div className="multiselect-bar">
            <h5 className="formtitle">{_t("Job Niche")}</h5>
            <Multiselect
              options={jobCategories}
              selectedValues={multiSelectOptions(applicationData?.jobNiche)}
              onSelect={selectJobNiche}
              onRemove={removeJobNiche}
              displayValue="name"
            />
          </div>

          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="name">
              <Form.Label>{_t("First Name")}</Form.Label>
              <Form.Control type="text" {...register("name")} />
            </Form.Group>
            <p className="error"> {errors.name && `${errors.name.message}`}</p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="lName">
              <Form.Label>{_t("Last Name")}</Form.Label>
              <Form.Control type="text" {...register("lName")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.lName && `${errors.lName.message}`}
            </p>
          </Col>

          {!isMechanic && (
            <Col lg={12}>
              <Form.Group className="formninputbar" controlId="business_name">
                <Form.Label>{_t("Business Name")}</Form.Label>
                <Form.Control type="text" {...register("business_name")} />
              </Form.Group>
              <p className="error">
                {" "}
                {errors.business_name && `${errors.business_name.message}`}
              </p>
            </Col>
          )}

          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="email">
              <Form.Label>{_t("Email")}</Form.Label>
              <Form.Control type="email" {...register("email")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.email && `${errors.email.message}`}
            </p>
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="email">
              <Form.Label>{_t("Phone Number")}</Form.Label>
              <Form.Control type="text" {...register("mobileNo")} />
            </Form.Group>
            <p className="error">
              {" "}
              {errors.mobileNo && `${errors.mobileNo.message}`}
            </p>
          </Col>

          {/* multiselect form  */}
        </Col>
        <Col lg={6}>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="status">
              <Form.Label>{_t("Status")}</Form.Label>
              <div className="filter">
                <select {...register("status")}>
                  <option value={ApplicationSTATUS.APPLIED}>
                    {ApplicationSTATUS.APPLIED}
                  </option>
                  <option value={ApplicationSTATUS.APPROVED}>
                    {ApplicationSTATUS.APPROVED}
                  </option>
                  <option value={ApplicationSTATUS.CONTACTED}>
                    {ApplicationSTATUS.CONTACTED}
                  </option>
                  <option value={ApplicationSTATUS.INTERESTED}>
                    {ApplicationSTATUS.INTERESTED}
                  </option>
                  <option value={ApplicationSTATUS.NOT_INTERESTED}>
                    {ApplicationSTATUS.NOT_INTERESTED}
                  </option>
                  <option value={ApplicationSTATUS.ONBOARDED}>
                    {ApplicationSTATUS.ONBOARDED}
                  </option>
                  <option value={ApplicationSTATUS.REJECTED}>
                    {ApplicationSTATUS.REJECTED}
                  </option>
                </select>
              </div>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.status && `${errors.status.message}`}
            </p>
          </Col>
          <div className="multiselect-bar">
            <h5 className="formtitle">{_t("Services Applied.")}</h5>
            <Multiselect
              options={[
                {
                  name: QuickFormConstant?.servicesInterest.BREAKDOWN_SERVICES,
                },
                {
                  name: QuickFormConstant?.servicesInterest.IN_PERSON_SERVICES,
                },
                { name: QuickFormConstant?.servicesInterest.REMOTE_SERVICES },
              ]}
              selectedValues={applicationData?.servicesInterest}
              onSelect={(value) => interestedServicesSelect(value)}
              onRemove={(value) => interestedServicesSelectRemove(value)}
              displayValue="name"
            />
            <p className="error">
              {errors.servicesInterest && `${errors.servicesInterest.message}`}
            </p>
          </div>
          <div className="multiselect-bar mt-10">
            <h5 className="formtitle">{_t("Additional Services")}</h5>
            <Multiselect
              options={multiSelectOptions([
                QuickFormConstant.PROVIDED_SERVICES.TOW,
                QuickFormConstant.PROVIDED_SERVICES.WHEEL_TIRE,
              ])}
              selectedValues={applicationData?.providedServices}
              onSelect={(value) => providedServicesSeledct(value)}
              onRemove={(value) => providedServicesRemove(value)}
              displayValue="name"
            />
            <p className="error">
              {errors.providedServices && `${errors.providedServices.message}`}
            </p>
          </div>
          <div className="multiselect-bar">
            <h5 className="formtitle">{_t("Vehicles")}</h5>
            <Multiselect
              options={multiSelectOptions([
                QuickFormConstant.INDUSTRY_SERVE.AUTOMOTIVE,
                QuickFormConstant.INDUSTRY_SERVE.CLASSIC_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.COMMERCIAL_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.HEAVY_MACHINERY,
                QuickFormConstant.INDUSTRY_SERVE.MARINE_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.MOTORCYCLE,
                QuickFormConstant.INDUSTRY_SERVE.RECREATIONAL_VEHICLES,
                QuickFormConstant.INDUSTRY_SERVE.SMALL_ENGINES,
                QuickFormConstant.SPECIFIC_VEHICLES.COMMERCIAL_BUSES,
                QuickFormConstant.SPECIFIC_VEHICLES.COMMERCIAL_TRUCKS,
                QuickFormConstant.SPECIFIC_VEHICLES.HEAVY_DUTY_MACHINERY,
              ])}
              selectedValues={applicationData?.vehicles}
              onSelect={(value) => industryServicesSelect(value)}
              onRemove={(value) => industryServicesSelectRemove(value)}
              displayValue="name"
            />
            <p className="error">
              {errors.vehicles && `${errors.vehicles.message}`}
            </p>
          </div>
        </Col>
        {/* state city and address */}
        <Row>
          <Col lg={6}>
            <Form.Group className="formninputbar" controlId="state">
              <Form.Label>State</Form.Label>

              <Form.Select
                aria-label="Default select example"
                onChange={handleState}
                value={selectedState}
                className="form-control arrow-dropdown">
                <option>State</option>
                {stateListMap?.map((item) => (
                  <option value={item.isoCode} key={item.isoCode}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>

              <p className="error">
                {" "}
                {errors.state && `${errors.state.message}`}
              </p>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="formninputbar" controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  setValue("city", e.target.value);
                }}
                value={selectedCity}
                className="form-control arrow-dropdown">
                <option>City</option>
                {cityList?.map(
                  (item) => (
                    <option value={item.name} key={item.name}>
                      {item.name}
                    </option>
                  )
                )}
              </Form.Select>

              <p className="error">
                {" "}
                {errors.city && `${errors.city.message}`}
              </p>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <Form.Group
              className="formninputbar"
              controlId="certifiedTechnician">
              <Form.Label>
                {isMechanic ? "Certified" : "Certified Technicians"}
              </Form.Label>
              <div className="filter">
                <select {...register("certifiedTechnician")}>
                  <option value="">{_t("Choose Option")}</option>
                  <option value={true}>{_t("Yes")}</option>
                  <option value={false}>{_t("No")}</option>
                </select>
              </div>
            </Form.Group>
          </Col>

          {!isMechanic && (
            <Col lg={6}>
              <Form.Group
                className="formninputbar"
                controlId="numberOfTechnicians">
                <Form.Label>{_t("Number of Technicians")}</Form.Label>
                <Form.Control
                  type="number"
                  {...register("numberOfTechnicians")}
                />
              </Form.Group>
              <p className="error">
                {" "}
                {errors.numberOfTechnicians &&
                  `${errors.numberOfTechnicians.message}`}
              </p>
            </Col>
          )}

          {isMechanic && isCertification && (
            <Col lg={12}>
              <Form.Group
                className="formninputbar"
                controlId="certificationText">
                <Form.Label>{_t("Certification Info")}</Form.Label>
                <Form.Control type="text" {...register("certificationText")} />
              </Form.Group>
              <p className="error">
                {" "}
                {errors.certificationText &&
                  `${errors.certificationText.message}`}
              </p>
            </Col>
          )}
        </Row>

        <Col lg={12}>
          <Form.Group className="formninputbar" controlId="experienceYears">
            <Form.Label>
              {`${!isMechanic ? "Years in Business" : "Years Experience"}  `}
            </Form.Label>
            <div className="filter">
              <select {...register("experienceYears")}>
                {/* <option value="">All</option> */}
                <option value="">Choose Experience</option>
                <option value={QuickFormConstant.USER_EXPERIENCES.UNDER_5}>
                  {QuickFormConstant.USER_EXPERIENCES.UNDER_5}
                </option>

                <option value={QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS}>
                  {QuickFormConstant.USER_EXPERIENCES.FIVE_PLUS}
                </option>

                <option value={QuickFormConstant.USER_EXPERIENCES.TEN_PLUS}>
                  {QuickFormConstant.USER_EXPERIENCES.TEN_PLUS}
                </option>

                <option value={QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS}>
                  {QuickFormConstant.USER_EXPERIENCES.FIFTEEN_PLUS}
                </option>
              </select>
            </div>
          </Form.Group>
          <p className="error">
            {" "}
            {errors.experienceYears && `${errors.experienceYears.message}`}
          </p>
        </Col>

        {!isMechanic && (
          <>
            <Col lg={6}>
              <Form.Group className="formninputbar" controlId="isLicensed">
                <Form.Label>{_t("Licensed and Insured")}</Form.Label>
                <div className="filter">
                  <select {...register("isLicensed")}>
                    <option value="">{_t("Choose Option")}</option>
                    <option value={true}>{_t("Yes")}</option>
                    <option value={false}>{_t("No")}</option>
                  </select>
                </div>
              </Form.Group>
              <p className="error">
                {" "}
                {errors.isLicensed && `${errors.isLicensed.message}`}
              </p>
            </Col>

            <Col lg={6}>
              <Form.Group className="formninputbar" controlId="isOem">
                <Form.Label>{_t("OEM Certified Business")}</Form.Label>
                <div className="filter">
                  <select {...register("isOem")}>
                    <option value={true}>{_t("Yes")}</option>
                    <option value={false}>{_t("No")}</option>
                  </select>
                </div>
              </Form.Group>
              <p className="error">
                {" "}
                {errors.isOem && `${errors.isOem.message}`}
              </p>
            </Col>
          </>
        )}

        <div className="dflex-inputbar form-100">
          <Form.Label>{_t("Applicant Notes  ")}</Form.Label>
          <Form.Group className="formninputbar" controlId="jobDescription">
            <Form.Control
              as="textarea"
              rows={4}
              type="text"
              {...register("notes")}
            />
            <p className="error">
              {" "}
              {errors?.notes && `${errors?.notes.message}`}
            </p>
          </Form.Group>
        </div>
        <Col lg={12}>
          <Form.Group
            className="formninputbar inputbar-btnpink"
            controlId="btnsave">
            {isSubmitting ? (
              <Button className="btn btnorange" type="submit">
                Submiting...
              </Button>
            ) : (
              <Button className="btn btnorange" type="submit">
                Create Profile
              </Button>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default AddApplicationForm;
