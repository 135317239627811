import React from "react";
import useTranslator from "../../../hooks/useTranslator";
const JobsBanner = () => {
  const { _t } = useTranslator();
  return (
    <>
      <section className="contact-banner BlogBanner jobbanner">
        <div className="container">
          <div className="blog-head">
            <h2>{_t("Open Remote Service Opportunities")}</h2>
            <div class="bar-line"></div>
            <p>
              {_t(
                "Join the modernization of the mechanic and service technician industry, Find a Remote Opportunity Near you."
              )}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobsBanner;
