import { useState, useEffect } from "react";
import {
  categoryList,
  deleteBlog,
  getAllBlogs,
  setPrimaryBlog,
} from "../api-services/blog";
import toast from "react-hot-toast";
import { requestHandler } from "../utils/comman";
import { uniqueValueArray } from "../utils/helpers";

const useBlogs = () => {
  const [blogsData, setBlogData] = useState([]);
  const [status, setStatus] = useState("idle"); // loading | success | error
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [primaryBlog, setPrimary] = useState(null);

  const fetchData = () => {
    setStatus("loading");
    getAllBlogs({ page, category: selectCategory })
      .then((res) => {
        if (res.status === "success") {
          setStatus("success");
          setBlogData(res?.data?.blogs?.docs);
          setPrimary(res?.data?.primaryBlog);

          setTotalDocs(res.data?.blogs?.totalDocs);
        }
      })
      .catch((error) => {
        setStatus("error");
      })
      .finally(() => setStatus("success"));
  };

  const updateBlogs = (data) => {
    setStatus("loading");
    updateBlogs(data)
      .then((updateRes) => {
        if (updateRes.status === "success") {
          setStatus("success");
          toast.success("update successfully blog");
          fetchData();
        }
      })
      .catch((err) => {
        setStatus("error");
        toast.error(err.message);
      })
      .finally(() => setStatus("idle"));
  };

  const fetchCategory = () => {
    requestHandler(
      async () => await categoryList(),
      setStatus,
      (res) => {
        const { data } = res;
        setCategoriesList(data);
      },
      (error) => {
        console.log("error during fetching category list", error);
      }
    );
  };

  useEffect(() => {
    fetchCategory();
    fetchData();
  }, [page, selectCategory]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const isarchived = (blogId) => {
    const cnf = window.confirm("are you sure for archived blog");
    if (cnf) {
      requestHandler(
        async () => await deleteBlog({ blogId }),
        null,
        (res) => {
          toast.success("blog is archived successfully");
          fetchData();
        },
        (error) => {
          toast.error(error);
        }
      );
    }
  };
  const setFeatured = (id) => {
    const cnf = window.confirm("are you sure for featured blog");
    if (cnf) {
      requestHandler(
        async () => await setPrimaryBlog({ id }),
        null,
        (res) => {
          toast.success("blog is featured set successfully");
          fetchData();
        },
        (error) => {
          toast.error(error);
        }
      );
    }
  };

  const filterCategory = (value) => {
    if (value === "All") {
      value = null;
    }
    setSelectCategory(value);
  };

  return {
    blogsData,
    categoriesList,
    status,
    updateBlogs,
    handlePageChange,
    isarchived,
    totalDocs,
    page,
    filterCategory,
    setFeatured,
    primaryBlog,
  };
};

export { useBlogs };
