import React from "react";
import BackButton from "../../../Components/BackToPrev";
import { Row, Col, Card } from "react-bootstrap";
import { useBlogs } from "../../../data-fetch/useBlogs";
import Loader from "../../../Components/Loader";import { MdAddCircleOutline, MdAddTask, MdCreate, MdSendAndArchive } from "react-icons/md";
import { Link } from "react-router-dom";
import Paginate from "../../../Components/Paginate";

const BlogAdminPage = () => {
  const {
    blogsData,
    status,
    handlePageChange,
    totalDocs,
    page,
    isarchived,
    setFeatured,
   
  } = useBlogs();

  if (status === "loading") {
    return <Loader />;
  }

  return (
    <div className="account-settings mt-4">
      <div className="top-header">
        <div className="page-name title-with-backbtn mb-10">
          <BackButton />
          <h4>Blogs</h4>
        </div>

        <Link to="/admin/blog/add" className="btn btnorange">
          <MdAddCircleOutline /> Add Blog
        </Link>
      </div>
      {blogsData.length && (
        <div className="users-table mt-3">
          <Row xs={1} md={1} className="g-4 mb-4">
            {blogsData?.length &&
              blogsData?.map((blog, idx) => (
                <Col key={idx}>
                  <Card bg="dark">
                    <Card.Body>
                      <Card.Title>{blog?.title}</Card.Title>
                      <Card.Text>
                        <p>{blog?.introduction}</p>
                        <div className="btngroup btngrop-leftalign mt-3">
                          <Link
                            to={`/admin/blog/${blog._id}?edit=${true}`}
                            className="btn btnorange">
                            <MdCreate />
                            Edit
                          </Link>
                          <button
                            className="btn btnorange"
                            onClick={() => isarchived(blog._id)}>
                            <MdSendAndArchive />
                            Archived
                          </button>
                          {blog?.isPrimary ? (
                            <button className="btn btnorange">
                              <MdAddTask />
                              Featured
                            </button>
                          ) : (
                            <button
                              className="btn btnorange"
                              onClick={() => setFeatured(blog._id)}>
                              <MdAddTask />
                              Set Featured
                            </button>
                          )}
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
          <div className="d-flex align-items-center justify-content-center">
            <Paginate
              total={totalDocs}
              onChange={handlePageChange}
              page={page}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogAdminPage;
