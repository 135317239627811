import React from "react";
import LoginForm from "../../Components/Forms/LoginForm";

const Login = () => {
  return (
    <>
      <div className="aboutbanner loginbaner">
        <div className="container">
          <h2>Login</h2>
          <div class="bar-line"></div>
          <div className="auth-form">
            <div className="container">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
