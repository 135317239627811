import React from "react";
import useTranslator from "../../../hooks/useTranslator";
import { haveValue } from "../../../utils/helpers";

const JobResponsibility = ({ responsibility }) => {
  const { _t } = useTranslator();
  if (responsibility) {
    return (
      <ul className="listing-ul">
        {responsibility?.map((info) => (
          <li>{_t(info?.name)}</li>
        ))}
      </ul>
    );
  }
};

export default JobResponsibility;
