import sendRequest from "../providers/axios";
import Routes from "./routes";

export const createJobCategory = async (data) => {
  const response = await sendRequest(Routes.jobCategory.create, "POST", data);

  return response;
};
export const updateJobCategory = async (data) => {
  const response = await sendRequest(Routes.jobCategory.update, "PUT", data);

  return response;
};
export const deleteJobCategory = async (data) => {
  const response = await sendRequest(Routes.jobCategory.delete, "DELETE", data);

  return response;
};
export const getAllJobCategory = async (data) => {
  const response = await sendRequest(Routes.jobCategory.getAll, "GET", data);
  return response;
};

export const selectAllCategory = async (data) => {
  const response = await sendRequest(Routes.jobCategory.selectAll, "GET", data);
  return response;
};
