import React from 'react';
import useTranslator from "../../../hooks/useTranslator";
const AboutListing = () => {
    const { _t } = useTranslator();
    return (
        <>
            <section className='about-listing'>
                <div className='container'>
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/dough.svg" alt='icon'/>
                            <h6>{_t("Multiply Your Money: Elevate Your Earnings in Your Free Time")}</h6>
                        </div>
                        <p className='content-p'>{_t("Discover the potential for extra income without sacrificing your current job.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Effortless Earning: Increase your financial stability effortlessly during your spare time.")}</li>
                            <li>{_t("Dual Income Dynamo: Embrace the power of supplementary earnings for a wealthier future.")}</li>
                            <li>{_t("Financial Freedom Awaits: Explore new possibilities and watch your income soar.")}</li>
                        </ul>
                    </div>
                    <div className='listingbar  '>
                        <div className='topbar-title'>
                            <img src="/images/callshot.svg" alt='icon'/>
                            <h6>{_t("You’re In Charge: Tailor Your Work, Tailor Your Pay!")}</h6>
                        </div>
                        <p className='content-p'>{_t("Seize control of both your schedule and income with unparalleled flexibility.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Flex-Freedom: Enjoy the liberty to shape your work around your lifestyle.")}</li>
                            <li>{_t("Income Autonomy: Control your financial destiny with a schedule and rate that suits you.")}</li>
                            <li>{_t("Personal Profit Paradigm: Redefine your income model on your own terms.")}</li>
                            <li>{_t("Your Earnings, Your Rules: Break free from traditional work constraints and dictate your financial goals.")}</li>
                        </ul>
                    </div>
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/techmarven.webp" alt='icon'/>
                            <h6>{_t("Tech Marvel Entrepreneurship: Forge Your Empire with Our Innovations!")}</h6>
                        </div>
                        <p className='content-p'>{_t("Transform your aspirations into reality by establishing your own business using our state-of-the-art technology.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Innovation Incubator: Nurture your entrepreneurial dreams with cutting-edge tech solutions.")}</li>
                            <li>{_t("Entrepreneurial Edge: Gain a competitive advantage by harnessing our advanced technological tools.")}</li>
                            <li>{_t("Future-Forward Business: Shape the future of your industry by integrating our cutting-edge solutions.")}</li>
                        </ul>
                    </div>
                    <div className='listingbar  '>
                        <div className='topbar-title'>
                            <img src="/images/pocket.svg" alt='icon'/>
                            <h6>{_t("Pocket-Sized Profits: Connect with Thousands Anytime, Anywhere!")}</h6>
                        </div>
                        <p className='content-p'>{_t("Convert your phone into a revenue-generating powerhouse, effortlessly reaching a vast customer base.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Mobile Money Machine: Turn your smartphone into a lucrative source of income.")}</li>
                            <li>{_t("Nationwide Reach: Seamlessly connect with customers around the country from the palm of your hand.")}</li>
                            <li>{_t("Anytime, Anywhere Earnings: Enjoy the flexibility of making profits on your own terms.")}</li>
                            <li>{_t("Effortless Expansion: Expand your business reach effortlessly through the convenience of your mobile device.")}</li>
                        </ul>
                    </div>
                    <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/skillss.webp" alt='icon'/>
                            <h6>{_t("Monetize Your Skills: And Get Paid Well!")}</h6>
                        </div>
                        <p className='content-p'>{_t("Optimize your earning potential with rates tailored to your services, ranging from $25 to $180 per hour.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Tailored Rates, Maximum Returns: Customize your earning potential based on your expertise.")}</li>
                            <li>{_t("Lucrative Expertise: Monetize your knowledge and skills at competitive rates.")}</li>
                            <li>{_t("Personalized Pay Scale: Set your own value and watch your income soar with tailored hourly rates.")}</li>
                        </ul>
                    </div>
                    <div className='listingbar  '>
                        <div className='topbar-title'>
                            <img src="/images/Revolutionize.svg" alt='icon'/>
                            <h6>{_t("Revitalize Your Career: Spearhead the Remote Mechanic Tech Evolution!")}</h6>
                        </div>
                        <p className='content-p'>{_t("Become a part of a groundbreaking technological movement at its inception and revolutionize your industry.")}</p>
                        <ul className='listing-ul'>
                            <li>{_t("Industry Game-Changer: Lead the way in reshaping your field with revolutionary technology.")}</li>
                            <li>{_t("Tech Evolution Pioneer: Be at the forefront of technological advancements driving industry change.")}</li>
                            <li>{_t("Groundbreaking Growth: Experience career growth and innovation by being part of something transformative and monumental.")}</li>
                            
                        </ul>
                    </div>
                    {/* <div className='listingbar bg-gray '>
                        <div className='topbar-title'>
                            <img src="/images/profithand.svg" alt='icon'/>
                            <h6>{_t("")}</h6>
                        </div>
                        <p className='content-p'>{_t("")}</p>
                        <ul className='listing-ul'>
                            
                            
                            
                            
                        </ul>
                    </div>  */}
                </div>
            </section>
        </>
    );
}

export default AboutListing;
