import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { sendMailOutReach } from "../../api-services/application";
import toast from "react-hot-toast";
import useTranslator from "../../hooks/useTranslator";
import { sendOutReactMails } from "../../api-services/email";

const SentMailModel = ({ setShowModel, show, fetchInfo }) => {
  const { _t } = useTranslator();
  const { email } = useParams();
  const applicantName = window.location.search.split("=")[1];
  console.log("🚀 ~ SentMailModel ~ applicantName:", applicantName);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
      applicantName: "",
      content: "",
      subject: "",
    },
    mode: "onSubmit",
  });

  const handleClose = () => setShowModel(false);

  const OnSubmit = (data) => {
    data = { ...data, email, applicantName };
    setLoading(true);
    sendOutReactMails(data)
      .then((mailDataRes) => {
        if (mailDataRes.status === "success") {
          toast.success("mail send successfully");
          setShowModel(false);
          fetchInfo(email);
          reset();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onError = (errors) => console.log("error", errors);

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} className="darkmode-modal">
      <Form onSubmit={handleSubmit(OnSubmit, onError)}>
        <Modal.Header>
          <Modal.Title>{`Send Mail to ${applicantName} `}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="userrole-detail-modal accountform ">
            <Form.Control
              required
              type="text"
              {...register("subject", {
                required: {
                  value: true,
                  message: "fill subject is required",
                },
              })}
              placeholder={_t("write your subject here....")}
            />
            <Form.Control
              required
              as="textarea"
              rows={8}
              type="text"
              {...register("content", {
                required: {
                  value: true,
                  message: "fill content is required",
                },
              })}
              placeholder="write your content here...."
            />
          </div>
          <p className="error"> {errors?.content && errors.content.message}</p>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <button className="btn btnwhite" type="button">
              Sending Mail...
            </button>
          ) : (
            <button className="btn btnwhite" type="submit">
              Send
            </button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SentMailModel;
