import sendRequest from "../providers/axios";
import Routes from "./routes";

export const categoriesApplication = async (data) => {
  const response = await sendRequest(
    Routes.dashboardRoutes.categoryGroup,
    "GET",
    data
  );
  return response;
};
export const getAdminSetting = async (data) => {
  const response = await sendRequest(
    Routes.dashboardRoutes.getAdminSetting,
    "GET",
    data
  );
  return response;
};
export const updateAdminSetting = async (data) => {
  const response = await sendRequest(
    Routes.dashboardRoutes.updateSetting,
    "PUT",
    data
  );
  return response;
};
const sitemapUrlList = async (data) => {
  const response = await sendRequest(Routes.siteMap.get, "GET", data);
  return response;
};

