import React from "react";
import Carousel from "react-elastic-carousel";
import useTranslator from "../../../hooks/useTranslator";
import Item from "./Item";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 767, itemsToShow: 2 },
  { width: 1024, itemsToShow: 3 },
];

const WhyApplySlider = () => {
  const { _t } = useTranslator();

  return (
    <>
      <Carousel breakPoints={breakPoints}>
        <Item>
          <div className="slidebox">
            <img src="/images/dough.svg " alt="icon1" />
            <h4>
              {_t("Double Your Dough: Boost Your Income in Your Spare Time")}
            </h4>
            <p>
              {_t(
                "Unleash the power of supplemental earnings without giving up your day job."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/callshot.svg" alt="icon2" />
            <h4>
              {_t("You’re In Charge: Tailor Your Work, Tailor Your Pay!")}
            </h4>
            <p>
              {_t(
                "Seize control of both your schedule and income with unparalleled flexibility."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/techmarven.webp" alt="icon3" />
            <h4>
              {_t("Tech-preneurship: Forge Your Empire with Our Innovations!")}
            </h4>
            <p>
              {_t(
                "Transform your aspirations into reality by establishing your own business using our state-of-the-art technology."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/pocket.svg " alt="icon4" />
            <h4>
              {_t(
                "Pocket-Sized Profits: Connect with Thousands Anytime, Anywhere!"
              )}
            </h4>
            <p>
              {_t(
                "Convert your phone into a revenue-generating powerhouse, effortlessly reaching a vast customer base."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/skillss.webp" alt="icon4" />
            <h4>{_t("Monetize Your Skills: And Get Paid Well!")}</h4>
            <p>
              {_t(
                "Optimize your earning potential with rates tailored to your services, ranging from $25 to $180 per hour."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/Revolutionize.svg" alt="icon5" />
            <h4>
              {_t(
                "Revitalize Your Career: Spearhead the Remote Mechanic Tech Evolution!"
              )}
            </h4>
            <p>
              {_t(
                "Become a part of a groundbreaking technological movement at its inception and revolutionize your industry."
              )}
            </p>
          </div>
        </Item>
      </Carousel>
    </>
  );
};

export default WhyApplySlider;
