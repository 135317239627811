import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/Slices/authSlice";
import { loginService } from "../../api-services";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../providers/localStorage";
import toast from "react-hot-toast";
import useTranslator from "../../hooks/useTranslator";

const LoginForm = () => {
  const { _t } = useTranslator();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      password: yup.string().required(),
      email: yup.string().email().required(),
    })
    .required();

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      // Submit the form data

      loginService(values)
        .then((data) => {
          if (data.status === "success") {
            setLocalStorageItem("token", data.data.token);
            toast.success("Login Successfully");
            dispatch(login(values));
            navigate("/admin");
            // window.location.reload();
          }
        })
        .catch((err) => alert(err.message));
    },
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{_t("Email address")}</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Form.Text className="text-muted">
            {_t(" We'll never share your email with anyone else.")}
          </Form.Text>
          {formik.errors.email && (
            <p className="error">{_t("Email is required")}</p>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>{_t("Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Group>
        {formik.errors.password && (
          <p className="error">{_t("Password is required")}</p>
        )}
        <Button className="btn btnorange" type="submit">
          {_t("Submit")}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
