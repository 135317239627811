import React, { useRef, useState } from "react";
import useTranslator from "../../../hooks/useTranslator";
import Multiselect from "multiselect-react-dropdown";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";
import { useForm } from "react-hook-form";
import yup from "yup";
import toast from "react-hot-toast";
import { shortApplicationApply } from "../../../api-services/application";
import { haveValue } from "../../../utils/helpers";
import Select from "react-select";



const ResumeUpload = () => {
  const [selectedValues, setSelectedValues] = useState([]);
  const { jobCategories, error } = useFetchJobCategory();
  const [resumeError, setResumeError] = useState(null);
  const [selectedValueError, setSelectedValueError] = useState(null);
  const selectInputRef = useRef();

  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: "onSubmit",
  });

  const handleChange = (e) => {
    console.log("e", e);
    const value = e.map((val) => val.value).join(",");
    setSelectedValues(value);
  };

  console.log("selectedValues",selectedValues)

  const onSubmit = (data) => {
    const formData = new FormData();

    if (data && data.resume[0]) {
      formData.append("resume", data.resume[0]);
    }
   

    if (selectedValues.length === 0) {
      setSelectedValueError("please select value from category from dropdown");
      return;
    }

    formData.append("jobCategory", selectedValues);

    shortApplicationApply(formData)
      .then((applicationSubmit) => {
        if (applicationSubmit.status === "success") {
          toast.success(_t(applicationSubmit.message));
          reset();
          setSelectedValues("");
          setSelectedValueError(null);
          onClear()
          
        }
      })
      .catch((error) => {
        toast.error(_t(error?.message));
      });
  };

  const onError = (errors, e) => console.log(errors, "Error", e);
  const jobNicheOptions = jobCategories.map((item) => ({
    ...item,
    label: item.name,
  }));

  const { _t } = useTranslator();
  return (
    <>
      <div className="let-talk-block uploadresumeblock">
        <div className="container">
          <div className="letstalk-inner">
            <div className="uploadingarea">
              <h3>{_t("No time to apply? Just Submit your resume")}</h3>
              <div class="bar-line"></div>
              <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className="reumeupload-form">
                  <div className="multiselect-bar">
                    <h5 className="formtitle">
                      {_t("Select all industries you serve.")}
                    </h5>
                    {/* <Multiselect
                      options={jobCategories}
                      onKeyPressFn={(e) => e.preventDefault()}
                      isSearchable={false}
                      selectedValues={selectedValues}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                    /> */}
                    <Select
                      isMulti
                      ref={selectInputRef}

                      name="jobNiche"
                      options={jobNicheOptions}
                      isSearchable={false}
                      className="basic-multi-select"
                      // menuIsOpen={true}

                      classNamePrefix="select"
                      onChange={handleChange}
                    />

                    {selectedValueError && (
                      <p className="error">{selectedValueError}</p>
                    )}
                  </div>

                  <div className="inputfile">
                    <h5 className="formtitle">
                      {_t("Please upload the resume")}
                    </h5>
                    <input
                      class="form-control "
                      type="file"
                      accept=".doc,.docx,.pdf,.png,.jpeg,.xml"
                      {...register("resume", {
                        required: "Please upload your resume.",
                      })}
                    />
                    <p>{_t("Max File size 2mb")}</p>
                    {errors && errors?.resume && (
                      <p className="error"> {errors?.resume?.message}</p>
                    )}
                  </div>
                </div>
                <div className="btnsubmit">
                  <button className="btn btnorange" type="submit">
                    {_t("Submit")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeUpload;
