import React, { memo, useState } from "react";
import useTranslator from "../hooks/useTranslator";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdAttachEmail, MdLocationPin,  MdOutlinePeople } from "react-icons/md";
import Paginate from "./Paginate";


const DashboardCategoryTable = ({ data }) => {
  const [page, setPage] =useState(1)
  const itemsPerPage = 10;

  const pageHandler = (value)=>{
    setPage(value)
  }

  const { _t } = useTranslator();
  if (data?.length > 0) {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = data.slice(startIndex, endIndex);

    return (
      <div>
        <div className="users-table">
          <Table responsive>
            <thead>
              <tr>
                <th>{_t("Name")}</th>
                <th>{_t("Email")}</th>
                <th>{_t("Phone")}</th>
                <th>{_t("Location")}</th>
                <th>{_t("Job Niche")}</th>
                <th>{_t("Category")}</th>
                <th>{_t("Status")}</th>
                {/* <th>{_t("document")}</th> */}
                <th>{_t("Action")}</th>
                <th>{_t("Communication")}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((application, index) => (
                <tr key={index}>
                  <td>
                    <div className="user-details">
                      <div>
                        <p className="user-name">{application?.name}</p>
                      </div>
                    </div>
                  </td>

                  <td>{application?.email}</td>
                  <td className="red-text">
                    {application?.mobileNo
                      ? application?.mobileNo
                      : "N/A"}
                  </td>
                  <td className="red-text">
                    <MdLocationPin />{" "}
                    {application?.city ? `${application?.city},` : null}{" "}
                    {application?.state}
                  </td>
                  <td className="red-text">{application?.category}</td>
                  <td className="red-text">{application?.job?.jobCategory}</td>
                  <td className="red-text">{application?.status}</td>

                  <td>
                    <Link to={`/applicant/${application?._id}`}>
                      <td className="red-text">
                        <MdOutlinePeople className="ml-2" />
                        {_t("View profile")}
                      </td>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/applicant/mails/${application?._id}`}>
                      <td className="red-text">
                        <MdAttachEmail className="ml-2" />{" "}
                        {_t("communications")}
                      </td>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Paginate total={data.length} onChange={pageHandler} page={page} />
        </div>
      </div>
    );
  }
};

export default memo(DashboardCategoryTable);
