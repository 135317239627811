import React from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useBlogs } from "../../../data-fetch/useBlogs";
import Loader from "../../Loader";
import { formatDateWithoutTime, urlEncode } from "../../../utils/helpers";
import { blogCoverImageUrl } from "../../../config";
const AllBlog = () => {
  const { blogsData, status, categoriesList, filterCategory } = useBlogs();

  if (status === "loading") {
    return <Loader />;
  }

  return (
    <section className="allblogs">
      <div className="container">
        <Tabs
          defaultActiveKey="All"
          id="justify-tab-example"
          className="blogtab"
          onSelect={(selectedKey) => filterCategory(selectedKey)}
          justify>
          <Tab
            className="tab-empty"
            eventKey="Filter by :"
            title="Filter by :"
            disabled></Tab>
          <Tab eventKey="All" title="All">
            {blogsData?.length > 0 &&
              blogsData?.map((blog, index) => (
                <div className="bloglisting" key={index}>
                  <div className="blog-title">
                    <div className="title-tophead">
                      <div className="btn btngray">
                        {blog?.category || "Innovations"}
                      </div>
                      <h6>
                        <img src="/images/calender.webp" alt="icon" />
                        {formatDateWithoutTime(blog?.createdAt)}
                      </h6>
                    </div>
                    <h5>{blog?.title}</h5>
                    <p>{blog?.introduction}</p>
                    <div className="blogbtn">
                      <Link
                        className="btn btnorange"
                        to={`/blog/${urlEncode(blog?.title)}`}>
                        Learn More
                      </Link>
                    </div>
                  </div>
                  <div className="blog-content-detail">
                    <img
                      src={`${blogCoverImageUrl}/${blog?.coverImage}`}
                      alt="blog"
                    />
                  </div>
                </div>
              ))}
          </Tab>

          {categoriesList?.map((category) => (
            <Tab
              eventKey={category.value}
              title={category.value}
              // onClick={filterCategory(category.value)}
            >
              {blogsData.length &&
                blogsData?.map((blog, index) => (
                  <div className="bloglisting" key={index}>
                    <div className="blog-title">
                      <div className="title-tophead">
                        <div className="btn btngray">
                          {blog?.category || "Innovations"}
                        </div>
                        <h6>
                          <img src="/images/calender.webp" alt="icon" />
                          {formatDateWithoutTime(blog?.createdAt)}
                        </h6>
                      </div>
                      <h5>{blog?.title}</h5>
                      <p>{blog?.introduction}</p>
                      <div className="blogbtn">
                        <Link
                          className="btn btnorange"
                          to={`/blog/${urlEncode(blog?.title)}`}>
                          Learn More
                        </Link>
                      </div>
                    </div>
                    <div className="blog-content-detail">
                      <img
                        src={`${blogCoverImageUrl}/${blog?.coverImage}`}
                        alt="blog"
                      />
                    </div>
                  </div>
                ))}
            </Tab>
          ))}
        </Tabs>
      </div>
    </section>
  );
};

export default AllBlog;
