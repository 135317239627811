import axios from "axios";
import config from "../config";
import sendRequest from "../providers/axios";
import Routes from "./routes";

const baseUrl = config.server.url;
export const imageBaseUrl = config.server.imageBaseUrl;

export const loginService = async (data) => {
  const response = await sendRequest(Routes.auth.loginUser, "POST", data);
  return response;
};

export const getCurrentUser = async () => {
  const response = await sendRequest(Routes.auth.currentUser, "GET");
  return response;
};

export const logout = async () => {
  const response = await sendRequest(Routes.auth.logout, "GET");
  return response;
};
export const adminRegister = async (data) => {
  const response = await sendRequest(Routes.auth.adminRegister, "POST", data);
  return response;
};
export const resetPassword = async () => {
  const response = await sendRequest(Routes.auth.resetPassword, "POST");
  return response;
};
