import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Languagebar from "./Languagebar";
import Logo from "./logo";
import MainNavbar from "./MainNavbar";
import classnames from "classnames";
import { Link } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

const MainHeader = (props) => {
  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const { _t } = useTranslator();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // You can adjust the scroll threshold as needed
      const isSticky = scrollPosition > 100;

      setHeaderSticky(isSticky);
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures that this effect runs once on mount

  const headerClasses = classnames("MainHeader", {
    "sticky-header": isHeaderSticky,
  });
  return (
    <>
      <div className={headerClasses}>
        <Navbar expand="lg" className="navbar-primary">
          <Container>
            <Navbar.Brand href="/">
              <Logo />
            </Navbar.Brand>
            <MainNavbar />
            <div className="rightbar-nav"> 
              <Languagebar />
              <Link to="/login" className="btn btnwhite">
                {_t("Login")}
              </Link>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default MainHeader;
