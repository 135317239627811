import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col, Table } from "react-bootstrap";

import useTranslator from "../../hooks/useTranslator";
import { capitalizedString, formatDateWithoutTime } from "../../utils/helpers";
import toast from "react-hot-toast";

const JobHistory = ({ show, setShowHistory, data }) => {
  const { _t } = useTranslator();

  const handleClose = () => setShowHistory(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="darkmode-modal">
        <Modal.Header closeButton>
          <Modal.Title>{_t("Job History")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="users-table history-job">
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Action</th>
                  <th>Update By</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((hist, index) => (
                  <tr key={index}>
                    <td>{formatDateWithoutTime(hist.date)}</td>
                    <td>{capitalizedString(hist.action)}</td>
                    <td>{capitalizedString(hist.updateBy?.name)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobHistory;
