import React, { useEffect, useState } from "react";
import BlogDetailBanner from "../../Components/PagesAssets/Blog/BlogDetailBanner";
import OtherArticles from "../../Components/PagesAssets/Blog/OtherArticles";
import { Row } from "react-bootstrap";
import useBlogInfo from "../../data-fetch/useBlogInfo";
import { useParams } from "react-router-dom";
import { getRandomBlogItems, urlDecode } from "../../utils/helpers";
import Loader from "../../Components/Loader";
import { requestHandler } from "../../utils/comman";
import { categoryBlogs } from "../../api-services/blog";
import { Helmet } from "react-helmet";
import { blogCoverImageUrl } from "../../config";

const BlogDetail = () => {
  const { id } = useParams();
  const title = urlDecode(id);

  const [categoryBlogData, setCategoryBlog] = useState([]);

  const { dataBlog: blogData, loading, error } = useBlogInfo({ title });

  const categoryInfo = () => {
    return requestHandler(
      async () =>
        await categoryBlogs({
          category: blogData?.category,
          title: blogData?.title,
        }),
      null,
      (res) => {
        const { data } = res;
        setCategoryBlog(data);
      },
      (error) => {
        console.log("🚀 ~ categoryInfo ~ error:", error);
      }
    );
  };

  useEffect(() => {
    if (blogData?.category) {
      categoryInfo();
    }
  }, [blogData?.category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Helmet>
        <title>{blogData?.title}</title>
        {/* Set the canonical URL */}
        <link
          rel="canonical"
          href={`https://www.remotemechanicjobs.com/blog/${urlDecode(
            blogData?.title
          )}`}
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href={`https://www.remotemechanicjobs.com/en-us/blog/${urlDecode(
            blogData?.title
          )}`}
        />
        <link
          rel="alternate"
          hreflang="es-es"
          href={`https://www.remotemechanicjobs.com/es-es/blog/${urlDecode(
            blogData?.title
          )}`}
        />
        {/* Set the meta description */}
        <meta name="description" content={blogData?.introduction} />
        <meta name="keywords" content={blogData?.keywords} />
      </Helmet>
      <BlogDetailBanner data={blogData} />
      <div className="blogdetail-content">
        <div className="container">
          <div className="featured-img">
            <img
              src={`${blogCoverImageUrl}/${blogData?.coverImage}`}
              alt="img"
            />
          </div>
          <div className="blogdetail-wrapper">
            <p className="introduction">{blogData?.introduction}</p>
            <div
              className="blog-content"
              dangerouslySetInnerHTML={{ __html: blogData?.content }}
            />
            <div></div>

            <div className="spacer-50"> </div>
            <div className="introduction">{blogData?.summary}</div>
          </div>
        </div>
        <div className="articles-block">
          <div className="container">
            <h3>Discover our other articles</h3>
            <Row>
              <OtherArticles data={categoryBlogData} />
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
