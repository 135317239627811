import React, { useEffect } from 'react';
import ContactBanner from '../../Components/PagesAssets/ContactUs/ContactBanner';
import ContactFormSection from '../../Components/PagesAssets/ContactUs/ContactFormSection';

const Contact = (props) => {
    
    return (
        <>
            <div className="Contactbar">
                <ContactBanner/>
                <ContactFormSection/>
            </div>
        </>
    );
}

export default Contact;