import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorageItem } from "../../providers/localStorage";

const initialState = {
  userData: {},
  status: getLocalStorageItem("token") ? true : false,
  role: null,
  usrRole: localStorage.getItem("usrRole") ?? "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.userData = action.payload;
      if (action.payload) {
        state.status = true;
      }
    },
    logout: (state) => {
      state.userData = null;
      state.status = false;
      localStorage.clear();
    },

    setUserPreferences: (state, action) => {
      state.usrRole = action.payload;
      localStorage.setItem("usrRole", action.payload);
    },
  },
});

export const { login, logout, setUserPreferences } = authSlice.actions;

export default authSlice.reducer;
