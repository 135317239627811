import React, { Suspense, useEffect } from "react";
import UserRole from "../../Components/Modals/UserRole";
import CareerOpportunityDivider from "../../Components/PagesAssets/Home/CareerOpportunity_divider";
import HomeBanner from "../../Components/PagesAssets/Home/HomeBanner";
import KeyNumber from "../../Components/PagesAssets/Home/KeyNumber";
import Letstalk from "../../Components/PagesAssets/Home/Letstalk";
import LookingFor from "../../Components/PagesAssets/Home/LookingFor";
import LookingForasBusiness from "../../Components/PagesAssets/Home/LookingForasBusiness";
import WhyApply from "../../Components/PagesAssets/Home/WhyApply";
import WhyApplyasbusiness from "../../Components/PagesAssets/Home/WhyApplyasbusiness";
import QuickApply from "./../../Components/PagesAssets/Home/QuickApply";
import { Helmet } from "react-helmet";
import Loader from "../../Components/Loader";

const Home = () => {
  const userRole = localStorage.getItem("usrRole");

  const hash = window.location.hash;

  useEffect(() => {
    if (!hash.startsWith("#formapply")) {
      window.scrollTo(0, 0);
    }
  }, [hash, userRole]);
  return (
    <>
      <Helmet>
        <title>Remote Mechanic Jobs </title>
        {/* Set the canonical URL */}
        <link rel="canonical" href={`https://www.remotemechanicjobs.com`} />
        <meta
          name="description"
          content="The same vehicle and equipment maintenance industry , with some upgraded service options. Offering Remote Services provides you with an entirely new revenue stream at your own pace."
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href="https://www.remotemechanicjobs.com/en-us"
        />
        <link
          rel="alternate"
          hreflang="es-es"
          href="https://www.remotemechanicjobs.com/es-es"
        />
      </Helmet>

      <div className="homebar">
        <HomeBanner />
        {userRole === "mechanic" ? <WhyApply /> : <WhyApplyasbusiness />}
        <KeyNumber />
        {userRole === "mechanic" ? <LookingFor /> : <LookingForasBusiness />}
        <CareerOpportunityDivider />
        <QuickApply />
        <Letstalk />
        {!userRole && <UserRole />}
      </div>
    </>
  );
};

export default Home;
