import React, { useState, memo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useTranslator from "../../../hooks/useTranslator";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Pie from "../../Chart/Pie";
const KeyNumber = () => {
  const { _t } = useTranslator();
  const [counterStart, setCounterStart] = useState(false);
  return (
    <div className="KeyNumber">
      <div className="container">
        <div className="primary-title">
          <h4>{_t("Key numbers")}</h4>
          <h2>{_t("2023 Remote Work Statistics")}</h2>
          <div className="bar-line"></div>
        </div>
        <ScrollTrigger
          onEnter={() => setCounterStart(true)}
          onExit={() => setCounterStart(false)}>
          <Row className="">
            <Col className="onecard" md={6} lg={3} sm={6} xs={6}>
              <div className="countNumber">
                <h3 className="piebar">
                  {counterStart && (
                    <CountUp start={0} duration={2} end={12.7} />
                  )}{" "}
                  %
                </h3>

                <h5>{_t("of full-time employees work from home")}.</h5>
              </div>
            </Col>

            <Col className="twocard" md={6} lg={3} sm={6} xs={6}>
              <div className="linebarbox countNumber">
                <Pie count={28} />
                
                <h5>
                  {_t("of employees have adapted to a hybrid work model")}
                </h5>
              </div>
            </Col>
            <Col className="custom-top threecard" md={6} lg={3} sm={6} xs={6}>
              <div className="countNumber ">
                <h3  className="piebar">
                  {counterStart && <CountUp start={0} duration={2} end={22} />}%
                </h3>
                <h5>
                  {_t(
                    "By 2025, 32.6 million Americans will work remote by 2025, about 22% of the workforce"
                  )}
                  .
                </h5>
              </div>
            </Col>

            <Col className=" fourcard" md={6} lg={3} sm={6} xs={6}>
              <div className="linebarbox countNumber">
                <Pie count={5} />
                <h5>
                  {_t(
                    "While Only 5% of service technicians and mechanics work remotely"
                  )}
                </h5>
              </div>
            </Col>
          </Row>
        </ScrollTrigger>
      </div>
    </div>
  );
};

export default memo(KeyNumber);
