import React, { memo } from "react";
import WhyApplySlider from "../WhyApplySlider";
import useTranslator from "../../../hooks/useTranslator";
import { Link } from "react-router-dom";

const WhyApply = () => {
  const { _t } = useTranslator();
  return (
    <div className="whyapply">
      <div className="container">
        <div className="primary-title">
          <h4>{_t("Why apply?")}</h4>
          <h2>{_t("Benefits for Mechanics")}</h2>
          <div className="bar-line"></div>
        </div>
        <WhyApplySlider />
        <div className="btngroup">
          <Link to="/#formapply" className="btn btnorange">
            {_t("Apply Now")}{" "}
          </Link>
          <Link to="/about/#user-benefit" className="btn btnwhite">
            {_t("See Details")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WhyApply;
