import React, { useEffect, useState } from "react";
import JobsBanner from "../../Components/PagesAssets/Jobs/JobsBanner";
import Search from "../../Components/Filter/Search";
import LocationFilter from "./../../Components/Filter/LocationFilter";
import JobsFilter from "./../../Components/Filter/JobsFilter";
import ContractFilter from "./../../Components/Filter/ContractFilter";
import JobListing from "../../Components/PagesAssets/Jobs/JobListing";
import Spontaneousbar from "./../../Components/PagesAssets/Jobs/Spontaneousbar";
import { getUserJobs } from "../../api-services";
import { useDispatch, useSelector } from "react-redux";
import {
  LocalLang,
  debounce,
  getUniqeStateAndCity,
  selectedRole,
} from "../../utils/helpers";
import useTranslator from "../../hooks/useTranslator";
import ResumeUpload from "../../Components/PagesAssets/Jobs/ResumeUpload";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";

const AllJobs = () => {
  const hash = window.location.hash;

  useEffect(() => {
    if (hash !== "#applyform") {
      window.scrollTo(0, 0);
    }
  }, [hash]);

  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [jobsList, setJobsList] = useState([]);
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(false);

  const webLanguage = LocalLang() === "es-es" ? "Spanish" : "English" ;
  console.log("🚀 ~ AllJobs ~ webLanguage:", webLanguage)

  const userRole = selectedRole();

  const { _t } = useTranslator();

  const debounceList = debounce(() => {
    setLoading(true);
    getUserJobs({
      page,
      limit,
      searchText,
      state,
      city,
      jobLanguage: webLanguage,
      category: userRole,
    })
      .then((list) => {
        if (list.status === "success") {
          setJobsList(list.data.docs);
          setLoading(false);
          setTotalDocs(list.data.totalDocs);
          const loc = getUniqeStateAndCity(list.data.docs);

          setLocations(loc);
        }
      })
      .catch((err) => {
        console.log("error fetch list", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500);

  useEffect(() => {
    debounceList();
  }, [page, limit, searchText, state, webLanguage, city]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Find Remote Job in USA </title>
        {/* Set the canonical URL */}
        <link
          rel="canonical"
          href={`https://www.remotemechanicjobs.com/alljobs`}
        />
        {/* Set the meta description */}
        <meta
          name="description"
          content="The same vehicle and equipment maintenance industry , with some upgraded service options. Offering Remote Services provides you with an entirely new revenue stream at your own pace."
        />
        <link
          rel="alternate"
          hreflang="en-us"
          href="https://www.remotemechanicjobs.com/en-us/alljobs"
        />
        <link
          rel="alternate"
          hreflang="es-es"
          href="https://www.remotemechanicjobs.com/en-us/alljobs"
        />
      </Helmet>
      <JobsBanner />
      <ResumeUpload />
      <div className="filterbar">
        <div className="container">
          <div className="filterrow">
            <Search searchHandleChange={handleSearch} searchTerm={searchText} />
            {/* <JobsFilter /> */}

            <LocationFilter
              locations={locations?.states}
              setLocation={setState}
              type="state"
            />
            <LocationFilter
              locations={locations?.cities}
              setLocation={setCity}
              type="city"
            />

            {/* <ContractFilter /> */}
          </div>
        </div>
      </div>
      {jobsList.length ? (
        <div className="alljobs-listing">
          <div className="container">
            <h4>
              <span>
                {totalDocs} {_t("positions")}
              </span>{" "}
              {_t("at all locations serving all teams")}
            </h4>

            {loading ? <Loader /> : <JobListing jobsList={jobsList} />}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AllJobs;
