import Carousel from "react-elastic-carousel";
import useTranslator from "../../../hooks/useTranslator";
import Item from "./Item";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 767, itemsToShow: 2 },
  { width: 1024, itemsToShow: 3 },
];

const WhyAppluSliderasBusiness = () => {
  const { _t } = useTranslator();

  return (
    <>
      <Carousel breakPoints={breakPoints}>
        <Item>
          <div className="slidebox">
            <img src="/images/profit.svg " alt="icon6" />
            <h4>
              {_t(
                "Revolutionize Customer Connections: Expand Your Reach with Remote Support Solutions"
              )}
            </h4>
            <p>
              {_t(
                "Introduce a lucrative new revenue stream by effortlessly connecting with thousands of potential customers through innovative remote support solutions."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/Revolutionize.svg" alt="icon7" />
            <h4>
              {_t(
                "Virtualize and Thrive: Elevate Services and Optimize Space Allocation"
              )}
            </h4>
            <p>
              {_t(
                "Transform your business dynamics by transitioning low-income services to a virtual environment. opportunities."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/skills.webp" alt="icon8" />
            <h4>
              {_t(
                "Revenue Resilience: Sustain Business Income During Slow Periods"
              )}
            </h4>
            <p>
              {_t(
                "Ensure continuous revenue flow and keep your team gainfully employed at all times."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/Modernize.svg " alt="icon9" />
            <h4>
              {_t(
                "Modernize without the Cost: Elevate Client Experiences with Zero Startup Investment"
              )}
            </h4>
            <p>
              {_t(
                "Upgrade your service offerings for existing clients without breaking the bank – embrace new and modern solutions with no upfront expenses."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/skills.webp" alt="icon10" />
            <h4>
              {_t(
                "Finance Tracking Suite: Effortless Income Tracking with Integrated Finance Tools"
              )}
            </h4>
            <p>
              {_t(
                "Take control of your virtual earnings effortlessly with a comprehensive suite of tools that seamlessly integrate with your business systems, ensuring easy income tracking and financial management."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/Marketing.svg" alt="icon11" />
            <h4>
              {_t(
                "Precision Marketing Advantage: Target Your Ideal Audience with Affordable Campaigns"
              )}
            </h4>
            <p>
              {_t(
                "Access a powerful advertising platform that targets your audience with precision, delivering impactful messages at extremely affordable prices."
              )}
            </p>
          </div>
        </Item>
        <Item>
          <div className="slidebox">
            <img src="/images/Revolution.svg" alt="icon12" />
            <h4>
              {_t(
                "Tech Revolution Awaits: Join Groundbreaking Change and Stay Ahead"
              )}
            </h4>
            <p>
              {_t(
                "Be at the forefront of industry transformation with cutting-edge technology – secure your competitive edge by joining a groundbreaking movement from the ground level."
              )}
            </p>
          </div>
        </Item>
      </Carousel>
    </>
  );
};

export default WhyAppluSliderasBusiness;
