import React, { useState, useEffect } from "react";
import BackButton from "../../../Components/BackToPrev";
import { AiOutlineSearch, AiFillPlusCircle } from "react-icons/ai";
import {
  MdDeleteForever,
  MdHistory,
  MdModeEditOutline,
  MdPublishedWithChanges,
  MdLocationPin,
} from "react-icons/md";
import { InputGroup, Form, Table } from "react-bootstrap";
import Paginate from "../../../Components/Paginate";
import { Link } from "react-router-dom";
import {
  AdminJobsFetch,
  deleteJobInfo,
  rePublishJobInfo,
} from "../../../api-services/jobs";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import JobHistory from "../../../Components/Modals/Jobhistory";
import Loader from "../../../Components/Loader";
import {
  formatDateWithoutTime,
  getDayDifferenceFromDate,
} from "../../../utils/helpers";

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalDocs, setTotalDocs] = useState("");
  const [show, setShowHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedSearch = debounce(() => {
    setLoading(true);
    AdminJobsFetch({ page, limit, searchText })
      .then((jobData) => {
        if (jobData.status === "success") {
          setLoading(true);
          setJobList(jobData.data.docs);
          setTotalDocs(jobData.data.totalDocs);
        }
      })
      .catch((error) => {
        console.log("Error while fetching jobs: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 500); // Adjust the debounce time as needed

  useEffect(() => {
    debouncedSearch();
  }, [searchText, page, limit]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangePage = (page) => {
    setPage(page);
  };

  function deleteJobHandler(id) {
    // Ask for confirmation
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this job?"
    );

    if (isConfirmed && id) {
      deleteJobInfo({ id })
        .then((deleteRes) => {
          if (deleteRes.status === "success") {
            toast.success("Job removed from the list");
            debouncedSearch();
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }

  function rePublishJobHandler(id) {
    const isConfirmed = window.confirm(
      "Are you sure you want to Republish this job?"
    );
    if (isConfirmed && id) {
      rePublishJobInfo({ id })
        .then((deleteRes) => {
          if (deleteRes.status === "success") {
            toast.success("job removed from list");
            debouncedSearch();
          }
        })
        .catch((error) => {
          toast.error(error?.message);
        });
    }
  }

  function historyModelHandler(data) {
    setShowHistory(true);
    setHistoryData(data);
  }

  return (
    <>
      <div className="users-page">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-0">
            <BackButton />
            <h4>Jobs</h4>
          </div>
          <div className="import-export">
            <Link className="btn btnorange" to="/jobs/add">
              <AiFillPlusCircle /> Add Job
            </Link>
            {/* <button>
              <AiOutlineUpload /> Export
            </button> */}
          </div>
        </div>
        {/* ----------searchbox---------- */}
        <div className="serachbar-box">
          <div className="searchbar">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <AiOutlineSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Job"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleSearch(e)}
                value={searchText}
              />
            </InputGroup>
          </div>
        </div>
        {/* --------------table-------------- */}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="users-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>job Title</th>
                    <th>Job Niche</th>
                    <th>location</th>
                    <th>Category</th>
                    <th>Language</th>
                    <th>Rate</th>
                    <th>Applications</th>
                    <th>Expiry At</th>
                    <th>Actions</th>
                    <th>Delete</th>
                    <th>Re-Publish</th>
                    <th>Publish History</th>
                  </tr>
                </thead>
                <tbody>
                  {jobList?.map((job, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/jobs/${job?._id}`}>
                          <div className="user-details">
                            <div>
                              <p className="user-name">{job.jobTitle}</p>
                            </div>
                          </div>
                        </Link>
                      </td>

                      <td>{job.jobCategory}</td>
                      <td className="red-text">
                        {" "}
                        <MdLocationPin /> {job.city && job?.city + ","}{" "}
                        {job.state}
                      </td>

                      <td className="red-text"> {job?.category}</td>
                      <td className="red-text"> {job?.jobLanguage}</td>
                      <td className="red-text">
                        $
                        {job.rate.rateValue
                          ? job.rate.rateValue
                          : `${job.rate.min}-${job.rate.max}`}
                        /{job.rate.rateTimeLine}
                      </td>
                      <td className="red-text">{job.applicationCount}</td>
                      <td className="red-text">
                        {formatDateWithoutTime(job.expiredAt)}
                      </td>

                      <Link to={`/job/edit/${job?._id}`}>
                        <td className="red-text cursor-pointer">
                          <button>
                            <MdModeEditOutline /> Edit
                          </button>
                        </td>
                      </Link>
                      <td className="red-text cursor-pointer">
                        <button onClick={() => deleteJobHandler(job?._id)}>
                          <MdDeleteForever />
                          Delete
                        </button>
                      </td>
                      {job?.isExpiry ? (
                        <td className="red-text cursor-pointer">
                          <button onClick={() => rePublishJobHandler(job?._id)}>
                            <MdPublishedWithChanges />
                            Republish
                          </button>
                        </td>
                      ) : (
                        <td className="red-text no-drop-cursor">
                          <MdPublishedWithChanges />
                          Published
                        </td>
                      )}

                      <td className="red-text cursor-pointer">
                        <button
                          onClick={() =>
                            historyModelHandler(job?.publishHistory)
                          }>
                          <MdHistory className="ml-4 w-4 h-4" />
                          History
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Paginate
                total={totalDocs}
                onChange={handleChangePage}
                page={page}
              />
            </div>
          </>
        )}
      </div>
      <JobHistory
        show={show}
        setShowHistory={setShowHistory}
        data={historyData}
      />
    </>
  );
};

export default Jobs;
