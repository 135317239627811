import React from "react";
import { Col } from "react-bootstrap";
import {
  formatDateWithoutTime,
  haveValue,
  urlEncode,
} from "../../../utils/helpers";
import { Link } from "react-router-dom";

const OtherArticles = ({ data = [] }) => {
  const OtherArticles = [
    {
      id: 1,
      title: "Industry 4.0: How Digitalization is Transforming Manufacturing",
      date: "Dec 31, 2020",
      category: "Maintenance",
      coverImage: "/images/blog1.webp",
    },
    {
      id: 2,
      title: "Reducing Business Downtime: Effective Maintenance Strategies",
      date: "Friday, October 12, 2023",
      category: "industry",
      coverImage: "/images/blog3.webp",
    },
    {
      id: 3,
      title: "Reducing Business Downtime: Effective Maintenance Strategies",
      date: "Friday, October 18, 2023",
      category: "Maintenance",
      coverImage: "/images/blog2.webp",
    },
  ];
  if (haveValue(data)) {
    return (
      <>
        {data?.map((articles, index) => (
          <Col sm={12} md={12} lg={4} key={index}>
            <div className="Otherarticle">
              <div className="article-img">
                <img
                  src={"/images/blog2.webp"} //articles?.coverImage ||
                  alt="articles blog"
                />
              </div>
              <div className="articles-detail">
                <div className="catgory btn btngray">
                  {articles.category || "Innovation"}
                </div>
                <h5 className="article-date">
                  <img src="/images/calender.webp" alt="icon" />
                  {formatDateWithoutTime(articles.createdAt)}
                </h5>
              </div>
              <Link to={`/blog/${urlEncode(articles.title)}`}>
                <h3 className="article-title">{articles.title}</h3>
              </Link>
            </div>
          </Col>
        ))}
      </>
    );
  }
};

export default OtherArticles;
