import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Form, Row, Col, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import useTranslator from "../../../hooks/useTranslator";
import { haveValue } from "../../../utils/helpers";
import { editUser } from "../../../api-services/user";
import { requestHandler } from "../../../utils/comman";
import toast from "react-hot-toast";
import useUsers from "../../../data-fetch/useUsers";
const AddUserForm = ({ onSubmit, setShow, edit, user }) => {
  const { editUserhandler } = useUsers();
  const { _t } = useTranslator();
  const schema = yup.object().shape({
    name: yup.string().required("name field is required"),
    email: yup.string().email().required("email field is required"),
    mobileNo: yup.string().required("mobile field is required"),
    password: !edit
      ? yup
          .string()
          .min(6, "Password must be at least 6 characters")
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
          )
          .required("Password is required")
      : yup.string().optional(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      mobileNo: "",
      password: "",
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmitHandler = (data) => {
    if (edit) {
      editUserhandler(data);
    } else {
      onSubmit(data);
    }
    setShow(false);
  };

  const onError = (error) => console.log(error);

  useEffect(() => {
    if (haveValue(user) && edit) {
      reset({
        id: user._id,
        name: user.name,
        email: user.email,
        mobileNo: user.mobileNo,
      });
    }
  }, [user, edit]);

  return (
    <div>
      <Form
        className="accountform"
        onSubmit={handleSubmit(onSubmitHandler, onError)}>
        <Row>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="name">
              <Form.Label>{_t("Name")}</Form.Label>
              <Form.Control type="text" {...register("name")} />
            </Form.Group>
            {errors.name && <p className="error">{errors.name.message}</p>}
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="email">
              <Form.Label>{_t("Email")}</Form.Label>
              <Form.Control type="text" {...register("email")} />
            </Form.Group>
            {errors.email && <p className="error">{errors.email.message}</p>}
          </Col>
          <Col lg={12}>
            <Form.Group className="formninputbar" controlId="mobileNo">
              <Form.Label>{_t("Phone Number")}</Form.Label>
              <Form.Control type="text" {...register("mobileNo")} />
            </Form.Group>
            {errors.mobileNo && (
              <p className="error">{errors.mobileNo.message}</p>
            )}
          </Col>

          {!edit && (
            <Col lg={12}>
              <Form.Group className="formninputbar" controlId="password">
                <Form.Label>{_t("Password")}</Form.Label>
                <Form.Control type="text" {...register("password")} />
              </Form.Group>
              {errors.password && (
                <p className="error">{errors.password.message}</p>
              )}
            </Col>
          )}

          <Col lg={12}>
            <Form.Group
              className="formninputbar inputbar-btnpink"
              controlId="btnsave">
              {isSubmitting ? (
                <Button className="btn btnorange">Submitting...</Button>
              ) : (
                <Button className="btn btnorange" type="submit">
                  {edit ? "Edit User" : "Add User"}
                </Button>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddUserForm;
