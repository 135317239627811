import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Tabs } from "antd";

import BackButton from "../../../Components/BackToPrev";
import LangData from "./LangData";
import { getLangData } from "../../../api-services/language.api";
const onChange = (key) => {};

const LanguageSetting = (props) => {
  const items = [
    {
      key: "1",
      label: `English`,
      children: <LangData langCode="en"/>,
    },

    {
      key: "2",
      label: `Spanish`,
      children: <LangData langCode="es"/>,
    },
  ];

  return (
    <>
      <div className="account-settings mt-4">
        <div className="top-header">
          <div className="page-name title-with-backbtn mb-10">
            <BackButton />
            <h4> Content </h4>
          </div>  
        </div>

        <div className="tabs-account language-accords">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
    </>
  );
};

export default LanguageSetting;
