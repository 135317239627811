import React from 'react';
import useTranslator from "../../../hooks/useTranslator";
import { Link } from 'react-router-dom';
const BenefitsFreelancer = () => {
    const { _t } = useTranslator();
    return (
        <>
             <div className='CareerOpportunitydivider benefitbar'>
            <div className='container'>
            <h4>{_t("Benefits for Mechanics")}</h4>
            <div className='btnlearnmore'>
            <Link to="/#formapply" className='btn btnwhite'>{_t("Apply Now")}</Link>
            </div>
            </div>
        </div>
        </>
    );
}

export default BenefitsFreelancer;
