import { useEffect, useState } from "react";
import { requestHandler } from "../utils/comman";
import { getBlogById } from "../api-services/blog";

const useBlogInfo = (params = {}) => {
  const [dataBlog, setData] = useState(null);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);

  useEffect(() => {
    requestHandler(
      async () => await getBlogById(params),
      setloading,
      (res) => {
        const { data, status } = res;
        setData(data);
      },
      seterror
    );
  }, []);

  return {
    dataBlog,
    loading,
    error,
  };
};

export default useBlogInfo;
