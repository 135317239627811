import React from 'react';
import JobDetailBanner from './JobDetailBanner';

const JobDetail = () => {
    return (
        <>
            <div className='JobDetail-block'>
                <JobDetailBanner/>
            </div>
        </>
    );
}

export default JobDetail;
