import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import PropTypes from "prop-types";


const Search = ({ searchHandleChange, searchTerm }) => {
  return (
    <>
      <div className="searchbar">
        <div className="dflex">
          <input
            className="inputsearch"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => searchHandleChange(e)}
          />
          <div className="inputicon">
            <IoSearchOutline />
          </div>
        </div>
      </div>
    </>
  );
};

Search.propTypes = {
  searchHandleChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default Search;
