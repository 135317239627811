import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import BackButton from "../../../Components/BackToPrev";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { MdAlternateEmail } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import SentMailModel from "../../../Components/Modals/SentMail";
import { formatDateWithoutTime } from "../../../utils/helpers";
import { getOutReachEmail } from "../../../api-services/email";
const ApplicantCommunication = () => {
  const { email } = useParams();
  const params = useSearchParams();
  console.log("🚀 ~ ApplicantCommunication ~ params:", params);
  const [showModel, setShowModel] = useState(false);

  const [communicationData, setCommunicationData] = useState(null);

  function fetchInfo() {
    getOutReachEmail({ email })
      .then((appData) => {
        console.log("🚀 ~ .then ~ appData:", appData);
        if (appData.status === "success") {
          setCommunicationData(appData.data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  useEffect(() => {
    fetchInfo();
  }, [email]);

  const handlerModel = () => setShowModel(true);

  return (
    <div className="users-page">
      <div className="top-header">
        <div className="page-name title-with-backbtn mb-0">
          <BackButton />
          <h4> Communications</h4>
        </div>
        <div className="import-export">
          <button className="btn btnorange" onClick={handlerModel}>
            <MdAlternateEmail /> Sent Email
          </button>
        </div>
      </div>

      <div className="users-table mt-3">
        <Row xs={1} md={1} className="g-4">
          {communicationData?.length > 0 &&
            communicationData?.map((hist, idx) => (
              <Col key={idx}>
                <Card bg="dark">
                  <Card.Body>
                    <Card.Title>
                      Date : {formatDateWithoutTime(hist?.createdAt)}
                    </Card.Title>
                    <Card.Text>
                      <div style={{ whiteSpace: "pre-line" }}>
                        {hist?.content}
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
      <SentMailModel
        show={showModel}
        setShowModel={setShowModel}
        fetchInfo={fetchInfo}
      />
    </div>
  );
};

export default ApplicantCommunication;
