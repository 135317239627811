import React, { Suspense } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import Optional from "./Optional";
import StepFour from "./StepFour";
import StepSix from "./StepSix";
import NumberOfTechnicians from "./NumberOfTechnicians";
// import StepSeven from "./StepSeven";
import useTranslator from "../../../hooks/useTranslator";
import CertificationTextStep from "./CertficationText";
import Loader from "../../Loader";
import useScrollTopForm from "../../../hooks/useScrollTopForm";

const StepSeven = React.lazy(() => import("./StepSeven"));

const ShopOwnerFlow = ({
  setCurrentStep,
  totalCase,
  currentStep,
  isHeavyVehicleSelected,
  isCertified,
}) => {
  const { _t } = useTranslator();
  useScrollTopForm(`fromapply`);

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne setCurrentStep={setCurrentStep} totalCase={totalCase} />
        );
      case 2:
        return (
          <StepTwo setCurrentStep={setCurrentStep} totalCase={totalCase} />
        );
      case 3:
        return (
          <StepThree setCurrentStep={setCurrentStep} totalCase={totalCase} />
        );
      case 4:
        if (isHeavyVehicleSelected()) {
          return (
            <Optional setCurrentStep={setCurrentStep} totalCase={totalCase} />
          );
        }
        // If not heavy vehicle selected, proceed to the next step
        setCurrentStep(currentStep + 1);
        break;

      case 5:
        return (
          <StepFour setCurrentStep={setCurrentStep} totalCase={totalCase} />
        );

      case 6:
        return (
          <NumberOfTechnicians
            setCurrentStep={setCurrentStep}
            totalCase={totalCase}
          />
        );

      case 7:
        return (
          <StepSix setCurrentStep={setCurrentStep} totalCase={totalCase} />
        );

      case 8:
        return (
          <Suspense fallback={<Loader />}>
            <StepSeven setCurrentStep={setCurrentStep} totalCase={totalCase} />
          </Suspense>
        );
      case 9:
        return <h4 className="text-center mt-5">{_t("Thank You")}</h4>;
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default ShopOwnerFlow;
